import { useEffect, useMemo } from "react";
import Tus from "@uppy/tus";
import Uppy from "@uppy/core";
import { API_SERVER_ROOT, getCookie } from "../utils";


const useSparkwiseUppy = (immersionId: string) => {
    const accessToken = useMemo(() => getCookie(document.cookie, "auth0Token"), []);
    const uppy = useMemo(
        () => new Uppy({ restrictions: { allowedFileTypes: ["video/*"] } }),
        [],
    );

    const endpoint = `${API_SERVER_ROOT}/immersion/${immersionId}/cloudflareStreamVideoUpload`;

    useEffect(() => {
        uppy.use(Tus, {
            onBeforeRequest: async (req) => {
                if (req.getURL().includes(API_SERVER_ROOT)) {
                    req.setHeader("Authorization", `Bearer ${accessToken}`);
                }
            },
            endpoint,
            chunkSize: 50 * 1024 * 1024, // 50 MB chunk size
        });
        return () => {
            uppy.close();
        };
    }, [accessToken, immersionId, endpoint, Tus, uppy]);

    return uppy;
};

export default useSparkwiseUppy;
