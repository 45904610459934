import { Flex } from "@chakra-ui/react";
import { InsightsHeader } from "./InsightsHeader";
import { InsightsContent } from "./InsightsContent";

export const InsightsMain: React.FC = () => {
    return (
        <Flex id={"insights-main"} grow={1} position={"relative"}
            width={["auto", "100%"]}
            height={["auto", "100%"]}
            direction={["column", "row"]}
            overflow={["auto", "hidden"]}
        >
            <Flex
                width={["auto", "100%"]}
                position={["relative", "absolute"]}
                height={["auto", "100%"]}
            >
                <Flex p={[0, "4px"]} width={"100%"}>
                    <Flex
                        direction={"column"}
                        bg={"white"}
                        borderRadius={[null, "12px"]}
                        border={[null, "1px solid"]}
                        borderColor={[null, "gray.100"]}
                        width={"100%"}
                    >
                        <InsightsHeader />
                        <InsightsContent />
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
