import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App/App";
import { Auth0Provider } from "@auth0/auth0-react";
import * as Sentry from "@sentry/react";
import { COMMIT } from "./utils";
import { THEME_COLORS } from "shared/constants";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "@uppy/core/dist/style.min.css";
import "@uppy/dashboard/dist/style.min.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

Sentry.init({
    dsn: "https://444520cc7811495b9fdd65c2019f358a@o1112051.ingest.sentry.io/6617546",
    integrations: [new Sentry.BrowserTracing()],
    release: COMMIT,
    environment: process.env.REACT_APP_STAGE,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.01,
});

const theme = extendTheme({
    colors: THEME_COLORS,
    fonts: {
        body: '"Inter", sans-serif',
    },
});

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement,
);
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN || ""}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
            authorizationParams={{
                audience: process.env.REACT_APP_AUTH0_AUDIENCE,
                redirect_uri: window.location.origin,
            }}
        >
            <ChakraProvider theme={theme}>
                <App />
            </ChakraProvider>
        </Auth0Provider>
    </React.StrictMode>,
);
