import {
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    TextProps,
} from "@chakra-ui/react";
import { ArrowDownTrayIcon, PrinterIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useInsights } from "./InsightsContext";
import { useNavigate } from "react-router-dom";

export const ExitModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    const pStyle: TextProps = {
        lineHeight: "22px",
        mb: "22px",
        fontWeight: 400,
        fontSize: "14px",
    };
    const navigate = useNavigate();
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent p={"20px"} borderRadius={"20px"}>
                <ModalHeader>
                    <Heading fontWeight="bold" fontSize="22px">
                        Exit current insight
                    </Heading>
                </ModalHeader>
                <ModalBody mt={"4px"}>
                    <Text {...pStyle}>
                        Sparkwise will autosave your current insight. You can
                        access it again from past insights page.
                    </Text>
                </ModalBody>
                <ModalFooter pb={0}>
                    <Flex
                        w={"100%"}
                        justifyContent={"flex-end"}
                        direction={["column", "row"]}
                        gap={"16px"}
                    >
                        <Button
                            onClick={onClose}
                            variant="outline"
                            colorScheme="gray"
                            fontWeight={400}
                        >
                            Cancel
                        </Button>
                        <Button
                            colorScheme="blue"
                            onClick={async () => {
                                navigate("/");
                            }}
                        >
                            Exit
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
