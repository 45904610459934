import Table from "../common/Table";
import { timeParser } from "../../utils";

const EventsTable = ({ events }: { events: any[] }) => (
    <Table
        columns={[
            ["createdOn", "Created On", timeParser],
            ["type", "Type"],
            ["status", "Status"],
            ["completedOn", "Completed On", timeParser],
            ["scheduledOn", "Scheduled On", timeParser],
            ["event", "Event"],
            ["cancel", "Cancel"],
            ["sendImmediately", "Send Immediately"],
            ["complete", "Mark as Complete"],
        ]}
        data={{
            0: events.map((event) => ({
                ...event,
                status: event.n8nExecutionUrl ? (
                    <a
                        href={event.n8nExecutionUrl}
                        target="_blank"
                        className="text-gray-600 hover:underline underline-offset-2"
                        rel="noreferrer"
                    >
                        {event.status}
                    </a>
                ) : (
                    event.status
                ),
            })),
            1: [],
        }}
        searchable={["event"]}
        multiselectable={["type", "status"]}
        hidePages={true}
        initialSortDirection={"desc"}
        initialSortField={"createdOn"}
        onPageChange={() => {}}
        page={0}
        sortable={true}
    />
);

export default EventsTable;
