import {
    Button,
    Divider,
    Flex,
    Grid,
    GridItem,
    Input,
    InputGroup,
    InputLeftAddon,
    InputRightAddon,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    Tab,
    TabList,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react";
import { useGlobalContext } from "../../context";
import { useTRPC } from "../../hooks/useTRPC";
import { ParamsProps, withParams } from "../../utils";
import { useEffect, useState } from "react";
import {
    CalendarBulletsType,
    EventSendSettings,
    LinksBulletsType,
    ProductIdentityBrandingType,
    SignatureOptions,
} from "@prisma/client";
import { LinksTab } from "./LinksTab";
import { OnboardingTab } from "./OnboardingTab";
import { ReminderTab } from "./ReminderTab";
import { FollowupTab } from "./FollowupTab";
import { CalendarInvites } from "./CalendarInvites";
import { useIsSuperAdmin } from "../../hooks/useUserData";
import Loading from "../../components/common/Loading";
import { ManagerTab } from "./ManagerTab";
//import checkmark from heroicons
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import { MinusCircleIcon } from "@heroicons/react/24/solid";

const Customization = (props: ParamsProps) => {
    const context = useGlobalContext();
    const trpc = useTRPC();

    const isSuperAdmin = useIsSuperAdmin();

    const { data: org } = trpc.org.getOrgById.useQuery(
        props.params.organizationId || "",
    );

    const managerEmailsEnabled = org?.featureFlagOverrides.some(
        (flag: { featureFlag: { name: string }; enabled: any }) =>
            flag.featureFlag.name === "Send manager emails" && flag.enabled,
    );

    const {
        data: settings,
        isLoading,
        refetch,
    } = trpc.org.getBrandingCustomizationSettings.useQuery(
        {
            organizationId: context.organizationId || "",
        },
        { enabled: !!context.organizationId },
    );

    const { data: emailData } = trpc.org.getEmailData.useQuery();

    const { mutateAsync: saveBrandingCustomizationSettings } =
        trpc.org.saveBrandingCustomizationSettings.useMutation();
    const { mutateAsync: sendSampleEmail } =
        trpc.org.previewEmail.useMutation();

    const { mutateAsync: sendManagerEmail } =
        trpc.org.previewManagerEmail.useMutation();

    const { mutateAsync: previewCalendarInvite } =
        trpc.org.previewCalendarInvite.useMutation();
    const [orgEmailName, setOrgEmailName] = useState(
        settings?.organizationEmailName,
    );
    const [organizerNameToggle, setOrganizerNameToggle] = useState<boolean>(
        !!settings?.contactName,
    );
    const [signatureType, setSignatureType] = useState<string>(
        SignatureOptions.DEFAULT,
    );
    const [productIdentityBrandingName, setProductIdentityBrandingName] =
        useState<string>("");
    const [productIdentityBrandingType, setProductIdentityBrandingType] =
        useState<string>(ProductIdentityBrandingType.DEFAULT);
    const [linksBulletsType, setLinksBulletsType] = useState<string>(
        LinksBulletsType.DEFAULT,
    );
    const [preEmailLinksText, setPreEmailLinksText] = useState<string>("");
    const [signature, setSignature] = useState<string>("");
    const [organizerName, setOrganizerName] = useState(settings?.contactName);
    const [customBulletsList, setCustomBulletsList] = useState<string[]>([]);
    const [sendLinkEmails, setSendLinkEmails] = useState<EventSendSettings>(
        EventSendSettings.DO_NOT_SEND,
    );
    const [sendOnboardingEmails, setSendOnboardingEmails] =
        useState<EventSendSettings>(EventSendSettings.DO_NOT_SEND);
    const [preEmailOnboardingText, setPreEmailOnboardingText] =
        useState<string>("");
    const [sendReminderEmails, setSendReminderEmails] =
        useState<EventSendSettings>(EventSendSettings.DO_NOT_SEND);
    const [preEmailReminderText, setPreEmailReminderText] =
        useState<string>("");
    const [sendFollowupEmails, setSendFollowupEmails] =
        useState<EventSendSettings>(EventSendSettings.DO_NOT_SEND);
    const [preEmailFollowupText, setPreEmailFollowupText] =
        useState<string>("");

    const [sendCalendarInvites, setSendCalendarInvites] =
        useState<EventSendSettings>(EventSendSettings.DO_NOT_SEND);
    const [preCalendarInviteText, setPreCalendarInviteText] =
        useState<string>("");
    const [calendarBulletsType, setCalendarBulletsType] = useState<string>(
        CalendarBulletsType.DEFAULT,
    );
    const [calendarBulletsList, setCalendarBulletsList] = useState<string[]>(
        [],
    );

    const hasChanged =
        settings?.organizationEmailName !== orgEmailName ||
        settings?.signatureText !== signature ||
        settings?.signatureType !== signatureType ||
        settings?.productIdentityBrandingName !== productIdentityBrandingName ||
        settings?.productIdentityBrandingType !== productIdentityBrandingType ||
        settings?.linksBulletsType !== linksBulletsType ||
        settings?.linksBullets !== customBulletsList ||
        settings?.sendLinkEmails !== sendLinkEmails ||
        settings?.preCalendarInviteText !== preCalendarInviteText ||
        settings?.preEmailLinksText !== preEmailLinksText ||
        settings?.sendOnboardingEmails !== sendOnboardingEmails ||
        settings?.preEmailOnboardingText !== preEmailOnboardingText ||
        settings?.sendReminderEmails !== sendReminderEmails ||
        settings?.preEmailReminderText !== preEmailReminderText ||
        settings?.sendFollowupEmails !== sendFollowupEmails ||
        settings?.preEmailFollowupText !== preEmailFollowupText ||
        settings?.sendCalInvites !== sendCalendarInvites ||
        settings?.calendarBulletsType !== calendarBulletsType ||
        settings?.calendarBullets !== calendarBulletsList ||
        (organizerNameToggle && organizerName ? organizerName : "") !==
            settings?.contactName;

    const setStartingValues = () => {
        if (settings) {
            setOrgEmailName(settings.organizationEmailName);
            setOrganizerName(settings.contactName);
            setOrganizerNameToggle(!!settings.contactName);
            setSignature(settings.signatureText || "");
            setSignatureType(settings.signatureType);
            setProductIdentityBrandingName(
                settings.productIdentityBrandingName || "",
            );
            setProductIdentityBrandingType(
                settings.productIdentityBrandingType,
            );
            setCustomBulletsList(settings.linksBullets || []);
            setLinksBulletsType(settings.linksBulletsType);
            setSendLinkEmails(settings.sendLinkEmails);
            setPreCalendarInviteText(settings.preCalendarInviteText || "");
            setPreEmailLinksText(settings.preEmailLinksText || "");
            setSendOnboardingEmails(settings.sendOnboardingEmails);
            setPreEmailOnboardingText(settings.preEmailOnboardingText || "");
            setSendReminderEmails(settings.sendReminderEmails);
            setPreEmailReminderText(settings.preEmailReminderText || "");
            setSendFollowupEmails(settings.sendFollowupEmails);
            setPreEmailFollowupText(settings.preEmailFollowupText || "");
            setSendCalendarInvites(settings.sendCalInvites);
            setCalendarBulletsList(settings.calendarBullets || []);
            setCalendarBulletsType(settings.calendarBulletsType);
        }
    };

    useEffect(() => {
        if (org) {
            context.setOrganizationId(org.id);
            context.setOrganizationName(org.name);
            context.setOrganizationTimezone(
                org.timezone ? org.timezone.name : "UTC",
            );
        }
    }, [context, org]);

    useEffect(() => {
        setStartingValues();
    }, [settings]);

    if (!isSuperAdmin) {
        return <Text>Under construction</Text>;
    }
    if (isLoading) {
        return <Loading></Loading>;
    }

    return (
        <Flex flexDir="column" maxW="1000px">
            <h1 className="text-xl font-semibold text-gray-900 mb-6">
                Communications
            </h1>
            <Grid templateRows="1fr 1.5fr 2fr 2fr" templateColumns="1fr 2fr">
                <GridItem h="50px">
                    <Text fontWeight="semibold" fontSize="sm">
                        Organization Name:
                    </Text>
                    <Text fontWeight="medium" fontSize="xs" color="gray">
                        (Specify who's the provider of the session)
                    </Text>
                </GridItem>
                <GridItem h="50px">
                    <Input
                        w="150px"
                        placeholder="e.g. Sparkwise"
                        value={orgEmailName}
                        onChange={(e) => setOrgEmailName(e.target.value)}
                    />
                </GridItem>
                <GridItem h="50px">
                    <Text fontWeight="semibold" fontSize="sm">
                        Organizer Name:
                    </Text>
                    <Text fontWeight="medium" fontSize="xs" color="gray">
                        (Specify who will appear on each email as the session
                        organizer)
                    </Text>
                </GridItem>
                <GridItem h="80px">
                    <Switch
                        isChecked={organizerNameToggle}
                        marginBottom="10px"
                        onChange={(e) => {
                            setOrganizerNameToggle(e.target.checked);
                        }}
                    ></Switch>
                    <InputGroup
                        visibility={organizerNameToggle ? undefined : "hidden"}
                    >
                        <InputLeftAddon>You are invited by</InputLeftAddon>
                        <Input
                            placeholder="e.g. John Doe"
                            value={organizerName || ""}
                            onChange={(e) => setOrganizerName(e.target.value)}
                            maxW="300px"
                            minW="100px"
                        />
                        <InputRightAddon>
                            to join a group learning experience
                        </InputRightAddon>
                    </InputGroup>
                </GridItem>
                <GridItem h="50px">
                    <Text fontWeight="semibold" fontSize="sm">
                        Signature Line
                    </Text>
                    <Text fontWeight="medium" fontSize="xs" color="gray">
                        (Appended at the end of all outgoing messages)
                    </Text>
                </GridItem>
                <GridItem h="120px">
                    <RadioGroup
                        onChange={setSignatureType}
                        value={signatureType}
                    >
                        <Stack direction="column">
                            <Radio value={SignatureOptions.DEFAULT}>
                                Default: "Team Sparkwise"
                            </Radio>
                            <Radio value={SignatureOptions.CUSTOM_SIGNATURE}>
                                Custom Signature
                            </Radio>
                        </Stack>
                    </RadioGroup>
                    <InputGroup
                        visibility={
                            signatureType === SignatureOptions.CUSTOM_SIGNATURE
                                ? undefined
                                : "hidden"
                        }
                    >
                        <InputLeftAddon>Thanks,</InputLeftAddon>
                        <Input
                            placeholder="e.g. The Sparkwise Team"
                            value={signature || ""}
                            onChange={(e) => setSignature(e.target.value)}
                            w="300px"
                        />
                    </InputGroup>
                </GridItem>
                <GridItem h="50px">
                    <Text fontWeight="semibold" fontSize="sm">
                        Format Naming
                    </Text>
                    <Text fontWeight="medium" fontSize="xs" color="gray">
                        (How to refer to the session format)
                    </Text>
                </GridItem>
                <GridItem h="120px">
                    <RadioGroup
                        onChange={setProductIdentityBrandingType}
                        value={productIdentityBrandingType}
                    >
                        <Stack direction="column">
                            <Radio value={ProductIdentityBrandingType.DEFAULT}>
                                Default: "Group Learning"
                            </Radio>
                            <Radio
                                value={
                                    ProductIdentityBrandingType.CUSTOM_BRANDING
                                }
                            >
                                Custom Name
                            </Radio>
                        </Stack>
                    </RadioGroup>
                    <InputGroup
                        visibility={
                            productIdentityBrandingType ===
                            ProductIdentityBrandingType.CUSTOM_BRANDING
                                ? undefined
                                : "hidden"
                        }
                    >
                        <Input
                            placeholder="e.g. Workshop"
                            value={productIdentityBrandingName || ""}
                            onChange={(e) =>
                                setProductIdentityBrandingName(e.target.value)
                            }
                            w="300px"
                        />
                    </InputGroup>
                </GridItem>
            </Grid>
            <Text fontWeight="semibold" fontSize="sm" marginBottom="5px">
                Calendar & Emails
            </Text>
            <Tabs minHeight="440px">
                <TabList>
                    <Tab>
                        <Text marginRight="7px">Calendar Invite </Text>
                        <Flex>
                            {sendCalendarInvites === EventSendSettings.SEND ? (
                                <CheckCircleIcon
                                    color="#47C26C"
                                    height="15px"
                                />
                            ) : (
                                <MinusCircleIcon
                                    height="15px"
                                    color="#999EA8"
                                />
                            )}
                        </Flex>
                    </Tab>
                    <Tab>
                        <Text marginRight="7px">Onboarding </Text>
                        {sendOnboardingEmails === EventSendSettings.SEND ? (
                            <CheckCircleIcon color="#47C26C" height="15px" />
                        ) : (
                            <MinusCircleIcon height="15px" color="#999EA8" />
                        )}
                    </Tab>
                    <Tab>
                        <Text marginRight="7px">Reminder </Text>
                        {sendReminderEmails === EventSendSettings.SEND ? (
                            <CheckCircleIcon color="#47C26C" height="15px" />
                        ) : (
                            <MinusCircleIcon height="15px" color="#999EA8" />
                        )}
                    </Tab>
                    <Tab>
                        <Text marginRight="7px">Links </Text>
                        {sendLinkEmails === EventSendSettings.SEND ? (
                            <CheckCircleIcon color="#47C26C" height="15px" />
                        ) : (
                            <MinusCircleIcon height="15px" color="#999EA8" />
                        )}
                    </Tab>
                    <Tab>
                        <Text marginRight="7px">Follow Up </Text>
                        {sendFollowupEmails === EventSendSettings.SEND ? (
                            <CheckCircleIcon color="#47C26C" height="15px" />
                        ) : (
                            <MinusCircleIcon height="15px" color="#999EA8" />
                        )}
                    </Tab>
                    <Tab>
                        <Text marginRight="7px">Manager Email </Text>
                        {managerEmailsEnabled ? (
                            <CheckCircleIcon color="#47C26C" height="15px" />
                        ) : (
                            <MinusCircleIcon height="15px" color="#999EA8" />
                        )}
                    </Tab>
                </TabList>
                <TabPanels>
                    <CalendarInvites
                        unsavedChanges={hasChanged}
                        previewCalendarInvite={async () => {
                            return await previewCalendarInvite({
                                organizationId: context.organizationId,
                            });
                        }}
                        calendarBulletsType={
                            calendarBulletsType as CalendarBulletsType
                        }
                        setCalendarBulletsType={setCalendarBulletsType}
                        customBulletsList={calendarBulletsList}
                        setCustomBulletsList={setCalendarBulletsList}
                        setSendCalendarInvites={setSendCalendarInvites}
                        sendCalendarInvites={sendCalendarInvites}
                        preCalendarInviteText={preCalendarInviteText}
                        setPreCalendarInviteText={setPreCalendarInviteText}
                        defaultCalendarBullets={
                            emailData?.defaultCalendarBullets || ["loading..."]
                        }
                    />
                    <OnboardingTab
                        unsavedChanges={hasChanged}
                        sendSampleOnboardingEmail={async () => {
                            return await sendSampleEmail({
                                type: "onboarding",
                                organizationId: context.organizationId,
                            });
                        }}
                        setSendOnboardingEmails={setSendOnboardingEmails}
                        sendOnboardingEmails={sendOnboardingEmails}
                        preEmailOnboardingText={preEmailOnboardingText}
                        setPreEmailOnboardingText={setPreEmailOnboardingText}
                    />
                    <ReminderTab
                        unsavedChanges={hasChanged}
                        sendSampleReminderEmail={async () => {
                            return await sendSampleEmail({
                                type: "reminder",
                                organizationId: context.organizationId,
                            });
                        }}
                        setSendReminderEmails={setSendReminderEmails}
                        sendReminderEmails={sendReminderEmails}
                        preEmailReminderText={preEmailReminderText}
                        setPreEmailReminderText={setPreEmailReminderText}
                    ></ReminderTab>
                    <LinksTab
                        unsavedChanges={hasChanged}
                        sendSampleEmail={async () => {
                            return await sendSampleEmail({
                                type: "links",
                                organizationId: context.organizationId,
                            });
                        }}
                        linksBulletsType={linksBulletsType as LinksBulletsType}
                        setLinksBulletsType={setLinksBulletsType}
                        customBulletsList={customBulletsList}
                        setCustomBulletsList={setCustomBulletsList}
                        setSendLinkEmails={setSendLinkEmails}
                        sendLinkEmails={sendLinkEmails}
                        preEmailLinksText={preEmailLinksText}
                        setPreEmailLinksText={setPreEmailLinksText}
                        defaultLinksBullets={
                            emailData?.defaultLinksBullets || ["loading..."]
                        }
                    />
                    <FollowupTab
                        unsavedChanges={hasChanged}
                        sendSampleFollowupEmail={async () => {
                            return await sendSampleEmail({
                                type: "follow-up",
                                organizationId: context.organizationId,
                            });
                        }}
                        setSendFollowupEmails={setSendFollowupEmails}
                        sendFollowupEmails={sendFollowupEmails}
                        preEmailFollowupText={preEmailFollowupText}
                        setPreEmailFollowupText={setPreEmailFollowupText}
                    />
                    <ManagerTab
                        sendSampleEmail={async () => {
                            await sendManagerEmail({
                                organizationId: context.organizationId,
                            });
                        }}
                    ></ManagerTab>
                </TabPanels>
            </Tabs>
            <Divider marginBottom="20px"></Divider>
            <Flex margin="0 auto" gap="20px">
                <Button
                    colorScheme="blue"
                    variant={"solid"}
                    isLoading={!settings}
                    isDisabled={!hasChanged}
                    width="200px"
                    onClick={() => {
                        saveBrandingCustomizationSettings({
                            organizationId: context.organizationId,
                            settings: {
                                organizationEmailName: orgEmailName,
                                setOrganizationEmailName: true,
                                contactName:
                                    organizerNameToggle && organizerName
                                        ? organizerName
                                        : "",
                                setContactName: true,
                                setSignatureType: true,
                                signatureType:
                                    signatureType as SignatureOptions,
                                signatureText:
                                    signatureType ===
                                    SignatureOptions.CUSTOM_SIGNATURE
                                        ? signature
                                        : "",
                                setProductIdentityBrandingName: true,
                                productIdentityBrandingName,
                                setProductIdentityBrandingType: true,
                                productIdentityBrandingType:
                                    productIdentityBrandingType as ProductIdentityBrandingType,
                                setSignatureText: true,
                                setLinksBulletsType: true,
                                linksBulletsType:
                                    linksBulletsType as LinksBulletsType,
                                linksBullets: customBulletsList,
                                setLinksBullets: true,
                                setSendLinksEmails: true,
                                sendLinkEmails,
                                setPreCalendarInviteText: true,
                                preCalendarInviteText,
                                setPreEmailLinksText: true,
                                preEmailLinksText,
                                setSendOnboardingEmails: true,
                                sendOnboardingEmails,
                                setPreEmailOnboardingText: true,
                                preEmailOnboardingText,
                                setSendReminderEmails: true,
                                sendReminderEmails,
                                setPreEmailReminderText: true,
                                preEmailReminderText,
                                setSendFollowupEmails: true,
                                sendFollowupEmails,
                                setPreEmailFollowupText: true,
                                preEmailFollowupText,
                                calendarBullets: calendarBulletsList,
                                setCalendarBullets: true,
                                setCalendarBulletsType: true,
                                calendarBulletsType:
                                    calendarBulletsType as CalendarBulletsType,
                                setSendCalInvites: true,
                                sendCalInvites: sendCalendarInvites,
                            },
                        }).then(() => refetch());
                    }}
                >
                    Save Changes
                </Button>
                <Button
                    width="200px"
                    onClick={setStartingValues}
                    isDisabled={!hasChanged}
                >
                    Discard
                </Button>
            </Flex>
        </Flex>
    );
};

export default withParams(Customization);
