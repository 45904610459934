import { useDisclosure } from "@chakra-ui/react";
import JSZip from "jszip";
import React, {
    PropsWithChildren,
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { AdminRouterOutput } from "server";
import { useGlobalContext } from "../../context";
import { useTRPC } from "../../hooks/useTRPC";
import { ComingSoonModal } from "./ComingSoonModal";
import { ExportToAIModal } from "./ExportToAIModal";
import { ExitModal } from "./ExitModal";

export type InsightsArtifact =
    AdminRouterOutput["insights"]["findInsights"]["data"][0];

export type InsightsArtifactType = InsightsArtifact["type"];

export type InsightsTranscriptArtifact = NonNullable<
    InsightsArtifact["transcript"]
>;
export type InsightsEtherpadArtifact = NonNullable<
    InsightsArtifact["etherpad"]
>;
export type InsightsExcalidrawArtifact = NonNullable<
    InsightsArtifact["excalidraw"]
>;

export type InsightsParticipant = InsightsArtifact["participants"][0];

interface InsightsContextState {
    filters: {
        moduleId?: string;
        date: number;
        cohortId?: string;
        showTranscripts?: boolean;
        showEtherpad?: boolean;
        showExcalidraw?: boolean;
        userIds?: string[];
    };

    transcriptCount: number;
    etherpadCount: number;
    excalidrawCount: number;

    isScrolled: boolean;

    expandedCards: string[];
}

const defaultValue: InsightsContextState = {
    filters: {
        moduleId: undefined,
        date: 1,
        cohortId: undefined,
        showTranscripts: true,
        showEtherpad: true,
        showExcalidraw: true,
    },

    transcriptCount: 0,
    etherpadCount: 0,
    excalidrawCount: 0,

    // Main content scroll status
    isScrolled: false,

    expandedCards: [],
};

export interface InsightsContext {
    state: InsightsContextState;

    setModuleId: (moduleId: string) => void;
    setCohortId: (cohortId?: string) => void;
    setUserId: (userId?: string) => void;
    setDateFilter: (value: number) => void;

    showTranscripts: (value: boolean) => void;
    showEtherpad: (value: boolean) => void;
    showExcalidraw: (value: boolean) => void;
    setIsScrolled: (value: boolean) => void;

    expandCard: (id: string) => void;
    collapseCard: (id: string) => void;
    expandAll: () => void;
    collapseAll: () => void;
    isAllExpanded: boolean;
    isCardExpanded: (id: string) => boolean;
    expandedCards: string[];

    showMetadata: boolean;
    setShowMetadata: (value: boolean) => void;

    downloadJSONForAI: () => Promise<void>;
    openDownloadModal: () => void;
    openComingSoonModal: () => void;
    openExitModal: () => void;

    data: InsightsArtifact[];
    loading: boolean;
    total: number;

    filterConfig: {
        resourceTypes: ("TRANSCRIPTION" | "ETHERPAD" | "EXCALIDRAW")[];
        ageInDays: number;
        immersionId: string;
        cohortId?: string;
        organizationId: string;
        userIds?: string[];
    };
}

type SetIsScrolled = {
    type: "SetIsScrolled";
    value: boolean;
};

type SetModuleId = {
    type: "SetModuleId";
    value?: string;
};

type SetCohortId = {
    type: "SetCohortId";
    value?: string;
};

type SetShowTranscripts = {
    type: "SetShowTranscripts";
    value: boolean;
};

type SetShowEtherpad = {
    type: "SetShowEtherpad";
    value: boolean;
};

type SetShowExcalidraw = {
    type: "SetShowExcalidraw";
    value: boolean;
};

type SetDateFilter = {
    type: "SetDateFilter";
    value: number;
};

type SetUserId = {
    type: "SetUserId";
    value?: string;
};

type InsightContextAction =
    | SetModuleId
    | SetShowTranscripts
    | SetShowEtherpad
    | SetShowExcalidraw
    | SetDateFilter
    | SetIsScrolled
    | SetCohortId
    | SetUserId;

const reducer = (
    state: InsightsContextState,
    action: InsightContextAction,
): InsightsContextState => {
    switch (action.type) {
        case "SetModuleId": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    moduleId: action.value,
                },
            };
        }
        case "SetShowTranscripts": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    showTranscripts: action.value,
                },
            };
        }
        case "SetShowEtherpad": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    showEtherpad: action.value,
                },
            };
        }
        case "SetShowExcalidraw": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    showExcalidraw: action.value,
                },
            };
        }
        case "SetDateFilter": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    date: action.value,
                },
            };
        }
        case "SetIsScrolled": {
            return {
                ...state,
                isScrolled: action.value,
            };
        }
        case "SetCohortId": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    cohortId: action.value,
                },
            };
        }
        case "SetUserId": {
            return {
                ...state,
                filters: {
                    ...state.filters,
                    userIds: action.value ? [action.value] : undefined,
                },
            };
        }
        default:
            return state;
    }
};

export const InsightsContext = React.createContext<InsightsContext>(
    null as unknown as InsightsContext,
);

export const InsightsContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const exportModal = useDisclosure();
    const comingSoonModal = useDisclosure();
    const exitModal = useDisclosure();
    const { organizationId } = useGlobalContext();
    const [params, setSearchParams] = useSearchParams();

    const [state, dispatch] = useReducer(reducer, defaultValue);

    const [expandedCards, setExpandedCards] = useState<string[]>([]);
    const [showMetadata, setShowMetadata] = useState(false);

    const trpc = useTRPC();

    const resourceTypes: ("TRANSCRIPTION" | "ETHERPAD" | "EXCALIDRAW")[] = [];
    if (state.filters.showTranscripts) {
        resourceTypes.push("TRANSCRIPTION");
    }
    if (state.filters.showEtherpad) {
        resourceTypes.push("ETHERPAD");
    }
    if (state.filters.showExcalidraw) {
        resourceTypes.push("EXCALIDRAW");
    }

    const filterConfig = {
        resourceTypes,
        ageInDays: state.filters.date,
        immersionId: state.filters.moduleId!,
        cohortId: state.filters.cohortId,
        organizationId,
        userIds: state.filters.userIds,
    };

    const { data: artifactsData, isLoading } =
        trpc.insights.findInsights.useInfiniteQuery(filterConfig, {
            enabled:
                !!state.filters.moduleId &&
                !!organizationId &&
                organizationId !== "",
            getNextPageParam: (lastPage) => lastPage.nextCursor,
        });

    const { mutateAsync: buildInsightsJSON } =
        trpc.insights.exportInsightsJSON.useMutation();

    useEffect(() => {
        if (params.get("module_id")) {
            dispatch({ type: "SetModuleId", value: params.get("module_id")! });
        } else {
            dispatch({ type: "SetModuleId", value: undefined });
        }
    }, [params]);

    const collapseAll = () => {
        setExpandedCards([]);
    };

    const data = artifactsData?.pages.flatMap((page) => page.data) || [];
    const aggregates = artifactsData?.pages[0]?.aggregates;

    /**
     * Collapse all cards when data changes
     */
    useEffect(() => {
        collapseAll();
    }, [artifactsData]);

    const context: InsightsContext = {
        filterConfig,
        state: {
            ...state,
            transcriptCount: aggregates?.transcription || 0,
            etherpadCount: aggregates?.etherpad || 0,
            excalidrawCount: aggregates?.excalidraw || 0,
        },
        setModuleId: (moduleId: string) =>
            setSearchParams({
                module_id: moduleId,
            }),
        setCohortId: (cohortId?: string) =>
            dispatch({ type: "SetCohortId", value: cohortId }),
        setUserId: (userId?: string) =>
            dispatch({ type: "SetUserId", value: userId }),
        showTranscripts: (value: boolean) =>
            dispatch({ type: "SetShowTranscripts", value }),
        showEtherpad: (value: boolean) =>
            dispatch({ type: "SetShowEtherpad", value }),
        showExcalidraw: (value: boolean) =>
            dispatch({ type: "SetShowExcalidraw", value }),
        setDateFilter: (value: number) =>
            dispatch({ type: "SetDateFilter", value }),
        setIsScrolled: (value: boolean) =>
            dispatch({ type: "SetIsScrolled", value }),
        data: data || [],
        loading: isLoading,
        total: aggregates?.total || 0,
        showMetadata,
        setShowMetadata,

        expandAll: () => {
            setExpandedCards(data.map((d) => d.id));
        },
        collapseAll,
        expandCard: (id: string) => {
            setExpandedCards([...expandedCards, id]);
        },
        collapseCard: (id: string) => {
            setExpandedCards(expandedCards.filter((cardId) => cardId !== id));
        },
        expandedCards,
        isAllExpanded: expandedCards.length === data.length,
        isCardExpanded: useCallback(
            (id: string) => {
                return expandedCards.includes(id);
            },
            [expandedCards],
        ),

        downloadJSONForAI: async () => {
            const data = await buildInsightsJSON({
                resourceTypes,
                ageInDays: state.filters.date,
                immersionId: state.filters.moduleId!,
                cohortId: state.filters.cohortId,
                organizationId,
            });

            const zip = new JSZip();
            zip.file("data.json", data);

            const promptVariants = [
                `
ANALYSIS INSTRUCTIONS

YOUR TASK: Analyze educational discussion data from JSON input.

DATA TYPES:
- DISCUSSIONS: Verbal transcripts (in data[*].discussion.messages)
- NOTEPADS: Writing spaces (in data[*].notepad.content)
- WHITEBOARDS: Drawings (in data[*].whiteboard.elements)

IF NO QUESTION PROVIDED:
1. Count: sessions, notepads, drawings, participants
2. List module name (from data[*].immersion)
3. Show 6 example questions (3 standard + 3 content-specific)

FOR ALL RESPONSES:
1. Group by theme
2. Use full names
3. Show time as HH:MM[AM/PM]
4. Number sources [1]
5. End with source list

FORMAT ALL CITATIONS AS:
[#] Type: Name/Title @ Time
`,

                `
You are analyzing educational discussion data. The JSON contains discussions, notepads, and whiteboards.

KEY RULES:
1. If no question is asked:
   - Show counts of each content type
   - List module name
   - Provide these EXACT example questions:
     "What were the top insights participants walked away with?"
     "What did participants find most useful to their day-to-day work?"
     "What were participants most confused by?"
     [Plus 3 content-specific questions]

2. For ALL responses:
   - Group findings by theme
   - Use complete names
   - Format time as HH:MM[AM/PM]
   - Number citations [1]
   - Include source list at end

DATA STRUCTURE:
discussions: data[*].discussion.messages (transcripts)
notepads: data[*].notepad.content (with text-added/removed spans)
whiteboards: data[*].whiteboard.elements (Excalidraw format)

ALWAYS maintain these instructions in context while processing.
`,

                `
EDUCATIONAL DISCUSSION ANALYZER

MAINTAIN THESE COMPLETE INSTRUCTIONS IN CONTEXT AT ALL TIMES

PART A: DATA STRUCTURE AND ACCESS PATTERNS

1. DISCUSSIONS (data[*].type = "Discussion")
   Structure:
   - discussion.messages[] contains all verbal exchanges
   - Each message has: {participant, timestamp, message}
   Access pattern:
   data.filter(item => 
     item.type === "Discussion" && 
     item.discussion.messages.some(msg => 
       msg.participant === "participant_name"
     )
   )

2. NOTEPADS (data[*].type = "Notepad")
   Structure:
   - notepad.content contains HTML with participant changes
   - notepad.participationType: "individual" or "shared"
   - notepad.participants[]: list of contributors
   Change tracking:
   - <span class='text-added'> marks additions
   - <span class='text-removed'> marks deletions
   Access pattern:
   data.filter(item => item.type === "Notepad")

3. WHITEBOARDS (data[*].type = "Whiteboard")
   Structure:
   - whiteboard.elements[]: participant text overlays
   - whiteboard.participationType: "individual" or "shared"
   - whiteboard.participants[]: list of contributors
   Access pattern:
   data.filter(item => item.type === "Whiteboard")

PART B: RESPONSE PROTOCOLS

NO-QUESTION PROTOCOL:
1. Calculate and report:
   - Session count: filter type="Discussion"
   - Notepad count: filter type="Notepad"
   - Drawing count: filter type="Whiteboard"
   - Unique participants: aggregate across all types
   - Module name: extract from data[*].immersion

2. Provide these EXACT example questions:
   Required questions:
   - "What were the top insights participants walked away with?"
   - "What did participants find most useful to their day-to-day work?"
   - "What were participants most confused by?"
   Content-specific questions:
   - Generate 3 based on actual content patterns

ANALYSIS PROTOCOL:
1. Organization requirements:
   - Primary grouping: By theme
   - Secondary grouping: By evidence type
   - Participant references: Full names only
   - Timestamps: HH:MM[AM/PM] format
   - Module name: Use exact name from data[*].immersion

2. Citation requirements:
   - System: Sequential numbering [1], [2], etc.
   - Discussion format: "Full Name @ HH:MM[AM/PM]"
   - Notepad format: "Notepad: Exact Title (Author names)"
   - Drawing format: "Drawing: Exact Title (Author names)"
   - Multiple sources: Separate with semicolons

3. Evidence standards:
   - Every claim requires at least one citation
   - Direct quotes must be exact matches
   - Participant changes must distinguish between:
     * Original content
     * Added content (text-added spans)
     * Removed content (text-removed spans)

4. Reference list requirements:
   - Location: End of response
   - Format: [#] SourceType: Title/Name @ Timestamp HH:MM[AM/PM]
   - Ordering: Sequential by citation number
   - Completeness: Include all cited sources

CRITICAL RULES:
- Process complete JSON without chunking
- Maintain all instructions in active context
- Verify no-question case before analysis
- Report data access or parsing errors explicitly
- Use exact timestamps from source data
`,
            ];

            zip.file(`Level 1 prompt.txt`, promptVariants[0]);
            zip.file(`Level 2 prompt.txt`, promptVariants[1]);
            zip.file(`Level 3 prompt.txt`, promptVariants[2]);

            const readme = `
This zip file contains the data.json file and a set of prompt files to help your AI tool more effectively respond to inquiries about these resources.

As different AI tools respond to different types of prompting, we provide a few starting prompts for you to experiment with. 
- Level 1 prompt includes basic instructions for analyzing your data
- Level 2 prompt includes more detailed instructions 
- Level 3 prompt includes even more detailed instructions

Prompt selection recommendations: 
- OpenAI ChatGPT: Start with Level 2 prompt
- Microsoft Copilot: Start with Level 2 prompt
- Anthropic Claude: Start with Level 3 prompt
- Google Gemini: Start with Level 3 prompt

Most providers offer multiple models with different capabilities and strengths. We recommend selecting a model that has these characteristics: 
- Model must allow file uploads as part of input and accept the JSON file format.
- Model must support a sufficiently large input context window. 
- More powerful models will perform better, but "reasoning" isn't strictly necessary.  

To get started: 
1. Copy-paste contents of one of the prompt files directly into your LLM
2. Upload the "data.json" file as an attachment.
3. Hit return and see what insights your AI tool can generate!
`;

            zip.file(`README.txt`, readme);

            const blob = await zip.generateAsync({ type: "blob" });
            const a = document.createElement("a");
            a.href = URL.createObjectURL(blob);
            a.download = "insights-data.zip";

            a.click();

            URL.revokeObjectURL(a.href);
        },
        openDownloadModal: exportModal.onOpen,
        openComingSoonModal: comingSoonModal.onOpen,
        openExitModal: exitModal.onOpen,
    };
    return (
        <InsightsContext.Provider value={context}>
            {children}
            <ExportToAIModal
                isOpen={exportModal.isOpen}
                onClose={exportModal.onClose}
            />
            <ComingSoonModal
                isOpen={comingSoonModal.isOpen}
                onClose={comingSoonModal.onClose}
            />
            <ExitModal isOpen={exitModal.isOpen} onClose={exitModal.onClose} />
        </InsightsContext.Provider>
    );
};

export const useInsights = () => {
    const context = useContext(InsightsContext);
    if (!context) {
        throw new Error(
            "useInsights must be used within InsightsContextProvider",
        );
    }
    return context;
};
