import {
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftAddon,
    Link,
} from "@chakra-ui/react";
import { TrashIcon } from "@heroicons/react/24/outline";

export const CustomBullets = ({
    customBulletsList,
    setCustomBulletsList,
}: {
    customBulletsList: string[];
    setCustomBulletsList: (value: string[]) => void;
}) => {
    return (
        <Flex flexDir="column">
            <Flex flexDir="column" gap="12px" marginY="12px">
                {customBulletsList.map((bullet, index) => (
                    <InputGroup>
                        <Input
                            value={bullet}
                            onChange={(e) => {
                                customBulletsList[index] = e.target.value;
                                console.log(customBulletsList);
                                setCustomBulletsList(
                                    customBulletsList
                                        .slice(0, index)
                                        .concat(e.target.value)
                                        .concat(
                                            customBulletsList.slice(index + 1),
                                        ),
                                );
                            }}
                            w="300px"
                        />
                        <Button
                            marginLeft="8px"
                            onClick={() => {
                                setCustomBulletsList(
                                    customBulletsList.filter(
                                        (item, i) => i !== index,
                                    ),
                                );
                            }}
                        >
                            <TrashIcon height="15px" />
                        </Button>
                    </InputGroup>
                ))}
            </Flex>
            <Link
                width="fit-content"
                fontSize="14px"
                fontWeight="500"
                onClick={() =>
                    setCustomBulletsList(customBulletsList.concat(""))
                }
            >
                + Add bullet
            </Link>
        </Flex>
    );
};
