import { FormEventHandler, useCallback, useState } from "react";
import { Session as SessionType } from "./Types";

import {
    Button,
    Button as ChakraButton,
    Checkbox,
    Flex,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Input,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    Tooltip,
    useDisclosure,
} from "@chakra-ui/react";
import { LinkIcon, QuestionMarkCircleIcon } from "@heroicons/react/24/outline";
import { ReactComponent as ExternalLinkIcon } from "design/assets/ExternalLinkIcon.svg";
import { DateTime } from "luxon";
import { useGlobalContext } from "../context";

type PostSessionLink = {
    url: string;
    name: string;
    autoRedirect: boolean;
};

export const PostSessionLinks: React.FC<{
    session: SessionType;
    onChange: (link: PostSessionLink) => void;
    onRemove: () => void;
}> = ({ session, onChange, onRemove }) => {
    const { timezoneName } = useGlobalContext();
    const sessionDuration = session.immersion.length || 60;
    const sessionEndTime = DateTime.fromISO(session.time)
        .plus({
            minutes: sessionDuration,
        })
        .setZone(timezoneName)
        .toFormat("hh:mma")
        .toLowerCase();
    const url = session.postSessionRedirectUrl;
    const linkName = session.postSessionRedirectName;
    const autoRedirect = session.postSessionAutoRedirect || false;
    const exists = !!url && !!linkName;
    const [editMode, setEditMode] = useState(false);
    const warningModal = useDisclosure();
    return (
        <Flex direction="column" mt={6} alignItems={"flex-start"}>
            <Flex gap={"2px"}>
                <Text
                    fontSize="16px"
                    fontWeight={600}
                    lineHeight={"19px"}
                    color="dark.600"
                    mb="4px"
                >
                    Custom redirect link
                </Text>
                {editMode && !exists && (
                    <Tooltip
                        label={
                            <Flex direction={"column"} gap={"8px"}>
                                <Text
                                    color="dark.800"
                                    fontSize={"14px"}
                                    fontWeight={600}
                                >
                                    Your custom link will look like this:
                                </Text>
                                <PostSessionLinkPreview
                                    name="[redirect target name]"
                                    url=""
                                />
                            </Flex>
                        }
                        color="gray.600"
                        bg={"white"}
                        padding={"12px"}
                        borderRadius={"12px"}
                        maxW="500px"
                    >
                        <QuestionMarkCircleIcon
                            color="dark.600"
                            display={"inline"}
                            height="19px"
                            cursor={"pointer"}
                        />
                    </Tooltip>
                )}
            </Flex>
            <Text fontSize={"13px"} fontWeight={400} color="dark.400">
                Add a link to send participants to at the end of the session.
            </Text>
            {exists && !editMode ? (
                <Flex direction={"column"} gap={"8px"}>
                    <PostSessionLinkPreview url={url} name={linkName} />
                    <Text color={"dark.400"} fontSize={"13px"} fontWeight={400}>
                        {autoRedirect
                            ? `(Participants will automatically be redirected to your link at session end time)`
                            : `(Participants will not be automatically be redirected to your link at session end time)`}
                    </Text>
                </Flex>
            ) : null}
            {!editMode && !exists && (
                <Flex>
                    <ChakraButton
                        color="gray.900"
                        variant="outline"
                        mt={"16px"}
                        onClick={() => setEditMode(true)}
                    >
                        <LinkIcon height="17px" />
                        Add link
                    </ChakraButton>
                </Flex>
            )}
            {editMode && (
                <PostSessionLinkForm
                    onCancel={() => setEditMode(false)}
                    onSubmit={(url, name, autoRedirect) => {
                        onChange({
                            url,
                            name,
                            autoRedirect,
                        });
                        setEditMode(false);
                    }}
                    initialValue={
                        url && linkName
                            ? {
                                  url,
                                  name: linkName,
                                  autoRedirect,
                              }
                            : undefined
                    }
                    sessionEndTime={sessionEndTime}
                />
            )}
            {exists && !editMode && (
                <Flex>
                    <ChakraButton
                        color="gray.900"
                        variant="outline"
                        mt={"16px"}
                        onClick={() => setEditMode(true)}
                        fontWeight={600}
                    >
                        Edit
                    </ChakraButton>
                    <ChakraButton
                        color="gray.900"
                        variant="outline"
                        mt={"16px"}
                        onClick={() => warningModal.onOpen()}
                        ml="8px"
                        fontWeight={400}
                    >
                        Remove
                    </ChakraButton>
                </Flex>
            )}
            <Modal
                size="lg"
                isOpen={warningModal.isOpen}
                onClose={() => warningModal.onClose()}
                isCentered
            >
                <ModalOverlay />
                <ModalContent px="28px" pt="24px" pb="12px">
                    <ModalBody p={0}>
                        <Text
                            color="black.900"
                            fontSize="18px"
                            fontWeight={600}
                            mb="16px"
                        >
                            Are you sure you want to remove your custom URL?
                        </Text>
                        <Text color="dark.700" fontSize="13px" fontWeight={400}>
                            Participants will no longer be redirected to this
                            URL at the end of the session.
                        </Text>
                    </ModalBody>
                    <ModalFooter p={0}>
                        <Flex mt="24px">
                            <Button
                                variant="ghost"
                                onClick={() => warningModal.onClose()}
                            >
                                Cancel
                            </Button>
                            <Button
                                ml="8px"
                                colorScheme="black"
                                onClick={() => {
                                    onRemove();
                                    warningModal.onClose();
                                }}
                            >
                                Remove
                            </Button>
                        </Flex>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </Flex>
    );
};

const PostSessionLinkPreview: React.FC<{
    url: string;
    name: string;
}> = ({ url, name }) => {
    return (
        <Flex
            p="8px"
            border="1px solid"
            borderColor="gray.100"
            borderRadius="12px"
            mt="16px"
        >
            <Flex mb="16px">
                <ExternalLinkIcon />
                <Flex direction={"column"} ml="24px">
                    <Text
                        color="gray.600"
                        fontSize="14px"
                        fontWeight={600}
                        mb="8px"
                    >
                        Heads up!
                    </Text>
                    <Text color="gray.400" fontSize="12px" fontWeight={400}>
                        When you continue, you will be taken to{" "}
                        <Link
                            href={url}
                            target="_blank"
                            color="blue.500"
                            textDecoration={"underline"}
                            fontWeight={600}
                        >
                            {name}
                        </Link>
                        {"."}
                    </Text>
                </Flex>
            </Flex>
        </Flex>
    );
};

const PostSessionLinkForm: React.FC<{
    onCancel?: () => void;
    onSubmit: (url: string, linkName: string, autoRedirect: boolean) => void;
    initialValue?: PostSessionLink;
    sessionEndTime: string;
}> = ({ onCancel, onSubmit, initialValue, sessionEndTime }) => {
    const [url, setUrl] = useState(initialValue?.url || "");
    const [linkName, setLinkName] = useState(initialValue?.name || "");
    const [autoRedirect, setAutoRedirect] = useState(
        initialValue?.autoRedirect || false,
    );
    const [urlError, setUrlError] = useState<string | null>(null);
    const [linkNameError, setLinkNameError] = useState<string | null>(null);
    const canSubmit = url !== "" && linkName !== "";
    const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        if (validate()) {
            onSubmit(url, linkName, autoRedirect);
        }
    };
    const validate = useCallback(() => {
        let hasError = false;
        setUrlError(null);
        setLinkNameError(null);
        if (url.trim() === "") {
            setUrlError("Please enter a link");
            hasError = true;
        } else {
            try {
                new URL(url.trim());
            } catch (e) {
                setUrlError("Please enter a valid link");
                hasError = true;
            }
        }
        if (linkName.trim() === "") {
            setLinkNameError("Please enter a link name");
            hasError = true;
        }
        return !hasError;
    }, [url, linkName]);
    return (
        <Flex mt="16px" direction="column">
            <form onSubmit={handleSubmit}>
                <Flex>
                    <FormControl isInvalid={!!urlError}>
                        <FormLabel
                            fontSize="14px"
                            fontWeight={600}
                            color="dark.600"
                        >
                            Paste a link:
                        </FormLabel>
                        <Input
                            value={url}
                            onChange={(_) => setUrl(_.target.value)}
                            onBlur={validate}
                        />
                        {urlError && (
                            <FormErrorMessage>{urlError}</FormErrorMessage>
                        )}
                    </FormControl>
                    <FormControl ml="16px" isInvalid={!!linkNameError}>
                        <FormLabel
                            fontSize="14px"
                            fontWeight={600}
                            color="dark.600"
                        >
                            Link name:
                        </FormLabel>
                        <Input
                            type="text"
                            value={linkName}
                            onChange={(_) => setLinkName(_.target.value)}
                            onBlur={validate}
                        />
                        {linkNameError && (
                            <FormErrorMessage>{linkNameError}</FormErrorMessage>
                        )}
                    </FormControl>
                </Flex>
                <Flex mt={"8px"}>
                    <FormControl>
                        <Checkbox
                            defaultChecked={autoRedirect}
                            onChange={(_) => setAutoRedirect(_.target.checked)}
                        >
                            <Text
                                fontSize={"13px"}
                                fontWeight={400}
                                color={"gray.500"}
                            >
                                Participants should be auto-redirected to this
                                link at {sessionEndTime}
                            </Text>
                        </Checkbox>
                    </FormControl>
                </Flex>
                <Flex mt="16px">
                    <ChakraButton
                        fontSize="14px"
                        fontWeight={600}
                        type="submit"
                        colorScheme="blue"
                        isDisabled={!canSubmit}
                    >
                        Save
                    </ChakraButton>
                    <ChakraButton
                        ml="16px"
                        variant="outline"
                        fontSize="14px"
                        fontWeight={600}
                        onClick={onCancel}
                    >
                        Cancel
                    </ChakraButton>
                </Flex>
            </form>
        </Flex>
    );
};
