import React, { useState } from "react";
import useShowHide from "../../hooks/useShowHide";
import Form from "./Form";

const useSetPassword = ({
    changePassword,
    disabled,
    label = "Change Password",
}: {
    changePassword: (password: string) => Promise<void>;
    disabled?: boolean;
    label: string;
}) => {
    const {
        show: showSetPassword,
        ShowHideButton,
        setHidden,
    } = useShowHide(false);

    const [password, setPassword] = useState("");

    const SetPasswordForm = () => (
        <Form
            change={(e) => setPassword(e.currentTarget.value)}
            submit={async () => {
                await changePassword(password);
                setPassword("");
            }}
            disabled={disabled || !showSetPassword}
            label={label}
            saveLabel="Change Password"
            cancel={setHidden}
            value={password}
        />
    );

    return {
        SetPasswordForm: (
            <ShowHideButton
                label="Change Password"
                component={<SetPasswordForm />}
            />
        ),
        password,
        setHidden,
        setPassword,
        showSetPassword,
    };
};

export default useSetPassword;
