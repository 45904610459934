import Table from "../common/Table";
import { NavLink, Link as ReactRouterLink } from "react-router-dom";
import { Link, Tooltip } from "@chakra-ui/react";
import { timeParser } from "../../utils";
import { SessionParticipant } from "../../App/Types";
import { Box, Divider, Flex, Tag, Text } from "@chakra-ui/react";
import {
    RoomProvider,
    useOthers,
    useSelf,
    useStatus,
} from "../../liveblocks.config";
import { useIsSuperAdmin } from "../../hooks/useUserData";

interface Props {
    data: {
        [page: string]: any[];
    };
    hidePages: boolean;
    onPageChange: (page: number) => void;
    page: number;
    sortDirection: "asc" | "desc";
    hideSessionCount?: boolean;
    showOrganization?: boolean;
    showActive?: boolean;
    showAdvanced?: boolean;
}

const SessionTable = (props: Props) => {
    const isSuperAdmin = useIsSuperAdmin();
    const columns: (
        | [string, string]
        | [
              string,
              string,
              (field: any) => string | Date | JSX.Element,
              boolean?,
          ]
    )[] = [
        ["time", "Time", timeParser],
        [
            "participants",
            "Participants",
            (participants: any) => {
                return displayParticipants(
                    participants.filter(
                        (participant: SessionParticipant) =>
                            participant.groupId &&
                            (participant.firstVisitGroup ||
                                participant.lastVisitGroup),
                    ),
                    !!props.showActive,
                );
            },
            true,
        ],
        [
            "participants",
            "Absentees",
            (participants: any): string => {
                return participants
                    .filter(
                        (participant: SessionParticipant) =>
                            !participant.groupId ||
                            !(
                                participant.firstVisitGroup ||
                                participant.lastVisitGroup
                            ),
                    )
                    .map(displayParticipant)
                    .join("\n");
            },
        ],
        ["cohortName", "Cohort"],
        ["groupingStrategy", "Grouping"],
    ];

    if (props.showAdvanced) {
        columns.push(["fullyScheduled", "Fully Scheduled"]);
        columns.push(["resourceStatus", "Resource Status"]);
    }

    if (props.showActive) {
        columns.push(["active", "Active"]);
    }

    if (isSuperAdmin) {
        columns.splice(1, 0, ["moduleCode", "Module Code"]);
    } else {
        columns.splice(1, 0, ["moduleName", "Module"]);
        columns.splice(1, 0, ["category", "Category"]);
    }

    if (props.showOrganization) {
        columns.splice(1, 0, ["account", "Account"]);
    }

    return props.data?.["0"]?.length ? (
        <Table
            columns={columns}
            data={props.data}
            hidePages={props.hidePages}
            initialSortField={"time"}
            initialSortDirection={props.sortDirection}
            onPageChange={props.onPageChange}
            page={props.page}
            searchable={["account", "moduleCode", "moduleName", "category", "cohortName"]}
            multiselectable={["groupingStrategy"]}
            sortable={true}
        />
    ) : (
        <Text color="gray.600">No sessions</Text>
    );
};

const displayParticipant = (participant: SessionParticipant) => {
    return participant.user.firstName || participant.user.lastName
        ? `${participant.user.firstName} ${participant.user.lastName}`
        : participant.user.emails.map((email) => email.email).join(", ");
};

const displayParticipants = (
    participants: SessionParticipant[],
    showActive: boolean,
) => {
    const groupToParticipants = participants.reduce(
        (map, p) => map.set(p.groupId, [...(map.get(p.groupId) || []), p]),
        new Map<string, SessionParticipant[]>(),
    );
    return (
        <Box>
            {participants.length > 1 && <Divider></Divider>}
            {Array.from(groupToParticipants.entries()).map(
                ([group, participants]) => {
                    return showActive ? (
                        <RoomProvider
                            id={group}
                            initialPresence={{ stepNumber: 0 }}
                            key={group}
                        >
                            <GroupParticipants participants={participants} />
                        </RoomProvider>
                    ) : (
                        <Flex flexDir="column" key={group}>
                            {participants.map((p) => (
                                <Link
                                    as={ReactRouterLink}
                                    to={`/session/${p.timeslotId}`}
                                    key={p.id}
                                    paddingRight="5px"
                                    color="gray.600"
                                    fontStyle="underline"
                                    paddingX="1rem"
                                    fontSize="14px"
                                >
                                    {displayParticipant(p)}
                                </Link>
                            ))}
                        </Flex>
                    );
                },
            )}
        </Box>
    );
};

const GroupParticipants = ({
    participants,
}: {
    participants: SessionParticipant[];
}) => {
    const status = useStatus();
    const others = useOthers();
    const selfObject = useSelf();
    const othersMap = Object.fromEntries(others.map((o) => [o.id, o]));
    if (selfObject) {
        othersMap[selfObject.id!] = selfObject;
    }

    return (
        <Tooltip label={`Liveblocks status: ${status}`}>
            <Flex flexDir={"column"}>
                {participants.map((p) => (
                    <Text paddingX="1rem" fontSize="14px" key={p.id}>
                        <Flex>
                            <NavLink to={`/session/${p.timeslotId}`}>
                                <Link
                                    paddingRight="5px"
                                    color="rgb(3, 105, 161)"
                                    whiteSpace={"nowrap"}
                                >
                                    {displayParticipant(p)}
                                </Link>
                            </NavLink>
                            {othersMap[p.user.emails[0].email] && (
                                <Tag
                                    bg={
                                        othersMap[p.user.emails[0].email]
                                            ?.presence.connectedToTwilio
                                            ? "#47C26C"
                                            : "#FFD233"
                                    }
                                    color={
                                        othersMap[p.user.emails[0].email]
                                            ?.presence.connectedToTwilio
                                            ? "white"
                                            : "#2B2D31"
                                    }
                                    size="sm"
                                    borderRadius="full"
                                    fontSize="12px"
                                    whiteSpace={"nowrap"}
                                >
                                    Step{" "}
                                    {
                                        othersMap[p.user.emails[0].email]
                                            .presence.stepNumber
                                    }
                                </Tag>
                            )}
                        </Flex>
                    </Text>
                ))}
                {participants.length >= 1 && <Divider></Divider>}
            </Flex>
        </Tooltip>
    );
};

export default SessionTable;
