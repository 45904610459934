import axios from "axios";
import { DateTime } from "luxon";
import { Params, useParams } from "react-router-dom";
import Tooltip from "./components/factories/Tooltip";
import { EventStatus } from "@prisma/client";

export const API_SERVER_ROOT = `${process.env.REACT_APP_API_ROOT}/admin`;
export const COMMIT = process.env.REACT_APP_COMMIT;

export const GET = "GET";
export const POST = "POST";
export const PUT = "PUT";
export const DELETE = "DELETE";
export type METHOD = "GET" | "POST" | "PUT" | "DELETE";

export const fetchWithAccessToken = (accessToken: string, method: METHOD) => {
    return async (endpoint: string, data: object = {}) => {
        const config = {
            headers: {
                Authorization: `Bearer ${accessToken}`,
            },
        };
        try {
            if (method === "GET") {
                const response = await axios.get(
                    `${API_SERVER_ROOT}/${endpoint}`,
                    config,
                );
                return response.data;
            } else if (method === "POST") {
                const response = await axios.post(
                    `${API_SERVER_ROOT}/${endpoint}`,
                    data,
                    config,
                );
                return response.data;
            } else if (method === "PUT") {
                const response = await axios.put(
                    `${API_SERVER_ROOT}/${endpoint}`,
                    data,
                    config,
                );
                return response.data;
            } else if (method === "DELETE") {
                const response = await axios.delete(
                    `${API_SERVER_ROOT}/${endpoint}`,
                    config,
                );
                return response.data;
            }
        } catch (error) {
            if (axios.isAxiosError(error)) {
                throw new Error(
                    (error as any).response?.data || (error as any).message,
                );
            }
        }
    };
};

export const timeParser = (time: string) => {
    return new Date(time);
};

export const formatTime = (time: string | null, timeZone: string) => {
    if (!time) {
        return "";
    }
    return new Date(time).toLocaleString("en-US", {
        timeZone,
    });
};

// Convert Luxon DateTime to UTC ISO string and strip seconds and milliseconds
export const convertDateTimeToISO = (dateTime: DateTime) => {
    return dateTime.toUTC().set({ second: 0, millisecond: 0 }).toISO();
};

// Convert Luxon DateTime to a timezone and return as plain JS Date object
export const localizeTimeToJSDate = (
    datetime: DateTime,
    timezoneName: string,
) => {
    const localDateTime = datetime.setZone(timezoneName);
    return new Date(
        localDateTime.toISO({
            suppressMilliseconds: true,
            includeOffset: false,
            extendedZone: false,
        })!,
    );
};

// Convert a plain JS Date object to a Luxon DateTime with a timezone
export const convertLocalJSDateToDateTime = (
    localDatetime: Date,
    timezoneName: string,
): DateTime => {
    return DateTime.fromObject(
        {
            year: localDatetime.getFullYear(),
            month: localDatetime.getMonth() + 1,
            day: localDatetime.getDate(),
            hour: localDatetime.getHours(),
            minute: localDatetime.getMinutes(),
            second: 0,
        },
        {
            zone: timezoneName,
        },
    );
};

export const isoTimeSameMinute = (time1: string, time2: string) => {
    const date1 = new Date(time1);
    const date2 = new Date(time2);
    return (
        date1.getUTCFullYear() === date2.getUTCFullYear() &&
        date1.getUTCMonth() === date2.getUTCMonth() &&
        date1.getUTCDate() === date2.getUTCDate() &&
        date1.getUTCHours() === date2.getUTCHours() &&
        date1.getUTCMinutes() === date2.getUTCMinutes()
    );
};

export interface ParamsProps {
    [key: string]: any;
    params: Params;
}

export const withParams = (Component: any) => {
    return (props: ParamsProps) => (
        <Component {...props} params={useParams()} />
    );
};

export const attendedSession = (sessionParticipant: {
    firstVisitGroup: string;
    lastVisitGroup: string;
}) => {
    return (
        sessionParticipant.firstVisitGroup !== null &&
        sessionParticipant.lastVisitGroup !== null
    );
};

export const matchingEventsForType = (
    session: {
        events: {
            type: string;
            status: "IN_PROGRESS" | "PENDING" | "COMPLETE" | "CANCELLED";
            event: any;
            createdOn: string;
            scheduledOn: string;
            completedOn: string;
        }[];
    },
    participant: { id: string; user: { emails: { email: string }[] } },
    type: string,
    eventType?: string,
) => {
    return session.events
        .sort((a, b) =>
            (a.scheduledOn || a.createdOn) < (b.scheduledOn || b.createdOn)
                ? 1
                : -1,
        )
        .filter(
            (event) =>
                event.type === type &&
                (event.status === "IN_PROGRESS" ||
                    event.status === "PENDING" ||
                    event.status === "COMPLETE") &&
                (!eventType || event.event.type === eventType) &&
                (participant.id === event.event.participantId ||
                    participant.id === event.event.sessionParticipantId ||
                    (event.event.participantIds &&
                        event.event.participantIds.includes(participant.id))),
        ) as {
        type: string;
        status: "IN_PROGRESS" | "PENDING" | "COMPLETE";
        event: any;
        scheduledOn: string;
        completedOn: string;
        createdOn: string;
    }[];
};

export const matchingEventsByGroup = (
    session: {
        events: {
            type: string;
            status: EventStatus;
            event: any;
            createdOn: string;
            scheduledOn: string;
            completedOn: string;
        }[];
    },
    groupId: string,
    type: string,
) => {
    return session.events
        .sort((a, b) =>
            (a.scheduledOn || a.createdOn) < (b.scheduledOn || b.createdOn)
                ? 1
                : -1,
        )
        .filter(
            (event) =>
                event.type === type &&
                event.status !== "CANCELLED" &&
                event.event.groupIds?.includes(groupId),
        );
};

export const matchingEventForType = (
    session: {
        events: {
            type: string;
            status: "IN_PROGRESS" | "PENDING" | "COMPLETE" | "CANCELLED";
            event: any;
            createdOn: string;
            scheduledOn: string;
            completedOn: string;
        }[];
    },
    participant: { id: string; user: { emails: { email: string }[] } },
    type: string,
    eventType?: string,
) => {
    return matchingEventsForType(session, participant, type, eventType)[0];
};

export const displayEvent = (
    event?: {
        status: EventStatus;
        scheduledOn: string;
        completedOn: string;
        createdOn: string;
    },
    timezone?: string,
    disabled?: boolean,
    index?: number,
) => {
    if (event && event.status === "COMPLETE") {
        return (
            <div key={`event-${index}`} className="flex text-gray-300">
                ✅
                <Tooltip
                    text={
                        event.completedOn
                            ? `Completed on ${formatTime(
                                  event.completedOn,
                                  timezone || "UTC",
                              )}`
                            : `Completed (see table)`
                    }
                />
            </div>
        );
    } else if (disabled) {
        return (
            <div key={`event-${index}`} className="flex text-gray-300">
                ➖
                <Tooltip text="Disabled by settings" />
            </div>
        );
    } else if (
        event &&
        (event.status === "PENDING" || event.status === "IN_PROGRESS")
    ) {
        return (
            <div key={`event-${index}`} className="flex text-gray-300">
                ⏲️
                <Tooltip
                    text={
                        event.scheduledOn
                            ? `Scheduled for ${formatTime(
                                  event.scheduledOn,
                                  timezone || "UTC",
                              )}`
                            : `In progress`
                    }
                />
            </div>
        );
    } else {
        return (
            <div key={`event-${index}`} className="flex text-gray-300">
                ❌
                <Tooltip text="Not scheduled" />
            </div>
        );
    }
};

export const isFullyScheduled = (session: {
    actionSchedule: {
        email: {
            [key: string]: string;
        };
    };
    events: {
        type: string;
        status: "IN_PROGRESS" | "PENDING" | "COMPLETE";
        event: any;
        scheduledOn: string;
        completedOn: string;
        createdOn: string;
    }[];
    participants: {
        id: string;
        user: { emails: { email: string }[] };
    }[];
}) => {
    for (const participant of session.participants) {
        if (!matchingEventForType(session, participant, "CALENDAR_INVITE")) {
            return false;
        }
        if (
            !Object.keys(session.actionSchedule.email).every((type) =>
                matchingEventForType(session, participant, "EMAIL", type),
            )
        ) {
            return false;
        }
    }

    if (
        session.participants.length &&
        !session.events.find((event) => event.type === "CLONE_RESOURCES")
    ) {
        return false;
    }

    return true;
};

// This function should be moved to shared package, but shared is currently broken this
// function is duplicated here and in apps/web/src/utils.tsx
export const getCookie = (cookies: string, name: string): string | null => {
    return (
        cookies
            .split(";")
            .map((c) => c.trim())
            .filter((cookie) => {
                return cookie.substring(0, name.length + 1) === `${name}=`;
            })
            .map((cookie) => {
                return decodeURIComponent(cookie.substring(name.length + 1));
            })[0] || null
    );
};
