import { useEffect, useState } from "react";
import Loading from "../components/common/Loading";
import SessionsByTime from "../components/factories/SessionsByTime";
import SessionTable from "../components/factories/SessionTable";
import { useGlobalContext } from "../context";
import { RoomProvider } from "../liveblocks.config";
import { isFullyScheduled } from "../utils";
import { Presence } from "./Presence";
import { useIsSuperAdmin } from "../hooks/useUserData";
import {
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react";

const Status = () => {
    const context = useGlobalContext();
    const [error, setError] = useState("");
    const [sessions, setSessions] = useState<any[]>([]);
    const [pending, setPending] = useState(true);
    const isSuperAdmin = useIsSuperAdmin();

    const endpoint = "sessions/status";

    const fetchSessions = async () => {
        try {
            const sessions = await context.get(endpoint);
            for (const session of sessions) {
                session.link = `/session/${session.id}`;
                session.fullyScheduled = isFullyScheduled(session)
                    ? "✅"
                    : "❌";
                session.moduleCode = session.immersion.name;

                // In the future, we'll want to remove this column entirely.
                if (session.groupingStrategy === "FIXED") {
                    session.active = (
                        <RoomProvider
                            id={`${session.timeslotGroups[0].id}`}
                            initialPresence={{ stepNumber: 0 }}
                        >
                            <Presence
                                members={session.participants.map(
                                    (p: any) => p.user,
                                )}
                            ></Presence>
                        </RoomProvider>
                    );
                }
            }
            setSessions(sessions);
        } catch (error) {
            setError((error as any).message);
        }
        setPending(false);
    };

    useEffect(() => {
        fetchSessions();
    }, []);

    if (!sessions || pending) {
        return <Loading error={error} />;
    }

    return (
        <Tabs variant="unstyled">
            <TabList>
                <Tab
                    color="dark.500"
                    borderRadius="8px"
                    fontWeight="600"
                    _selected={{ bgColor: "gray.50", color: "blue.600" }}
                >
                    Current Sessions
                </Tab>
                <Tab
                    color="dark.500"
                    borderRadius="8px"
                    fontWeight="600"
                    _selected={{ bgColor: "gray.50", color: "blue.600" }}
                >
                    Future Sessions
                </Tab>
                <Tab
                    color="dark.500"
                    borderRadius="8px"
                    fontWeight="600"
                    _selected={{ bgColor: "gray.50", color: "blue.600" }}
                >
                    Past Sessions
                </Tab>
            </TabList>
            <TabPanels>
                <TabPanel mt="24px">
                    <Text
                        color="gray.600"
                        fontWeight="600"
                    >{`${sessions.length} currently accessible session${sessions.length === 1 ? "" : "s"}`}</Text>
                    <SessionTable
                        data={{ 0: sessions }}
                        hidePages={true}
                        hideSessionCount={true}
                        onPageChange={() => {}}
                        page={0}
                        sortDirection={"asc"}
                        showActive={true}
                        showAdvanced={isSuperAdmin}
                    />
                </TabPanel>
                <TabPanel>
                    <SessionsByTime all={true} pastOrFuture="future" />
                </TabPanel>
                <TabPanel>
                    <SessionsByTime all={true} pastOrFuture="past" />
                </TabPanel>
            </TabPanels>
        </Tabs>
    );
};

export default Status;
