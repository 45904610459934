import { Box, Flex, Icon, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";
import { ReactComponent as Transcript } from "design/assets/Transcript.svg";
import { useGlobalContext } from "../../context";
import { ArtifactCard, ArtifactCardProps } from "./ArtifactCard";
import {
    InsightsArtifact,
    InsightsTranscriptArtifact,
} from "./InsightsContext";
import { DateTime } from "luxon";

interface TranscriptItem {
    id: string;
    name: string;
    time: number;
    stepName: string;
    stepNumber: number;
    message: string;
}

export const TranscriptCard: React.FC<
    ArtifactCardProps & {
        transcript: InsightsTranscriptArtifact;
        artifact: InsightsArtifact;
    }
> = (props) => {
    const context = useGlobalContext();
    const tz = context.timezoneName || "UTC";
    const items: TranscriptItem[] = props.transcript.messages.map((message) => {
        return {
            id: message.id,
            name: message.participant.name,
            time: message.timestamp,
            stepName: message.step.name,
            stepNumber: message.step.number,
            message: message.message,
        };
    });
    let prevItem: TranscriptItem | null = null;
    const groupedItems: Array<{
        id: string;
        firstItem: TranscriptItem;
        items: TranscriptItem[];
    }> = items.reduce(
        (
            acc: Array<{
                id: string;
                firstItem: TranscriptItem;
                items: TranscriptItem[];
            }>,
            item: TranscriptItem,
            index: number,
        ) => {
            if (!prevItem) {
                prevItem = item;
                return [
                    {
                        id: item.id,
                        firstItem: item,
                        items: [item],
                    },
                ];
            }
            if (
                prevItem.name === item.name &&
                Math.abs(item.time - prevItem.time) < 15000
            ) {
                prevItem = item;
                const lastEntry = acc[acc.length - 1];
                if (lastEntry) {
                    lastEntry.items.push(item);
                    return acc;
                }
            }
            prevItem = item;
            return [
                ...acc,
                {
                    id: item.id,
                    firstItem: item,
                    items: [item],
                },
            ];
        },
        [],
    );

    let lastStep: {
        name: string;
        number: number;
    } | null = null;

    const transcriptItems: Array<
        | {
              type: "step";
              step: {
                  name: string;
                  number: number;
              };
          }
        | {
              type: "group";
              group: {
                  id: string;
                  firstItem: TranscriptItem;
                  items: TranscriptItem[];
              };
          }
    > = [];

    groupedItems.forEach((group) => {
        if (!lastStep) {
            lastStep = {
                name: group.firstItem.stepName,
                number: group.firstItem.stepNumber,
            };
            transcriptItems.push({
                type: "step",
                step: lastStep,
            });
        } else if (lastStep.number !== group.firstItem.stepNumber) {
            lastStep = {
                name: group.firstItem.stepName,
                number: group.firstItem.stepNumber,
            };
            transcriptItems.push({
                type: "step",
                step: lastStep,
            });
        }
        transcriptItems.push({
            type: "group",
            group,
        });
    });

    const participantIds = Array.from(
        new Set(props.transcript.messages.map((_) => _.participant.id)),
    );
    const participants = participantIds.map((id) => {
        return props.transcript.messages
            .map((_) => _.participant)
            .find((_) => _.id === id)!;
    });
    const metadata = {
        immersionName: props.artifact.immersion.name,
        immersionId: props.artifact.immersion.id,
        participants: participants.map((_) => ({
            userId: _.userId,
            name: _.name,
        })),
        date: new Date(props.artifact.time),
        timeslotId: props.artifact.timeslotId,
    };

    const date = DateTime.fromMillis(
        transcriptItems.filter((_) => _.type === "group")[0].group.firstItem
            .time,
    )
        .setZone(tz)
        .toFormat(`yyyy-MM-dd' at' hh:mm a`)
        .toLowerCase();

    return (
        <ArtifactCard
            title="Discussion"
            subtitle={
                <>
                    {date}{" "}
                    <Text
                        as="span"
                        display={"inline"}
                        fontWeight={400}
                        fontSize={"12px"}
                        color={"dark.400"}
                    >
                        ({tz})
                    </Text>
                </>
            }
            icon={<Icon as={Transcript} mb={1} mr={1} />}
            metadata={metadata}
            participants={participants}
            type={props.artifact.type}
            {...props}
        >
            <Box
                paddingX="24px"
                paddingY="16px"
                pb={props.expanded ? "42px" : undefined}
            >
                <Table variant={"unstyled"} size={"sm"}>
                    <Tbody>
                        {transcriptItems.map((transcriptItem, index) => {
                            if (transcriptItem.type === "step") {
                                return (
                                    <Tr key={index.toString()}>
                                        <Td colSpan={2} pb={"16px"} pt={"16px"}>
                                            <Box
                                                bg={"white"}
                                                w={"100%"}
                                                top={0}
                                            >
                                                <Flex
                                                    direction={"row"}
                                                    grow={1}
                                                    shrink={0}
                                                    alignItems={"center"}
                                                >
                                                    <Flex
                                                        py={"2px"}
                                                        px={"6px"}
                                                        wrap={"nowrap"}
                                                    >
                                                        <Text
                                                            color={"blue.300"}
                                                            fontSize={"12px"}
                                                            fontWeight={400}
                                                            whiteSpace={
                                                                "nowrap"
                                                            }
                                                        >
                                                            <b>
                                                                Step{" "}
                                                                {
                                                                    transcriptItem
                                                                        .step
                                                                        .number
                                                                }
                                                                :
                                                            </b>{" "}
                                                            {
                                                                transcriptItem
                                                                    .step.name
                                                            }
                                                        </Text>
                                                    </Flex>
                                                    <Flex
                                                        borderTop={"1px solid"}
                                                        borderColor={"blue.200"}
                                                        width={"100%"}
                                                    />
                                                </Flex>
                                            </Box>
                                        </Td>
                                    </Tr>
                                );
                            }
                            const item = transcriptItem.group;
                            return (
                                <Tr key={index.toString()}>
                                    <Td
                                        textAlign={"right"}
                                        pr={"6px"}
                                        pb={"12px"}
                                        verticalAlign={"top"}
                                    >
                                        <Text
                                            display={"inline"}
                                            fontSize={"14px"}
                                            fontWeight={600}
                                            lineHeight={"22px"}
                                            whiteSpace={"nowrap"}
                                        >
                                            {item.firstItem.name}
                                        </Text>
                                    </Td>
                                    <Td pl={"6px"} pb={"12px"}>
                                        <Text
                                            fontSize={"14px"}
                                            lineHeight={"22px"}
                                            fontWeight={400}
                                            whiteSpace={"pre-line"}
                                        >
                                            {item.items
                                                .map((_) => _.message)
                                                .join("\n")}
                                        </Text>
                                    </Td>
                                </Tr>
                            );
                        })}
                    </Tbody>
                </Table>
            </Box>
        </ArtifactCard>
    );
};
