import {
    Box,
    Flex,
    Icon,
    ListItem,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import { ReactComponent as Paintbrush } from "design/assets/Paintbrush.svg";
import { ArtifactCard, ArtifactCardProps } from "./ArtifactCard";
import {
    InsightsArtifact,
    InsightsExcalidrawArtifact,
} from "./InsightsContext";

export const WhiteboardCard: React.FC<
    ArtifactCardProps & {
        artifact: InsightsArtifact;
        excalidraw: InsightsExcalidrawArtifact;
    }
> = (props) => {
    const { artifact, excalidraw } = props;
    const metadata = {
        immersionName: artifact.immersion.name,
        immersionId: artifact.immersion.id,
        participants: excalidraw.participants || [],
        steps: excalidraw.steps || [],
        date: new Date(artifact.time),
        timeslotId: artifact.timeslotId,
        cohort: artifact.cohort || undefined,
    };

    return (
        <ArtifactCard
            title={"Whiteboard"}
            subtitle={excalidraw.resourceName}
            icon={<Icon as={Paintbrush} mb={1} mr={1} />}
            groupType={excalidraw.participationType}
            metadata={metadata}
            participants={excalidraw.participants || []}
            type={artifact.type}
            {...props}
        >
            <Flex
                paddingX="40px"
                paddingY="24px"
                alignItems={"center"}
                direction={"column"}
            >
                <Flex
                    direction={"column"}
                    color={"gray.600"}
                    fontSize={"14px"}
                    lineHeight={"22px"}
                    fontWeight={400}
                    pb={props.expanded ? "42px" : undefined}
                >
                    <Text fontSize={"16px"} fontWeight={600} mb={"24px"}>
                        Extracted text from this activity
                    </Text>
                    <UnorderedList>
                        {excalidraw.extractedText.map((text, index) => (
                            <ListItem key={index}>
                                <Text>{text}</Text>
                            </ListItem>
                        ))}
                    </UnorderedList>
                </Flex>
            </Flex>
        </ArtifactCard>
    );
};
