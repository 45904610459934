import {
    Button,
    Flex,
    Grid,
    GridItem,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TabPanel,
    Text,
    Textarea,
    Tooltip,
} from "@chakra-ui/react";
import { CalendarBulletsType, EventSendSettings } from "@prisma/client";
import { CustomBullets } from "./CustomBullets";
import { CUSTOM_PARAGRAPH_HINT } from "./constants";

export const CalendarInvites = ({
    unsavedChanges,
    previewCalendarInvite,
    calendarBulletsType,
    setCalendarBulletsType,
    customBulletsList,
    setCustomBulletsList,
    setSendCalendarInvites,
    sendCalendarInvites,
    preCalendarInviteText,
    setPreCalendarInviteText,
    defaultCalendarBullets,
}: {
    unsavedChanges: boolean;
    previewCalendarInvite: () => void;
    calendarBulletsType: CalendarBulletsType;
    setCalendarBulletsType: (value: CalendarBulletsType) => void;
    customBulletsList: string[];
    setCustomBulletsList: (value: string[]) => void;
    setSendCalendarInvites: (value: EventSendSettings) => void;
    sendCalendarInvites: EventSendSettings;
    preCalendarInviteText: string;
    setPreCalendarInviteText: (value: string) => void;
    defaultCalendarBullets: string[];
}) => {
    return (
        <TabPanel>
            <Grid templateColumns="2fr 2fr 2fr">
                <GridItem h="50px">
                    <Text fontWeight="semibold" fontSize="sm">
                        Enabled
                    </Text>
                </GridItem>
                <GridItem h="50px">
                    <Switch
                        isChecked={
                            sendCalendarInvites === EventSendSettings.SEND
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSendCalendarInvites(EventSendSettings.SEND);
                            } else {
                                setSendCalendarInvites(
                                    EventSendSettings.DO_NOT_SEND,
                                );
                            }
                        }}
                    ></Switch>
                </GridItem>
                <GridItem
                    hidden={
                        sendCalendarInvites === EventSendSettings.DO_NOT_SEND
                    }
                >
                    <Tooltip
                        label={unsavedChanges ? "Save changes to preview" : ""}
                    >
                        <Button
                            float="right"
                            marginBottom="20px"
                            isDisabled={unsavedChanges}
                            onClick={previewCalendarInvite}
                        >
                            Send me a preview
                        </Button>
                    </Tooltip>
                </GridItem>
            </Grid>
            {sendCalendarInvites !== EventSendSettings.DO_NOT_SEND && (
                <Grid templateColumns="1fr 2fr">
                    <GridItem h="100px">
                        <Text fontWeight="semibold" fontSize="sm">
                            Custom Message
                        </Text>
                        <Text fontWeight="medium" fontSize="xs" color="gray">
                            (Enter custom text at the start of any of the
                            invite)
                        </Text>
                    </GridItem>
                    <GridItem h="100px">
                        <Textarea
                            value={preCalendarInviteText}
                            placeholder={CUSTOM_PARAGRAPH_HINT}
                            onChange={(e) =>
                                setPreCalendarInviteText(e.target.value)
                            }
                        ></Textarea>
                    </GridItem>
                </Grid>
            )}
            {sendCalendarInvites !== EventSendSettings.DO_NOT_SEND && (
                <Grid templateColumns="1fr 2fr">
                    <GridItem>
                        <Text fontWeight="semibold" fontSize="sm">
                            Custom Bullets
                        </Text>
                    </GridItem>
                    <GridItem>
                        <RadioGroup
                            onChange={setCalendarBulletsType}
                            value={calendarBulletsType}
                        >
                            <Stack direction="row">
                                <Radio value={CalendarBulletsType.DEFAULT}>
                                    Default Bullets
                                </Radio>
                                <Radio
                                    value={CalendarBulletsType.CUSTOM_BULLETS}
                                >
                                    Custom Bullets
                                </Radio>
                            </Stack>
                        </RadioGroup>
                        {calendarBulletsType ===
                        CalendarBulletsType.CUSTOM_BULLETS ? (
                            <CustomBullets
                                customBulletsList={customBulletsList}
                                setCustomBulletsList={setCustomBulletsList}
                            />
                        ) : (
                            <Flex flexDir="column">
                                <Textarea isDisabled h="90px">
                                    {defaultCalendarBullets?.join("\n")}
                                </Textarea>
                            </Flex>
                        )}
                    </GridItem>
                </Grid>
            )}
        </TabPanel>
    );
};
