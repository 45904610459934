import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    ListItem,
    Modal,
    ModalBody,
    ModalContent,
    ModalOverlay,
    UnorderedList,
} from "@chakra-ui/react";
import Downshift from "downshift";
import React, { useEffect, useState } from "react";
import { AdminRouterOutput } from "server";
import { trpc } from "../../hooks/useTRPC";
import Loading from "./Loading";

type Account = AdminRouterOutput["account"]["getAccounts"][0];

export const AccountSelectModal: React.FC<{
    userId: string | null;
    onCancel: () => void;
    onCommit: (account: { id: string; name: string }) => void;
}> = ({ userId, onCancel, onCommit }) => {
    const [selectedAccount, setSelectedAccount] = useState<Account | null>(
        null,
    );
    useEffect(() => {
        if (userId) {
            setSelectedAccount(null);
        }
    }, [userId]);
    const { data: accounts, isLoading: dataLoading } =
        trpc.account.getAccounts.useQuery();

    const isLoading = dataLoading;
    return (
        <Modal isOpen={userId !== null} onClose={onCancel}>
            <ModalOverlay></ModalOverlay>
            <ModalContent>
                <ModalBody p={"40px"}>
                    {isLoading && <Loading />}
                    <form
                        onSubmit={async (e) => {
                            e.preventDefault();
                            if (selectedAccount) {
                                onCommit(selectedAccount);
                            }
                        }}
                    >
                        <Flex direction={"column"}>
                            <FormControl display={isLoading ? "none" : "auto"}>
                                <FormLabel>Select an account</FormLabel>
                                <Downshift
                                    initialInputValue=""
                                    selectedItem={selectedAccount}
                                    itemToString={(_) => (_ ? _.name : "")}
                                    onChange={(selected, state) => {
                                        setSelectedAccount(selected);
                                        state.closeMenu();
                                    }}
                                >
                                    {({
                                        getInputProps,
                                        getRootProps,
                                        getItemProps,
                                        openMenu,
                                        isOpen,
                                        inputValue,
                                        highlightedIndex,
                                    }) => {
                                        const items = (accounts || []).filter(
                                            (_) => {
                                                if (
                                                    !inputValue ||
                                                    inputValue === ""
                                                ) {
                                                    return true;
                                                }
                                                return _.name
                                                    .toLowerCase()
                                                    .trim()
                                                    .includes(
                                                        inputValue
                                                            .toLowerCase()
                                                            .trim(),
                                                    );
                                            },
                                        );
                                        return (
                                            <Flex {...getRootProps()}>
                                                <InputGroup>
                                                    <Input
                                                        {...getInputProps()}
                                                        onFocus={() =>
                                                            openMenu()
                                                        }
                                                    />
                                                </InputGroup>
                                                {isOpen && (
                                                    <UnorderedList
                                                        position={"absolute"}
                                                        maxH={96}
                                                        overflow={"auto"}
                                                        zIndex={50}
                                                        fontSize={"sm"}
                                                        fontWeight={"medium"}
                                                        borderRadius={"md"}
                                                        bg={"white"}
                                                        border={"1px solid"}
                                                        borderColor={"gray.100"}
                                                        mr={2}
                                                        ml={0}
                                                        mt={"46px"}
                                                        minHeight={"120px"}
                                                        minW={"360px"}
                                                    >
                                                        {items.map(
                                                            (
                                                                account,
                                                                index,
                                                            ) => (
                                                                <ListItem
                                                                    {...getItemProps(
                                                                        {
                                                                            key: account.id,
                                                                            item: account,
                                                                            index,
                                                                        },
                                                                    )}
                                                                    key={
                                                                        account.id
                                                                    }
                                                                    ml={0}
                                                                    textColor={
                                                                        "dark.800"
                                                                    }
                                                                    fontWeight={
                                                                        "light"
                                                                    }
                                                                    cursor={
                                                                        "pointer"
                                                                    }
                                                                    py={2}
                                                                    pl={4}
                                                                    fontSize={
                                                                        "sm"
                                                                    }
                                                                    bg={
                                                                        highlightedIndex ===
                                                                        index
                                                                            ? "gray.50"
                                                                            : "white"
                                                                    }
                                                                >
                                                                    {
                                                                        account.name
                                                                    }
                                                                </ListItem>
                                                            ),
                                                        )}
                                                    </UnorderedList>
                                                )}
                                            </Flex>
                                        );
                                    }}
                                </Downshift>
                            </FormControl>
                            <Flex
                                direction={"row"}
                                mt={6}
                                justifyContent={"flex-end"}
                                gap={4}
                            >
                                <Button
                                    onClick={() => onCancel()}
                                    isDisabled={isLoading}
                                >
                                    Cancel
                                </Button>
                                <Button isDisabled={isLoading} type="submit">
                                    Submit
                                </Button>
                            </Flex>
                        </Flex>
                    </form>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};
