import { Box, Flex, Modal, ModalOverlay } from "@chakra-ui/react";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import React from "react";

export const FullScreenLoading: React.FC<{
    isOpen: boolean;
}> = ({ isOpen }) => (
    <Modal
        isOpen={isOpen}
        onClose={() => {}}
        closeOnEsc={false}
        closeOnOverlayClick={false}
    >
        <ModalOverlay />
        <Box pos={"fixed"} top={0} left={0} w={"100%"}>
            <Flex
                direction={"column"}
                alignItems={"center"}
                justifyContent={"center"}
                height={"100vh"}
                mx={"auto"}
            >
                <ArrowPathIcon className="w-6 h-6 animate-spin text-slate-800" />
            </Flex>
        </Box>
    </Modal>
);
