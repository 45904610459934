import classNames from "classnames";
import Downshift from "downshift";
import React from "react";
import { AdminUserItem } from "./AdminUserItem";
import { AdminUser, adminUserToString } from "./utils";

export const AdminUserSelect: React.FC<{
    users: AdminUser[];
    selectedItem: AdminUser | null;
    onChange?: (user: AdminUser | null) => void;
}> = ({ users, onChange, selectedItem }) => {
    const userList = (_input: string | null) => {
        const input = (_input || "").trim().toLowerCase();
        if (input === "") {
            return users;
        }
        return users.filter((adminUser) => {
            const str = adminUserToString(adminUser).toLowerCase().trim();
            return str.includes(input);
        });
    };
    return (
        <Downshift
            selectedItem={selectedItem}
            onChange={onChange}
            itemToString={(item: AdminUser | null) => {
                if (!item) {
                    return "";
                }
                return adminUserToString(item);
            }}
        >
            {({
                getInputProps,
                getItemProps,
                highlightedIndex,
                inputValue,
                isOpen,
                openMenu,
            }) => {
                return (
                    <div className="flex flex-row items-end">
                        <div>
                            <input
                                {...getInputProps()}
                                className={classNames(
                                    "border border-gray-100 rounded-md",
                                    "h-8",
                                )}
                                onFocus={() => {
                                    openMenu();
                                }}
                            />
                            {isOpen ? (
                                <ul
                                    className={classNames(
                                        "absolute max-h-96 overflow-auto w-1/2 z-50",
                                        "text-sm font-medium rounded-md mr-2",
                                        "bg-white",
                                        "border-gray-100 border",
                                        "mt-3",
                                        "w-80",
                                    )}
                                >
                                    {userList(inputValue).map((item, index) => {
                                        const { user } = item;
                                        return (
                                            <li
                                                {...getItemProps({
                                                    key: user.id,
                                                    item: item,
                                                    index,
                                                })}
                                                className={classNames(
                                                    highlightedIndex === index
                                                        ? "bg-gray-50"
                                                        : "bg-white",
                                                    "cursor-pointer",
                                                    "py-2 pl-4",
                                                )}
                                            >
                                                <AdminUserItem
                                                    inline={true}
                                                    email={
                                                        user.emails[0]?.email
                                                    }
                                                    firstName={user.firstName}
                                                    lastName={user.lastName}
                                                />
                                            </li>
                                        );
                                    })}
                                </ul>
                            ) : null}
                        </div>
                    </div>
                );
            }}
        </Downshift>
    );
};
