import { useTRPC } from "./useTRPC";
import { useInsights } from "../App/Insights/InsightsContext";
import { AdminRouterOutput } from "server/src/trpc";
import { useActiveCohorts } from "./useActiveCohorts";

type InsightCohort = NonNullable<
    AdminRouterOutput["insights"]["getCohortsForInsights"][0]
>;

export const useInsightsCohorts = (): Array<{
    item: InsightCohort;
    key: string;
    disabled?: boolean;
}> | null => {
    const trpc = useTRPC();
    const { filterConfig } = useInsights();
    const allCohorts: InsightCohort[] | null = useActiveCohorts();
    const { data: cohorts } = trpc.insights.getCohortsForInsights.useQuery(
        {
            ...filterConfig,
            cohortId: undefined,
        },
        {
            enabled: !!filterConfig.immersionId,
        },
    );
    if (!cohorts) {
        return null;
    }
    const insightCohorts = cohorts
        .sort((a, b) => a.name.localeCompare(b.name))
        // @ts-ignore
        .map((_: InsightCohort) => ({
            item: _,
            key: _.id,
            disabled: false,
        }));
    for (let c of (allCohorts || []).sort((a, b) =>
        a.name.localeCompare(b.name),
    )) {
        if (!insightCohorts.some((_) => _.item.id === c.id)) {
            insightCohorts.push({
                item: c,
                key: c.id,
                disabled: true,
            });
        }
    }
    return insightCohorts;
};
