import Toggle from "../common/Toggle";
import Tooltip from "./Tooltip";

interface Props {
    disabled: boolean;
    flags: {
        featureFlag: {
            id: string;
            name: string;
        };
        enabled: boolean;
    }[];
    allFlags: {
        id: string;
        name: string;
        enabled: boolean;
    }[];
    onChange: (id: string, enabled: boolean) => void;
}

const Flags = (props: Props) => (
    <>
        <div>
            <h4 className="text-sm font-medium text-gray-700 flex">
                Flags
                <Tooltip text="These are flags set on this account, user, or session." />
            </h4>
            <div className="mt-3">
                {props.allFlags &&
                    props.allFlags.map((flag) => {
                        const flagOverride = props.flags.find(
                            (f) => f.featureFlag.id === flag.id,
                        );
                        if (flagOverride) {
                            return (
                                <div
                                    className="flex mt-2"
                                    key={flagOverride.featureFlag.id}
                                >
                                    <Toggle
                                        disabled={props.disabled}
                                        onChange={(enabled: boolean) => {
                                            props.onChange(
                                                flagOverride.featureFlag.id,
                                                enabled,
                                            );
                                        }}
                                        label={flagOverride.featureFlag.name}
                                        value={flagOverride.enabled}
                                    />
                                    {flagOverride.featureFlag.name ===
                                        "Customer" && (
                                        <Tooltip text="Internally marks this account as a member/customer/client if on, and trial/pilot if off." />
                                    )}
                                    {flagOverride.featureFlag.name ===
                                        "Live onboarding" && (
                                        <Tooltip
                                            warning={true}
                                            text="Calendar invites will be sent again. Emails will be updated if they have not been sent already."
                                        />
                                    )}
                                </div>
                            );
                        } else {
                            return (
                                <div className="flex mt-2" key={flag.id}>
                                    <Toggle
                                        disabled={props.disabled}
                                        onChange={(value: boolean) => {
                                            props.onChange(flag.id, value);
                                        }}
                                        label={flag.name}
                                        value={flag.enabled}
                                    />
                                    {flag.name === "Customer" && (
                                        <Tooltip text="Internally marks this account as a member/customer/client if on, and trial/pilot if off." />
                                    )}
                                    {flag.name === "Live onboarding" && (
                                        <Tooltip
                                            warning={true}
                                            text="Calendar invites will be sent again. Emails will be updated if they have not been sent already."
                                        />
                                    )}
                                </div>
                            );
                        }
                    })}
            </div>
        </div>
    </>
);
export default Flags;
