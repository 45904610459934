import { Tooltip as ChakraTooltip, Flex, Text } from "@chakra-ui/react";
import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { BillingType } from "@prisma/client";
import { DateTime } from "luxon";
import { useMemo, useState } from "react";
import Button from "../components/common/Button";
import ErrorModal from "../components/common/ErrorModal";
import Table from "../components/common/Table";
import Toggle from "../components/common/Toggle";
import Tooltip from "../components/factories/Tooltip";
import UserSelect from "../components/factories/UserSelect";
import { useGlobalContext } from "../context";
import { getSparkwiseUrl } from "../env";
import useShowHide from "../hooks/useShowHide";
import { useIsSuperAdmin, useUserHasPermission } from "../hooks/useUserData";
import {
    displayEvent,
    matchingEventForType,
    matchingEventsByGroup,
    matchingEventsForType,
} from "../utils";
import { SessionState } from "./Session";
import { SessionParticipantsCohort } from "./SessionParticipantsCohort";

interface SessionParticipantsProps {
    onUserChange: (user: any) => void;
    onUserSave: (silently?: boolean) => void;
    onSessionParticipantManyChange: (e: any) => void;
    onAddParticipants: (silently?: boolean) => void;
    generateDialInCode: (sessionParticipantId: string) => void;
    removeSessionParticipant: (
        sessionParticipantId: string,
        silently?: boolean,
    ) => void;
    sendSessionParticipantTrialCodes: (sessionParticipantId: string) => void;
    updateBillingType: (isFree: boolean, sessionParticipantId: string) => void;
    refetch: () => void;
    onSetCohortId: (cohortId: string) => void;
}

const SessionParticipants = (
    props: SessionState & SessionParticipantsProps,
) => {
    const { setHidden, ShowHideButton } = useShowHide();
    const hasCohortsPermissions = useUserHasPermission("cohorts");
    const { timezoneName } = useGlobalContext();
    const isSuperAdmin = useIsSuperAdmin();

    const [modalError, setModalError] = useState("");
    const [errorIndex, setErrorIndex] = useState(1);

    const {
        onAddParticipants,
        onSessionParticipantManyChange,
        sessionParticipantMany,
        pending,
    } = props;

    const AddManyParticipants = useMemo(
        () => (
            <div
                className="mt-4"
                style={{
                    width: "400px",
                }}
            >
                <div className="flex">
                    <label
                        htmlFor="sessionParticipantMany"
                        className="block text-sm font-medium text-gray-700"
                    >
                        Add multiple participants by email
                    </label>
                    <Tooltip
                        text={`Enter each email on a new line, or use a comma-separated list.`}
                    />
                </div>
                <div className="mt-1">
                    <textarea
                        rows={4}
                        name="sessionParticipantMany"
                        id="sessionParticipantMany"
                        className="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                        onChange={onSessionParticipantManyChange}
                        value={sessionParticipantMany || ""}
                    ></textarea>
                </div>
                <div className="flex justify-end gap-2 mt-2">
                    <Button
                        secondary
                        disabled={pending}
                        onClick={setHidden}
                        label="Cancel"
                    />
                    <Button
                        disabled={pending || !sessionParticipantMany}
                        onClick={() => {
                            onAddParticipants();
                            setHidden();
                        }}
                        label={"Add Participants"}
                    />
                    <Button
                        disabled={pending || !sessionParticipantMany}
                        onClick={() => {
                            onAddParticipants(true);
                            setHidden();
                        }}
                        label={"Add Participants Silently"}
                    />
                </div>
            </div>
        ),
        [
            onSessionParticipantManyChange,
            sessionParticipantMany,
            pending,
            onAddParticipants,
            setHidden,
        ],
    );

    if (!props.session) return <></>;

    const columns = isSuperAdmin
        ? [
              ["name", "Name"],
              ["id", "Session Participant ID"],
              ["calendar", "📆"],
              ["onboardingEmail", "O 📬"],
              ["reminderEmail", "R 📬"],
              ["linksEmail", "L 📬"],
              ["followUpEmail", "F 📬"],
              ["resources", "📖"],
              ["firstVisitGroup", "Visited"],
              ["remove", "Remove"],
              ["removeSilently", "Remove Silently"],
              ["billingType", "Billing Type"],
          ]
        : [
              ["name", "Name"],
              ["firstVisitGroup", "Visited"],
              ["remove", "Remove"],
          ];
    if (isSuperAdmin && props.session?.groupingStrategy !== "DYNAMIC") {
        columns.push(["sendTrialCodes", "Send Trial Codes"]);
    }

    const rows = [];

    for (let group of [null, ...props.session.timeslotGroups]) {
        let groupParticipants = [];
        if (!group) {
            groupParticipants = props.session.participants.filter(
                (p) => p.groupId === null,
            );
            if (groupParticipants.length) {
                rows.push({
                    name: <Text fontWeight="bold">{`Absentees`}</Text>,
                });
            }
        } else {
            groupParticipants = props.session.participants.filter(
                (p) => p.groupId === group!.id,
            );
            const sessionUrl = `${getSparkwiseUrl()}/immersion/${props.session.id}/group/${group!.id}/0`;
            if (isSuperAdmin || groupParticipants.length > 0) {
                rows.push({
                    name: (
                        <Text fontWeight="bold">
                            {`Group `}
                            {isSuperAdmin && groupParticipants.length > 0 && (
                                <a
                                    href={sessionUrl}
                                    className="text-blue-600 "
                                    target={"_blank"}
                                    rel="noreferrer"
                                >
                                    <ArrowTopRightOnSquareIcon className="inline w-4 h-4 align-top" />
                                </a>
                            )}
                        </Text>
                    ),
                    id: group.id,
                    resources: matchingEventsByGroup(
                        props.session!,
                        group.id,
                        "CLONE_RESOURCES",
                    )
                        .sort((a, b) =>
                            (a.scheduledOn || a.createdOn) <
                            (b.scheduledOn || b.createdOn)
                                ? -1
                                : 1,
                        )
                        .map((event, index) =>
                            displayEvent(event, timezoneName, false, index),
                        ),
                });
            }
        }
        for (let p of groupParticipants) {
            rows.push({
                name: {
                    value:
                        p.user.firstName || p.user.lastName
                            ? `   ${p.user.firstName} ${p.user.lastName}`
                            : p.user.emails
                                  .map((email: any) => email.email)
                                  .join(", "),
                    link: `/user/${p.user.id}`,
                },
                id: <Text>{p.id}</Text>,
                firstVisitGroup: (
                    <Flex flexDir="column" w="">
                        {isSuperAdmin && p.firstVisitTimeslot && (
                            <ChakraTooltip label="First Visit to Timeslot">
                                <Text fontSize="12">
                                    {DateTime.fromJSDate(
                                        new Date(
                                            p.firstVisitTimeslot?.toString(),
                                        ),
                                    )
                                        .setZone(timezoneName)
                                        .toLocaleString(
                                            DateTime.DATETIME_SHORT,
                                        )}
                                </Text>
                            </ChakraTooltip>
                        )}
                        {p.firstVisitGroup && (
                            <ChakraTooltip label="First Visit to Group">
                                <Text fontSize="12">
                                    {DateTime.fromJSDate(
                                        new Date(p.firstVisitGroup?.toString()),
                                    )
                                        .setZone(timezoneName)
                                        .toLocaleString(
                                            DateTime.DATETIME_SHORT,
                                        )}
                                </Text>
                            </ChakraTooltip>
                        )}
                    </Flex>
                ),
                calendar: displayEvent(
                    matchingEventForType(props.session!, p, "CALENDAR_INVITE"),
                    timezoneName,
                    !p.sendCalInvites,
                ),
                onboardingEmail: displayEvent(
                    matchingEventForType(
                        props.session!,
                        p,
                        "EMAIL",
                        "onboarding",
                    ),
                    timezoneName,
                    !p.sendOnboardingEmails,
                ),
                reminderEmail: displayEvent(
                    matchingEventForType(
                        props.session!,
                        p,
                        "EMAIL",
                        "reminder",
                    ),
                    timezoneName,
                    !p.sendReminderEmails,
                ),
                linksEmail: displayEvent(
                    matchingEventForType(props.session!, p, "EMAIL", "links"),
                    timezoneName,
                    !p.sendLinkEmails,
                ),
                followUpEmail: displayEvent(
                    matchingEventForType(
                        props.session!,
                        p,
                        "EMAIL",
                        "follow-up",
                    ),
                    timezoneName,
                    !p.sendFollowupEmails,
                ),
                resources: matchingEventsForType(
                    props.session!,
                    p,
                    "CLONE_RESOURCES",
                )
                    .sort((a, b) =>
                        (a.scheduledOn || a.createdOn) <
                        (b.scheduledOn || b.createdOn)
                            ? -1
                            : 1,
                    )
                    .map((event, index) =>
                        displayEvent(event, timezoneName, false, index),
                    ),
                remove: p.firstVisitTimeslot ? null : (
                    <div className="flex">
                        <Button
                            onClick={() => {
                                props.removeSessionParticipant(p.id);
                            }}
                            disabled={props.pending}
                            subtle={true}
                            label="Remove"
                            requiresConfirmation
                        />
                        <Tooltip
                            warning={true}
                            text="Calendar events will be cancelled. Scheduled emails for a removed participant will be canceled, and emails for other participants will be updated if they have not been sent already."
                        />
                    </div>
                ),
                removeSilently: p.firstVisitTimeslot ? null : (
                    <div className="flex">
                        <Button
                            onClick={() => {
                                props.removeSessionParticipant(p.id, true);
                            }}
                            disabled={props.pending}
                            subtle={true}
                            label="Remove Silently"
                            requiresConfirmation
                        />
                        <Tooltip
                            warning={true}
                            text="Scheduled events and emails for removed participant will be delivered as expected."
                        />
                    </div>
                ),
                sendTrialCodes: (
                    <Button
                        onClick={() => {
                            props.sendSessionParticipantTrialCodes(p.id);
                        }}
                        disabled={props.pending || p.receivedTrialCodes}
                        label="Send"
                        requiresConfirmation
                    ></Button>
                ),
                billingType: (
                    <Toggle
                        disabled={props.pending}
                        onChange={(isFree: boolean) => {
                            props.updateBillingType(isFree, p.id);
                        }}
                        value={p.billingType === BillingType.FREE}
                        label="Free"
                    ></Toggle>
                ),
            });
        }
    }
    return (
        <div className="mt-12">
            <ErrorModal error={modalError} key={errorIndex} />
            {hasCohortsPermissions && (
                <SessionParticipantsCohort
                    session={props.session}
                    onSetCohortId={props.onSetCohortId}
                />
            )}
            <UserSelect
                selectedUserId={props.participantId}
                pending={props.pending}
                saved={props.saved.participant}
                cohortName={props.session?.cohort?.name}
                users={props.users}
                onChange={props.onUserChange}
                onSave={props.onUserSave}
                attendedTags={true}
                additionalControls={
                    isSuperAdmin && (
                        <Button
                            label="Add silently"
                            secondary
                            disabled={props.pending}
                            onClick={() => props.onUserSave(true)}
                        />
                    )
                }
            />
            {isSuperAdmin && (
                <div className="mt-4">
                    <ShowHideButton
                        label="Add multiple participants by email"
                        component={AddManyParticipants}
                    />
                </div>
            )}
            <Table
                // @ts-ignore - bug in type parsing.
                columns={columns}
                data={{
                    1: rows,
                    2: [],
                }}
                hidePages={true}
                onPageChange={() => {}}
                page={1}
                sortable={false}
            />
        </div>
    );
};

export default SessionParticipants;
