import {
    ExclamationCircleIcon,
    ArrowPathIcon,
    CheckCircleIcon,
} from "@heroicons/react/24/outline";

const Loading = ({
    error,
    warning,
    success,
}: {
    error?: string;
    warning?: string;
    success?: string;
}) =>
    error ? (
        <div className="bg-red-50 border-l-4 border-red-400 p-4 mt-3">
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationCircleIcon
                        className="h-5 w-5 text-red-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <p className="text-sm text-red-700">{error}</p>
                </div>
            </div>
        </div>
    ) : warning ? (
        <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 mt-3">
            <div className="flex">
                <div className="flex-shrink-0">
                    <ExclamationCircleIcon
                        className="h-5 w-5 text-yellow-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <p className="text-sm text-yellow-700">{warning}</p>
                </div>
            </div>
        </div>
    ) : success ? (
        <div className="bg-green-50 border-l-4 border-green-400 p-4 mt-3">
            <div className="flex">
                <div className="flex-shrink-0">
                    <CheckCircleIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <p className="text-sm text-green-700">{success}</p>
                </div>
            </div>
        </div>
    ) : (
        <div className="bg-green-50 border-l-4 border-green-400 p-4 mt-3">
            <div className="flex">
                <div className="flex-shrink-0">
                    <ArrowPathIcon
                        className="h-5 w-5 text-green-400"
                        aria-hidden="true"
                    />
                </div>
                <div className="ml-3">
                    <p className="text-sm text-green-700">Loading ...</p>
                </div>
            </div>
        </div>
    );

export default Loading;
