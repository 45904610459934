import { Flex, Select, Text } from "@chakra-ui/react";
import { useTRPC } from "../../hooks/useTRPC";
import { EventSendSettings } from "@prisma/client";
import { useState } from "react";
import ErrorModal from "../../components/common/ErrorModal";
import Tooltip from "../../components/factories/Tooltip";

export const CohortSettings = ({ cohortId }: { cohortId: string }) => {
    const trpc = useTRPC();
    const { data: cohort, refetch } = trpc.cohorts.getCohort.useQuery(
        {
            cohortId: cohortId || "",
        },
        { enabled: !!cohortId },
    );

    const [error, setError] = useState("");
    const [errorIndex, setErrorIndex] = useState(1);

    const { mutateAsync: setEventSettings } =
        trpc.cohorts.setEventSettings.useMutation();

    if (!cohort || !cohortId) {
        return <div>Not found.</div>;
    }

    return (
        <Flex flexDir="column" w="250px">
            <Flex flexDir="row" alignItems="flex-end">
                <h4 className="text-base text-gray-500">Event Settings</h4>
                <Tooltip text="Overrides org-level setting. Immediately applies to all existing events/session participants associated with the cohort." />
            </Flex>
            <ErrorModal error={error} key={errorIndex} />
            <Flex flexDir="column" ml="15px">
                <Text mt="10px">Send Onboarding Emails</Text>
                <Select
                    value={cohort.sendOnboardingEmails}
                    onChange={async (e) => {
                        try {
                            await setEventSettings({
                                cohortId: cohort.id,
                                fields: {
                                    sendOnboardingEmails: e.target.value as
                                        | EventSendSettings
                                        | undefined,
                                },
                            });
                            refetch();
                        } catch (error) {
                            setError((error as any).message);
                            setErrorIndex(errorIndex + 1);
                        }
                    }}
                >
                    <option value={EventSendSettings.INHERIT}>
                    Inherit Org Setting
                    </option>
                    <option
                        value={EventSendSettings.SEND}
                        key={EventSendSettings.SEND}
                    >
                        Send
                    </option>
                    <option
                        value={EventSendSettings.DO_NOT_SEND}
                        key={EventSendSettings.DO_NOT_SEND}
                    >
                        Don't Send
                    </option>
                </Select>
                <Text mt="10px">Send Reminder Emails</Text>
                <Select
                    value={cohort.sendReminderEmails}
                    onChange={async (e) => {
                        try {
                            await setEventSettings({
                                cohortId: cohort.id,
                                fields: {
                                    sendReminderEmails: e.target.value as
                                        | EventSendSettings
                                        | undefined,
                                },
                            });
                            refetch();
                        } catch (error) {
                            setError((error as any).message);
                            setErrorIndex(errorIndex + 1);
                        }
                    }}
                >
                    <option value={EventSendSettings.INHERIT}>
                    Inherit Org Setting
                    </option>
                    <option
                        value={EventSendSettings.SEND}
                        key={EventSendSettings.SEND}
                    >
                        Send
                    </option>
                    <option
                        value={EventSendSettings.DO_NOT_SEND}
                        key={EventSendSettings.DO_NOT_SEND}
                    >
                        Don't Send
                    </option>
                </Select>
                <Text mt="10px">Send Link Emails</Text>
                <Select
                    value={cohort.sendLinkEmails}
                    onChange={async (e) => {
                        try {
                            await setEventSettings({
                                cohortId: cohort.id,
                                fields: {
                                    sendLinkEmails: e.target.value as
                                        | EventSendSettings
                                        | undefined,
                                },
                            });
                            refetch();
                        } catch (error) {
                            setError((error as any).message);
                            setErrorIndex(errorIndex + 1);
                        }
                    }}
                >
                    <option value={EventSendSettings.INHERIT}>
                    Inherit Org Setting
                    </option>
                    <option
                        value={EventSendSettings.SEND}
                        key={EventSendSettings.SEND}
                    >
                        Send
                    </option>
                    <option
                        value={EventSendSettings.DO_NOT_SEND}
                        key={EventSendSettings.DO_NOT_SEND}
                    >
                        Don't Send
                    </option>
                </Select>
                <Text mt="10px">Send Followup Emails</Text>
                <Select
                    value={cohort.sendFollowupEmails}
                    onChange={async (e) => {
                        try {
                            await setEventSettings({
                                cohortId: cohort.id,
                                fields: {
                                    sendFollowupEmails: e.target.value as
                                        | EventSendSettings
                                        | undefined,
                                },
                            });
                            refetch();
                        } catch (error) {
                            setError((error as any).message);
                            setErrorIndex(errorIndex + 1);
                        }
                    }}
                >
                    <option value={EventSendSettings.INHERIT}>
                    Inherit Org Setting
                    </option>
                    <option
                        value={EventSendSettings.SEND}
                        key={EventSendSettings.SEND}
                    >
                        Send
                    </option>
                    <option
                        value={EventSendSettings.DO_NOT_SEND}
                        key={EventSendSettings.DO_NOT_SEND}
                    >
                        Don't Send
                    </option>
                </Select>
                <Text mt="10px">Send Calendar Invites</Text>
                <Select
                    value={cohort.sendCalInvites}
                    onChange={async (e) => {
                        try {
                            await setEventSettings({
                                cohortId: cohort.id,
                                fields: {
                                    sendCalInvites: e.target.value as
                                        | EventSendSettings
                                        | undefined,
                                },
                            });
                            refetch();
                        } catch (error) {
                            setError((error as any).message);
                            setErrorIndex(errorIndex + 1);
                        }
                    }}
                >
                    <option value={EventSendSettings.INHERIT}>
                        Inherit Org Setting
                    </option>
                    <option
                        value={EventSendSettings.SEND}
                        key={EventSendSettings.SEND}
                    >
                        Send
                    </option>
                    <option
                        value={EventSendSettings.DO_NOT_SEND}
                        key={EventSendSettings.DO_NOT_SEND}
                    >
                        Don't Send
                    </option>
                </Select>
            </Flex>
        </Flex>
    );
};
