import { Box, Flex, Image, Text, Tooltip } from "@chakra-ui/react";
import React from "react";
import { InsightsParticipant } from "./InsightsContext";
import { mapColorNameToHex, PARTICIPANT_BACKGROUNDS } from "../../utils";

export const Participants: React.FC<{
    participants: InsightsParticipant[];
    groupType?: "shared" | "individual";
    /**
     * If set to true, avatars will be colored based on the participant's index
     * and participant color array
     */
    colored?: boolean;
}> = ({ participants, groupType, colored }) => {
    const isSingle = participants.length === 1;
    const sorted = participants
        .sort((a, b) => a.id.localeCompare(b.id))
        .map((p, index) => ({
            ...p,
            color: colored
                ? mapColorNameToHex(PARTICIPANT_BACKGROUNDS[index])
                : undefined,
        }));
    return (
        <Flex grow={1} justifyContent={["flex-start", "flex-end"]}>
            {isSingle && groupType === "individual" ? (
                <ParticipantCircle
                    participant={sorted[0]}
                    isFirst={true}
                    isSingle={true}
                />
            ) : null}
            {!isSingle &&
                sorted.map((_, index) => (
                    <ParticipantCircle
                        key={_.id} 
                        participant={_}
                        isFirst={index === 0}
                        isSingle={false}
                        color={_.color}
                    />
                ))}
        </Flex>
    );
};

const ParticipantCircle: React.FC<{
    participant: InsightsParticipant;
    isFirst: boolean;
    isSingle: boolean;
    color?: string;
}> = ({ participant, isFirst, isSingle, color }) => {
    return (
        <Flex alignItems="center">
            <Tooltip
                label={!isSingle ? participant.name : null}
                aria-label="Participant name"
                hasArrow
            >
                <Box
                    borderStyle={"solid"}
                    borderWidth={color ? "2px" : "1px"}
                    borderColor={color ? color : "gray.600"}
                    boxSize={"24px"}
                    boxShadow={"0 0 0 3px white"}
                    borderRadius={"85px"}
                    overflow={"hidden"}
                    ml={isFirst || isSingle ? 0 : "-8px"}
                >
                    {participant.avatarUrl ? (
                        <Image src={participant.avatarUrl} />
                    ) : (
                        <Box bg={color} />
                    )}
                </Box>
            </Tooltip>
            {isSingle ? (
                <Text
                    ml="8px"
                    fontSize="14px"
                    fontWeight={600}
                    color="dark.800"
                >
                    {participant.name}
                </Text>
            ) : null}
        </Flex>
    );
};
