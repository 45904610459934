import { trpc } from "./useTRPC";
import * as Sentry from "@sentry/react";

export const useAccount = () => {
    const accountInfo = trpc.account.getAccountInfo.useQuery();
    if (accountInfo.error) {
        Sentry.captureException(accountInfo.error);
    }
    return accountInfo;
};

export const useChatwootToken = () => {
    const { data, error } = trpc.account.getChatwootToken.useQuery();
    if (error) {
        Sentry.captureException(error);
    }
    return data;
};

export const useIsMaintenanceWindow = () => {
    const { data } = trpc.flags.getAppFlags.useQuery();
    return (
        data?.find((flag) => flag.name === "Maintenance window")?.enabled ??
        false
    );
};
