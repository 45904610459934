import { AdminRouterOutput } from "server";

export const getAdminName = (admin: AdminUser) =>
    getAdminUserName(admin.user.firstName, admin.user.lastName);

export const adminUserToString = (admin: AdminUser) => {
    const email = admin.user.emails[0]?.email;
    return getAdminName(admin) || email;
};

export const getAdminUserName = (
    firstName?: string | null,
    lastName?: string | null,
) => {
    if (firstName && lastName) {
        return `${firstName} ${lastName}`;
    } else if (firstName) {
        return firstName;
    } else if (lastName) {
        return lastName;
    }
    return null;
};

export type AdminUser = AdminRouterOutput["org"]["getOrgUsers"][0];
