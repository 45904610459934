import { useCallback, useEffect, useState } from "react";
import { useGlobalContext } from "../context";
import Button from "../components/common/Button";
import Form from "../components/common/Form";
import Loading from "../components/common/Loading";
import Table from "../components/common/Table";
import ErrorBox from "../components/common/ErrorBox";
import Tooltip from "../components/factories/Tooltip";
import { useTRPC } from "../hooks/useTRPC";

const Domains = () => {
    const context = useGlobalContext();
    const [addingDomain, setAddingDomain] = useState(false);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState("");
    const [name, setName] = useState("");
    const [domains, setDomains] = useState<
        {
            id: string;
            name: string;
            users: number;
        }[]
    >([]);

    const trpc = useTRPC();

    const { data: domainsData, refetch } = trpc.domains.getOrgDomains.useQuery(
        {
            organizationId: context.organizationId,
        },
        { enabled: !!context.organizationId },
    );

    useEffect(() => {
        if (domainsData) {
            setDomains(domainsData as any);
        }
    }, [domainsData]);

    const { mutateAsync: deleteDomainById } =
        trpc.domains.deleteDomainById.useMutation();

    const deleteDomain = useCallback(
        async (id: string) => {
            setPending(true);
            try {
                deleteDomainById({ id });
                setDomains(domains.filter((domain) => domain.id !== id));
            } catch (error) {
                setError((error as any).message);
            }
            setPending(false);
        },
        [deleteDomainById, domains],
    );

    useEffect(() => {
        try {
            for (const domain of domains) {
                // @ts-ignore
                domain.delete = (
                    <Button
                        onClick={() => {
                            deleteDomain(domain.id);
                            // @ts-ignore
                            delete domain.id;
                        }}
                        disabled={domain.users !== 0 || pending}
                        label="Delete"
                    />
                );
            }
        } catch (error) {
            setError((error as any).message);
        }
    }, [deleteDomain, domains, pending]);

    const cancel = () => {
        setAddingDomain(false);
        setError("");
        setName("");
    };

    if (!domains) {
        return <Loading error={error} />;
    }

    const { mutateAsync: addDomainForOrg } =
        trpc.domains.addDomainForOrg.useMutation();

    return (
        <div className="my-12">
            <h4 className="text-base text-gray-500 flex">
                Domains
                <Tooltip text="An email domain (such as sparkwise.co) that should be attached to this account. Email addresses in these domains will not be allowed for users in other accounts." />
            </h4>

            <div className="mt-3">
                {domains.length ? (
                    <Table
                        columns={[
                            ["name", "Name"],
                            ["users", "Users"],
                            ["delete", "Delete"],
                        ]}
                        data={{
                            0: domains,
                            1: [],
                        }}
                        initialSortField={"name"}
                        onPageChange={(page: number) => {}}
                        page={0}
                        sortable={true}
                    />
                ) : (
                    <div className="text-gray-900 text-sm">No domains</div>
                )}
            </div>

            <div className="mt-3">
                {addingDomain ? (
                    <Form
                        cancel={cancel}
                        submit={async () => {
                            setPending(true);
                            setError("");
                            try {
                                await addDomainForOrg({
                                    name: name?.trim(),
                                    organizationId: context.organizationId,
                                });
                                refetch();
                                cancel();
                            } catch (error) {
                                setError((error as any).message);
                            }
                            setPending(false);
                        }}
                        change={(event) => setName(event.currentTarget.value)}
                        disabled={pending}
                        error={error}
                        label="Name"
                        placeholder={"example.com"}
                        value={name}
                    />
                ) : (
                    <Button
                        onClick={() => {
                            cancel();
                            setAddingDomain(true);
                        }}
                        label="Add Domain"
                    />
                )}
            </div>

            <ErrorBox error={error} />
        </div>
    );
};

export default Domains;
