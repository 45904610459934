import classNames from "classnames";
import React from "react";
import Button from "./Button";

interface Props {
    cancel?: () => void;
    submit: () => void;
    change?: (event: React.FormEvent<HTMLInputElement>) => void;
    disabled: boolean;
    error?: string;
    format?: string;
    label?: string;
    placeholder?: string;
    saveLabel?: string;
    value?: string;
    customInput?: React.ReactNode;
    customInputs?: React.ReactNode[];
}

const Form = (props: Props) => (
    <div className="bg-gray-50 rounded-lg w-fit">
        <div className="p-6">
            <form
                className=""
                onSubmit={(event) => {
                    event.preventDefault();
                    props.submit();
                }}
            >
                <div
                    className={classNames(
                        "flex",
                        props.format === "inline"
                            ? "flex-row items-end"
                            : "flex-col items-stretch",
                    )}
                >
                    {props.customInputs
                        ? props.customInputs.map((input, index) => (
                              <div key={index}>{input}</div>
                          ))
                        : props.customInput || (
                              <>
                                  <label
                                      htmlFor="input"
                                      className="block text-sm font-medium text-gray-700 mb-1"
                                  >
                                      {props.label}
                                  </label>
                                  <input
                                      type="text"
                                      name="input"
                                      id="input"
                                      className={classNames(
                                          "text-sm font-medium rounded-md mr-2 focus:outline outline-1 outline-blue-500",
                                          props.disabled ? "bg-gray-100" : "",
                                      )}
                                      size={36}
                                      autoFocus={true}
                                      placeholder={props.placeholder}
                                      value={props.value}
                                      onChange={props.change}
                                      disabled={props.disabled}
                                  />
                              </>
                          )}
                    <div
                        className={classNames(
                            props.format === "inline" ? "ml-6" : "mt-6",
                        )}
                    >
                        {props.cancel && (
                            <>
                                <Button
                                    label="Cancel"
                                    disabled={props.disabled}
                                    onClick={props.cancel}
                                    secondary={true}
                                />
                                <span className="mx-2" />
                            </>
                        )}
                        <Button
                            label={props.saveLabel || "Save"}
                            disabled={props.disabled}
                            onClick={(event) => {
                                event.preventDefault();
                                props.submit();
                            }}
                        />
                    </div>
                </div>
                {props.error && (
                    <div className="text-red-800 text-sm mt-6">
                        {props.error}
                    </div>
                )}
            </form>
        </div>
    </div>
);

export default Form;
