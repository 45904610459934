import { useMemo } from "react";
import { Navigate } from "react-router-dom";
import Button from "../components/common/Button";
import Loading from "../components/common/Loading";
import Table from "../components/common/Table";
import useStateObject from "../hooks/useStateObject";
import { trpc } from "../hooks/useTRPC";
import { Flex, Text } from "@chakra-ui/react";
import { timeParser } from "../utils";
import Tooltip from "../components/factories/Tooltip";

interface State {
    data: {
        [page: string]: any[];
    };
    error: string;
    page: number;
    pending: boolean;
    newSessionId?: string;
    updated: boolean;
}

const Immersions = () => {
    const [state, setState] = useStateObject<State>({
        data: {},
        error: "",
        page: 0,
        pending: false,
        updated: false,
    });

    const { data: _immersions } = trpc.immersions.getImmersions.useQuery();

    const immersions = useMemo(() => {
        return _immersions
            ?.filter((immersion) => immersion.status === "ACTIVE")
            .map((immersion) => ({
                ...immersion,
                authorPreview: (
                    <Button
                        disabled={state.pending}
                        label={"Author Preview"}
                        onClick={async () => {
                            try {
                                setState({
                                    pending: true,
                                });
                                const session = await addImmersion({
                                    authorPreview: immersion.id,
                                });
                                setState({
                                    pending: false,
                                    newSessionId: session.id,
                                });
                            } catch (error) {
                                setState({
                                    error: (error as any).message,
                                    pending: false,
                                });
                            }
                        }}
                    />
                ),
                link: `/immersion/${immersion.id}`,
                regenerateFutureSessions: (
                    <Button
                        disabled={true}
                        label={"Regenerate resources"}
                        onClick={async () => {
                            try {
                                setState({
                                    pending: true,
                                });
                                const session = await addImmersion({
                                    authorPreview: immersion.id,
                                });
                                setState({
                                    pending: false,
                                    newSessionId: session.id,
                                });
                            } catch (error) {
                                setState({
                                    error: (error as any).message,
                                    pending: false,
                                });
                            }
                        }}
                    />
                ),
                status: immersion.status === "ACTIVE" ? "Active" : "Inactive",
                sync: (
                    <Button
                        label="Sync"
                        disabled={state.pending}
                        onClick={async () => {
                            try {
                                setState({
                                    pending: true,
                                });
                                await syncImmersion({
                                    forceSync: true,
                                    immersionId: immersion.id,
                                });
                                setState({
                                    pending: false,
                                });
                            } catch (error) {
                                setState({
                                    error: (error as any).message,
                                    pending: false,
                                });
                            }
                        }}
                    ></Button>
                ),
                lastSync: immersion.lastSyncedOn?.toString(),
                orgs: (
                    <Flex flexDir="column">
                        {immersion.organizations.map((org) => (
                            <Text>{org.name}</Text>
                        ))}
                    </Flex>
                ),
            }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [_immersions, setState, state.pending]);

    const { mutateAsync: syncImmersion } =
        trpc.immersions.syncImmersionFromAirtable.useMutation();
    const { mutateAsync: addImmersion } =
        trpc.immersions.addImmersion.useMutation();
    const { mutateAsync: syncImmersions } =
        trpc.airtable.syncImmersions.useMutation();

    if (state.error || !immersions) {
        return <Loading error={state.error} />;
    }

    if (state.newSessionId) {
        return <Navigate to={`/session/${state.newSessionId}`} />;
    }
    return (
        <div>
            <div className="flex items-center">
                <h4 className="text-lg font-medium text-slate-800">
                    Module templates
                </h4>
                <div className="ml-6">
                    <Button
                        onClick={async () => {
                            try {
                                setState({
                                    pending: true,
                                });
                                // selectively sync ALL immersions (including their steps/resources)
                                await syncImmersions({ forceSync: false });
                                setState({
                                    pending: false,
                                    updated: true,
                                });
                            } catch (error) {
                                setState({
                                    error: (error as any).message,
                                    pending: false,
                                });
                            }
                        }}
                        label={
                            state.updated
                                ? "Updated successfully"
                                : "Smart Sync from Airtable"
                        }
                        secondary={true}
                        disabled={state.pending}
                    />
                </div>
                <Tooltip
                    text={`Smart Sync will only sync modules with recent changes in Airtable since the last Sync.\n\nResources will be re-cloned for any currently accessible sessions affected by the Sync.`}
                />
            </div>
            <Table
                columns={[
                    ["name", "Module Code"],
                    ["tactic", "Module"],
                    ["status", "Status"],
                    ["authorPreview", "Author Preview"],
                    ["sync", "Force-Sync from Airtable"],
                    ["lastSync", "Last Synced At", timeParser],
                    ["orgs", "Organizations"],
                    // [
                    //     "regenerateFutureSessions",
                    //     "Regenerate resources for future sessions",
                    // ],
                ]}
                data={{ 0: immersions }}
                disableLinks={state.pending}
                hidePages={true}
                onPageChange={() => {}}
                page={0}
                initialSortDirection={"asc"}
                initialSortField={"name"}
                activeFunction={(immersion) => immersion.status === "Active"}
                searchable={["name", "tactic"]}
                sortable={true}
            />
        </div>
    );
};

export default Immersions;
