import React, { Fragment, useState } from "react";
import classNames from "classnames";
import { Dialog, Transition } from "@headlessui/react";
import { Button, Flex, Text } from "@chakra-ui/react";
import AdminLogo from "design/assets/AdminLogo.svg";
import { Chatwoot } from "./Chatwoot";
import {
    Bars3Icon,
    CalendarIcon,
    ChartBarIcon,
    Cog6ToothIcon,
    FolderOpenIcon,
    InformationCircleIcon,
    PencilSquareIcon,
    RectangleGroupIcon,
    RectangleStackIcon,
    ShieldCheckIcon,
    UsersIcon,
    VideoCameraIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import { useGlobalContext } from "../context";
import AuditLog from "./AuditLog";
import Immersion from "./Immersion";
import Immersions from "./Immersions";
import Info from "./Info";
import Org from "./Org/Org";
import Orgs from "./Orgs";
import Session from "./Session";
import Sessions from "./Sessions";
import Status from "./Status";
import User from "./User";
import Users from "./Users";
import { ROOT_USERS } from "shared/constants";
import { useFullName, useUserData } from "../hooks/useUserData";
import { useIsMaintenanceWindow } from "../hooks/useAccount";
import Impact from "./Impact";
import Cohorts from "./Cohorts/Cohorts";
import Cohort from "./Cohorts/Cohort";
import { Excalidraw } from "./learningDesign/Excalidraw";
import { Etherpad } from "./learningDesign/Etherpad";
import { NewEtherpad } from "./learningDesign/NewEtherpad";
import { CloudflareStream } from "./learningDesign/CloudflareStream";
import { CloudflareImage } from "./learningDesign/CloudflareImage";
import { DownloadableFile } from "./learningDesign/DownloadableFile";
import LoadingPage from "../components/common/LoadingPage";
import ErrorCard from "../components/common/ErrorCard";
import Customization from "./customization/Customization";
import { getSparkwiseUrl } from "../env";
import { AppVersion } from "../components/common/AppVersion";

interface Props {
    user: { [key: string]: string };
    logout: () => void;
}

const Router = (props: Props) => {
    const context = useGlobalContext();
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const fullName = useFullName();
    const userData = useUserData();
    const isSuperAdmin = userData.data?.isSparkwiseAdmin;
    const isClientAdmin = userData.data?.isClientAdmin;
    const permissions = userData.data?.permissions;
    const hasPermissions =
        permissions?.sessions.value ||
        permissions?.users.value ||
        permissions?.analytics.value ||
        permissions?.cohorts.value ||
        permissions?.communications.value ||
        isSuperAdmin;

    const isMaintenanceWindow = useIsMaintenanceWindow();
    const showMaintenanceWindow =
        isMaintenanceWindow && !context.maintenanceWarningDismissed;

    const logoutFunction = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        props.logout();
    };

    const profile = () => (
        <>
            <div className="flex-shrink-0">
                <img
                    className="inline-block w-10 h-10 rounded-full"
                    src={props.user.picture}
                    alt=""
                />
            </div>
            <div className="ml-3">
                <p className="text-sm font-normal text-dark-800 break-all">
                    {fullName ? fullName : props.user.name}
                </p>
                <p className="text-base">
                    <button
                        className="text-sm font-semibold text-blue-700 hover:text-blue-500"
                        onClick={logoutFunction}
                    >
                        Log out
                    </button>
                </p>
            </div>
        </>
    );

    const timezone = () => (
        <div
            className="w-full p-4 cursor-pointer"
            style={{ userSelect: "none" }}
            onClick={() => {
                if (context.timezoneSource === "Local") {
                    context.setTimezoneSource("Account");
                    context.setTimezoneName(context.organizationTimezone);
                } else if (context.timezoneSource === "Account") {
                    context.setTimezoneSource("UTC");
                    context.setTimezoneName("UTC");
                } else if (context.timezoneSource === "UTC") {
                    context.setTimezoneSource("Local");
                    context.setTimezoneName(
                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                    );
                }
            }}
        >
            <p className="text-sm font-normal text-dark-400">
                Timezone ({context.timezoneSource})
            </p>
            <p className="text-sm font-normal text-dark-700">
                {context.timezoneName || "UTC"}
            </p>
        </div>
    );

    // Template from https://tailwindui.com/components/application-ui/application-shells/sidebar
    const routes = (
        <Routes>
            <Route path="accounts" element={<Orgs />} />
            <Route
                path="account/:organizationId"
                element={<Org params={{}} />}
            />
            <Route
                path="users/:organizationId"
                element={<Users params={{}} />}
            />
            <Route
                path="cohorts/:organizationId"
                element={<Cohorts params={{}}></Cohorts>}
            ></Route>
            <Route
                path="customization/:organizationId"
                element={<Customization params={{}}></Customization>}
            ></Route>
            <Route
                path="cohort/:cohortId"
                element={<Cohort params={{}}></Cohort>}
            ></Route>
            <Route
                path="impact/:organizationId"
                element={<Impact params={{}} />}
            />
            <Route path="user/:userId" element={<User params={{}} />} />
            <Route path="immersions" element={<Immersions />} />
            <Route
                path="immersion/:immersionId"
                element={<Immersion params={{}} />}
            />
            <Route
                path="sessions/:organizationId"
                element={<Sessions params={{}} />}
            />
            <Route
                path="session/:sessionId"
                element={<Session params={{}} />}
            />
            <Route path="status" element={<Status />} />
            <Route path="audit-log" element={<AuditLog params={{}} />} />
            <Route
                path="audit-log/:modelId"
                element={<AuditLog params={{}} />}
            />
            <Route path="info" element={<Info />} />

            <Route path="/" element={<Orgs />} />
            <Route path="/logout" element={<Orgs />} />
            <Route path="excalidraw/:recordId" element={<Excalidraw />} />
            <Route path="etherpad" element={<NewEtherpad />} />
            <Route path="etherpad/:padId" element={<Etherpad />} />
            <Route
                path="cloudflare-stream/:systemId"
                element={<CloudflareStream />}
            />
            <Route
                path="cloudflare-image/:systemId"
                element={<CloudflareImage />}
            />
            <Route
                path="downloadable-file/:systemId"
                element={<DownloadableFile />}
            />
        </Routes>
    );

    const TABS = [
        {
            key: "accounts",
            name: "Accounts",
            link: `/accounts`,
            icon: RectangleStackIcon,
            indent: undefined,
            include: isSuperAdmin,
        },
        {
            key: "modules",
            name: "Modules",
            link: `/immersions`,
            icon: FolderOpenIcon,
            include: isSuperAdmin,
        },
        {
            key: "current-sessions",
            name: "Current Sessions",
            link: "/status",
            icon: VideoCameraIcon,
            spacer: true,
            include: isSuperAdmin,
        },
        {
            key: "organization",
            name: context.organizationName,
            link: undefined,
            icon: undefined,
            include: context.organizationId,
        },
        {
            key: "account",
            name: "Account",
            link: `/account/${context.organizationId}`,
            icon: Cog6ToothIcon,
            include: isSuperAdmin && context.organizationId,
        },
        {
            key: "sessions",
            name: "Sessions",
            link: `/sessions/${context.organizationId}`,
            icon: CalendarIcon,
            indent: true,
            include:
                context.organizationId &&
                (permissions?.sessions.value || isSuperAdmin),
        },
        {
            key: "users",
            name: "Users",
            link: `/users/${context.organizationId}`,
            icon: UsersIcon,
            indent: true,
            include:
                context.organizationId &&
                (permissions?.users.value || isSuperAdmin),
        },
        {
            key: "cohorts",
            name: "Cohorts",
            link: `/cohorts/${context.organizationId}`,
            icon: RectangleGroupIcon,
            indent: true,
            include:
                context.organizationId &&
                (permissions?.cohorts.value || isSuperAdmin),
        },
        {
            key: "communications",
            name: "Communications",
            link: `/customization/${context.organizationId}`,
            icon: PencilSquareIcon,
            indent: true,
            include:
                context.organizationId &&
                (permissions?.communications.value || isSuperAdmin),
        },
        {
            key: "analytics",
            name: "Analytics",
            link: `/impact/${context.organizationId}`,
            icon: ChartBarIcon,
            indent: true,
            spacer: true,
            include:
                context.organizationId &&
                (permissions?.analytics.value || isSuperAdmin),
        },
    ];

    let navigationConfig: any[] = [];

    navigationConfig = navigationConfig.concat(
        TABS.filter((tab) => tab.include),
    );
    if (context.organizationId === "" && !isSuperAdmin) {
        navigationConfig = [];
    }

    const navigation = navigationConfig.map((item) => (
        <div key={item.key}>
            <NavLink
                to={item.link}
                className={({ isActive }) =>
                    classNames(
                        "group flex items-center px-2 py-2",
                        "text-sm font-normal rounded-md",
                        isActive
                            ? "text-blue-600 font-semibold"
                            : "text-dark-800",
                        item.link
                            ? "hover:bg-blue-50 hover:text-blue-600"
                            : "text-dark-800 cursor-default",
                    )
                }
            >
                {item.icon && (
                    <item.icon
                        className={classNames(
                            "flex-shrink-0 stroke-1 group-hover:text-blue-600 mr-2 h-6 w-6",
                        )}
                        aria-hidden="true"
                    />
                )}
                {item.name}
            </NavLink>
            {item.spacer ? (
                <>
                    <div className="pt-2 border-b-2" />
                    <div className="pt-2" />
                </>
            ) : null}
        </div>
    ));

    const adminNavigation = !ROOT_USERS.includes(props.user.email)
        ? []
        : [
              {
                  key: "audit-log",
                  name: "Audit Log",
                  link: "/audit-log",
                  icon: ShieldCheckIcon,
              },
              {
                  key: "info",
                  name: "Info",
                  link: "/info",
                  icon: InformationCircleIcon,
              },
          ].map((item) => (
              <div key={item.key}>
                  <NavLink
                      to={item.link}
                      className={({ isActive }) =>
                          classNames(
                              "group flex items-center px-2 py-2",
                              "text-sm font-normal rounded-md",
                              " hover:bg-blue-50 hover:text-blue-600",
                              isActive
                                  ? "text-blue-600 font-semibold"
                                  : "text-dark-800",
                          )
                      }
                  >
                      <item.icon
                          className={classNames(
                              "flex-shrink-0 stroke-1 group-hover:text-blue-600 mr-2 h-6 w-6",
                          )}
                          aria-hidden="true"
                      />
                      {item.name}
                  </NavLink>
              </div>
          ));

    if (userData.isLoading) {
        return <LoadingPage />;
    }

    if (userData.data?.isActive === false) {
        return (
            <div className="flex flex-col items-center justify-center h-screen mx-auto text-gray-100 bg-slate-800">
                <ErrorCard
                    title="Access Denied"
                    subtitle="Your account or the organization you belong to has been deactivated. Please contact support for assistance."
                />
            </div>
        );
    }

    if (!isSuperAdmin && (!isClientAdmin || !hasPermissions)) {
        setTimeout(() => {
            window.location.href = getSparkwiseUrl();
        }, 3000);

        return (
            <div className="flex flex-col items-center justify-center h-screen mx-auto text-gray-100 bg-slate-800">
                <ErrorCard
                    title="Access Denied"
                    subtitle="You do not have permission to view this page"
                />
                <div className="w-6 h-6 flex space-x-2 justify-center items-center">
                    <div className="flex space-x-2">
                        <div className="text-gray-100 text-2xl animate-bounce [animation-delay:-0.3s]">
                            .
                        </div>
                        <div className="text-gray-100 text-2xl animate-bounce [animation-delay:-0.15s]">
                            .
                        </div>
                        <div className="text-gray-100 text-2xl animate-bounce">
                            .
                        </div>
                    </div>
                </div>
                <div className="mt-2">Redirecting to app.sparkwise.co</div>
            </div>
        );
    }

    return (
        <BrowserRouter>
            {isClientAdmin ? <Chatwoot /> : null}
            <div className="flex h-full">
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-40 lg:hidden"
                        onClose={() => setSidebarOpen(false)}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>

                        <div className="fixed inset-0 z-40 flex">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative flex flex-col flex-1 w-full max-w-xs bg-blue-background focus:outline-none">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 pt-2 -mr-12">
                                            <button
                                                type="button"
                                                className="flex items-center justify-center w-10 h-10 ml-1 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() =>
                                                    setSidebarOpen(false)
                                                }
                                            >
                                                <span className="sr-only">
                                                    Close sidebar
                                                </span>
                                                <XMarkIcon
                                                    className="w-6 h-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex-1 h-0 pt-5 pb-4 overflow-y-auto">
                                        <div className="flex items-center flex-shrink-0 px-4">
                                            <Bars3Icon
                                                className="w-6 h-6"
                                                aria-hidden="true"
                                            />
                                        </div>
                                        <nav
                                            aria-label="Sidebar"
                                            className="mt-5"
                                        >
                                            <div className="px-2 space-y-1">
                                                {navigation}
                                                {adminNavigation}
                                            </div>
                                        </nav>
                                    </div>
                                    {showMaintenanceWindow && (
                                        <div className="flex flex-shrink-0 p-4">
                                            <div className="flex items-center">
                                                <Flex
                                                    direction="column"
                                                    bg="info.-5"
                                                    gap="28px"
                                                    marginBottom="14px"
                                                    padding="20px 20px 12px 20px"
                                                    border="1px solid #FFEDAD"
                                                    borderRadius="12px"
                                                >
                                                    <Flex
                                                        justifyContent="flex-start"
                                                        alignItems="flex-start"
                                                    >
                                                        <Text
                                                            fontSize="14px"
                                                            lineHeight="22px"
                                                        >
                                                            Sparkwise is
                                                            scheduled for weekly
                                                            system maintenance
                                                            from&nbsp;
                                                            <strong>
                                                                3:00-4:30pm
                                                                US/Pacific
                                                            </strong>
                                                            .
                                                        </Text>
                                                    </Flex>
                                                    <Flex justifyContent="flex-end">
                                                        <Button
                                                            type="button"
                                                            variant="unstyled"
                                                            height="22px"
                                                            color="gray.900"
                                                            fontSize="14px"
                                                            lineHeight="22px"
                                                            fontWeight="600"
                                                            onClick={() =>
                                                                context.setMaintenanceWarningDismissed(
                                                                    true,
                                                                )
                                                            }
                                                        >
                                                            Got it
                                                        </Button>
                                                    </Flex>
                                                </Flex>
                                            </div>
                                        </div>
                                    )}
                                    <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
                                        <div className="flex items-center">
                                            {profile()}
                                        </div>
                                    </div>
                                    {isSuperAdmin && (
                                        <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
                                            <div className="flex items-center">
                                                <AppVersion />
                                            </div>
                                        </div>
                                    )}
                                </Dialog.Panel>
                            </Transition.Child>
                            <div
                                className="flex-shrink-0 w-14"
                                aria-hidden="true"
                            >
                                {/* Force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden lg:flex lg:flex-shrink-0">
                    <div className="flex flex-col w-60">
                        {/* Sidebar component, swap this element with another sidebar if you like */}
                        <div className="flex flex-col flex-1 min-h-0 bg-blue-background border-r border-gray-200">
                            <div className="flex flex-col flex-1 pt-5 pb-4 overflow-y-auto">
                                <div className="flex items-center flex-shrink-0 px-4">
                                    <img src={AdminLogo} alt="Logo" />
                                </div>
                                <nav
                                    className="flex-1 mt-5"
                                    aria-label="Sidebar"
                                >
                                    <div className="px-2 space-y-1">
                                        {navigation}
                                    </div>
                                </nav>
                            </div>
                            {showMaintenanceWindow && (
                                <div className="flex items-stretch flex-shrink-0">
                                    <nav
                                        className="flex-1 mt-3"
                                        aria-label="Sidebar"
                                    >
                                        <div className="px-2 space-y-1">
                                            <Flex
                                                direction="column"
                                                bg="info.-5"
                                                gap="28px"
                                                marginBottom="14px"
                                                padding="20px 20px 12px 20px"
                                                border="1px solid #FFEDAD"
                                                borderRadius="12px"
                                            >
                                                <Flex
                                                    justifyContent="flex-start"
                                                    alignItems="flex-start"
                                                >
                                                    <Text
                                                        fontSize="14px"
                                                        lineHeight="22px"
                                                    >
                                                        Sparkwise is scheduled
                                                        for weekly system
                                                        maintenance from&nbsp;
                                                        <strong>
                                                            3:00-4:30pm
                                                            US/Pacific
                                                        </strong>
                                                        .
                                                    </Text>
                                                </Flex>
                                                <Flex justifyContent="flex-end">
                                                    <Button
                                                        type="button"
                                                        variant="unstyled"
                                                        height="22px"
                                                        color="gray.900"
                                                        fontSize="14px"
                                                        lineHeight="22px"
                                                        fontWeight="600"
                                                        onClick={() =>
                                                            context.setMaintenanceWarningDismissed(
                                                                true,
                                                            )
                                                        }
                                                    >
                                                        Got it
                                                    </Button>
                                                </Flex>
                                            </Flex>
                                        </div>
                                    </nav>
                                </div>
                            )}
                            <div className="flex items-stretch flex-shrink-0 border-t border-gray-200">
                                <nav
                                    className="flex-1 mt-3"
                                    aria-label="Sidebar"
                                >
                                    <div className="px-2 space-y-1">
                                        {adminNavigation}
                                    </div>
                                </nav>
                            </div>
                            <div className="flex items-stretch flex-shrink-0 border-t border-gray-200">
                                {timezone()}
                            </div>
                            <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
                                <div className="flex items-center">
                                    {profile()}
                                </div>
                            </div>
                            {isSuperAdmin && (
                                <div className="flex flex-shrink-0 p-4 border-t border-gray-200">
                                    <div className="flex items-center">
                                        <AppVersion />
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="flex flex-col flex-1 min-w-0 overflow-hidden">
                    <div className="lg:hidden">
                        <div className="flex items-center justify-between bg-blue-background border-b border-gray-200 px-4 py-1.5">
                            <img src={AdminLogo} alt="Logo" />
                            <div>
                                <button
                                    type="button"
                                    className="inline-flex items-center justify-center w-12 h-12 -mr-3 text-dark-800 rounded-md hover:text-gray-900"
                                    onClick={() => setSidebarOpen(true)}
                                >
                                    <span className="sr-only">
                                        Open sidebar
                                    </span>
                                    <Bars3Icon
                                        className="w-6 h-6"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="relative z-0 flex flex-1 overflow-hidden">
                        <main className="relative z-0 flex-1 overflow-y-auto focus:outline-none xl:order-last">
                            <div className="absolute inset-0 px-4 py-6 mb-10 sm:px-6 lg:px-8">
                                {routes}
                                <div className="min-h-[40px]"></div>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        </BrowserRouter>
    );
};

export default Router;
