import classNames from "classnames";
import React, { useState } from "react";

interface Props {
    secondary?: boolean;
    disabled?: boolean;
    onClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    label: React.ReactNode | string;
    subtle?: boolean;
    className?: string;
    requiresConfirmation?: boolean;
    confirmationTheme?: "blue" | "red"; // assume default theme is blue
}

const Button = (props: Props) => {
    const [touched, setTouched] = useState<boolean>(false);

    const confirmationButtons = () => {
        return (
            <div
                className={classNames(
                    props.confirmationTheme === "red" && "ml-2 space-x-2",
                )}
            >
                <button
                    type="button"
                    className={classNames(
                        "border rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
                        props.confirmationTheme === "red"
                            ? "py-[8px] px-[8px] shadow-none text-[12px] text-black leading-[16px] border-gray-100 bg-white hover:bg-gray-50 focus:ring-gray-100"
                            : "py-1.5 px-4 shadow-sm text-sm text-gray-700 border-gray-300 bg-gray-100 hover:bg-white focus:ring-indigo-500",
                    )}
                    onClick={() => setTouched(false)}
                >
                    Cancel
                </button>
                <button
                    type="button"
                    className={classNames(
                        "border rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2",
                        props.confirmationTheme === "red"
                            ? "py-[8px] px-[8px] shadow-none text-[12px] text-danger-0  leading-[16px] border-gray-100 bg-danger--5 hover:bg-danger--4 focus:ring-danger--4"
                            : "py-1.5 px-4 shadow-sm text-sm text-white border-gray-300 bg-blue-500 hover:bg-blue-600 focus:ring-indigo-500",
                    )}
                    onClick={(e) => {
                        setTouched(false);
                        props.onClick(e);
                    }}
                >
                    Confirm
                </button>
            </div>
        );
    };

    if (props.requiresConfirmation && touched) {
        return confirmationButtons();
    }

    return (
        <button
            disabled={props.disabled}
            type="button"
            className={classNames(
                "py-1.5 px-4 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 border-gray-300   focus:ring-indigo-500",
                props.disabled || props.subtle
                    ? "bg-gray-300  border-gray-300  text-white hover:bg-gray-300  focus:ring-gray-300"
                    : props.secondary
                      ? "bg-gray-100 text-gray-700 hover:bg-white"
                      : "bg-blue-500 text-white hover:bg-blue-600",
                props.subtle && "hover:bg-blue-600",
                props.className,
            )}
            onClick={
                props.requiresConfirmation
                    ? () => setTouched(true)
                    : props.onClick
            }
        >
            {props.label}
        </button>
    );
};

export default Button;
