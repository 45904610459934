import { useEffect, useState } from "react";
import classNames from "classnames";
import { useGlobalContext } from "../context";
import { ParamsProps, withParams } from "../utils";
import Loading from "../components/common/Loading";
import { useTRPC } from "../hooks/useTRPC";
import IframeResizer from "iframe-resizer-react";

const Impact = (props: ParamsProps) => {
    const context = useGlobalContext();

    const trpc = useTRPC();
    const { data: org, error } = trpc.org.getOrgById.useQuery(
        props.params.organizationId || "",
    );
    const { data: iframeUrl } =
        trpc.impact.getImpactDashboardByOrganizationId.useQuery({
            orgId: props.params.organizationId || "",
        });

    useEffect(() => {
        if (org) {
            context.setOrganizationId(org.id);
        }
    }, [context, org]);

    if (!org) {
        return <Loading error={error?.message} />;
    }

    if (!iframeUrl) {
        return <div>(None)</div>;
    }

    return (
        <div className="">
            <IframeResizer
                style={{ width: "1px", minWidth: "100%" }}
                title="Impact Dashboard"
                src={iframeUrl.iframeUrl}
            />
        </div>
    );
};

export default withParams(Impact);
