import { ReactNode, useMemo, useState } from "react";
import Button from "../components/common/Button";

export const useShowHide = (initialShow: boolean = false) => {
    const [show, setShow] = useState(initialShow);

    const setHidden = () => setShow(false);
    const setShown = () => setShow(true);

    const ShowHideButton = useMemo(
        () =>
            (props: { show?: boolean; component: ReactNode; label?: string }) =>
                (
                    <div>
                        {!show && (
                            <Button
                                onClick={() => setShown()}
                                label={props.label || ""}
                            />
                        )}
                        {show && props.component}
                    </div>
                ),
        [show],
    );

    return { show, setHidden, setShown, ShowHideButton };
};

export default useShowHide;
