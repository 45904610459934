import { useParams } from "react-router-dom";
import { trpc } from "../../hooks/useTRPC";
import { Flex, Image } from "@chakra-ui/react";

export const CloudflareImage = () => {
    const { systemId } = useParams();

    const { data: signed_url } = trpc.learningDesign.getCloudflareImageURL.useQuery(
        { systemId: systemId! },
    );

    return (
            <Image
                objectFit="contain"
                borderStyle="solid"
                borderColor="gray.100"
                borderRadius="12px"
                src={signed_url}
            />
    );
};
