import { Switch } from "@headlessui/react";
import classNames from "classnames";

interface Props {
    disabled?: boolean;
    onChange: (value: boolean) => void;
    label: string;
    value: boolean;
}

const Toggle = ({ disabled, onChange, label, value }: Props) => (
    <fieldset disabled={disabled}>
        <Switch.Group as="div" className="flex items-center">
            <Switch
                checked={value}
                onChange={onChange}
                className={classNames(
                    disabled && !value
                        ? "bg-gray-100 cursor-not-allowed"
                        : disabled && value
                        ? "bg-blue-100 cursor-not-allowed"
                        : value
                        ? "bg-sky-800"
                        : "bg-slate-400",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500",
                )}
            >
                <span
                    aria-hidden="true"
                    className={classNames(
                        value ? "translate-x-5" : "translate-x-0",
                        "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200",
                    )}
                />
            </Switch>
            <Switch.Label as="span" className="ml-2">
                <span
                    className={classNames(
                        "text-sm font-medium",
                        disabled ? "text-slate-400" : "text-slate-900",
                    )}
                >
                    {label}
                </span>
            </Switch.Label>
        </Switch.Group>
    </fieldset>
);

export default Toggle;
