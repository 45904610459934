import { Avatar, AvatarBadge, Stack, Tooltip } from "@chakra-ui/react";
import { useOthers, useSelf } from "../liveblocks.config";

type Member = {
    id: string;
    firstName: string | null;
    lastName: string | null;
    avatarUrl: string | null;
    emails: {
        email: string;
    }[];
};

export const Presence = ({ members }: { members: Member[] }) => {
    const others = useOthers();
    const selfObject = useSelf();
    const othersMap = Object.fromEntries(others.map((o) => [o.id, o]));
    if (selfObject) {
        othersMap[selfObject.id!] = selfObject;
    }

    return (
        <Stack direction="row" spacing={2} alignItems="center">
            {members.map((member) => {
                const liveblocksMember = othersMap[member.emails[0]?.email];

                let label = `${member.firstName} ${member.lastName}`;
                if (liveblocksMember) {
                    label = `${member.firstName} ${member.lastName} - Step ${liveblocksMember.presence.stepNumber}`;
                }
                return (
                    <Tooltip
                        hasArrow
                        placement="bottom-end"
                        label={label}
                        key={member.id}
                    >
                        <Avatar
                            key={member.id}
                            src={member.avatarUrl || ""}
                            name={member.firstName + " " + member.lastName}
                            size="xs"
                        >
                            {liveblocksMember ? (
                                <AvatarBadge
                                    boxSize="1.2em"
                                    bg={
                                        liveblocksMember.presence
                                            .connectedToTwilio
                                            ? "#51fcb8"
                                            : "#fff25e"
                                    }
                                >
                                    {liveblocksMember.presence.stepNumber}
                                </AvatarBadge>
                            ) : (
                                <AvatarBadge
                                    boxSize=".75em"
                                    bg="#cccccc"
                                ></AvatarBadge>
                            )}
                        </Avatar>
                    </Tooltip>
                );
            })}
        </Stack>
    );
};
