import { useParams } from "react-router-dom";
import { ParamsProps, withParams } from "../../utils";
import { useGlobalContext } from "../../context";
import { useTRPC } from "../../hooks/useTRPC";
import { useEffect, useState } from "react";
import Table from "../../components/common/Table";
import Button from "../../components/common/Button";
import UserSelect from "../../components/factories/UserSelect";
import ErrorModal from "../../components/common/ErrorModal";
import Form from "../../components/common/Form";
import { PencilIcon } from "@heroicons/react/24/solid";
import classNames from "classnames";
import { CohortSettings } from "./CohortSettings";
import useShowHide from "../../hooks/useShowHide";
import Tooltip from "../../components/factories/Tooltip";
import { useIsSuperAdmin } from "../../hooks/useUserData";

const Cohort = (props: ParamsProps) => {
    const { cohortId } = useParams();

    const context = useGlobalContext();
    const trpc = useTRPC();
    const isSuperAdmin = useIsSuperAdmin();

    const [pending, setPending] = useState(false);
    const [error, setError] = useState("");
    const [errorIndex, setErrorIndex] = useState(1);
    const [userId, setUserId] = useState("");
    const [userList, setUserList] = useState("");
    const [editingName, setEditingName] = useState(false);

    const { setHidden: setAddUserHidden, ShowHideButton: AddUser } =
        useShowHide();
    const {
        setHidden: setAddMultipleUsersHidden,
        ShowHideButton: AddMultipleUsers,
    } = useShowHide();

    const { data: cohort, refetch } = trpc.cohorts.getCohort.useQuery(
        {
            cohortId: cohortId || "",
        },
        { enabled: !!cohortId },
    );
    const [cohortName, setCohortName] = useState(cohort?.name || "");
    const { mutateAsync: addUserToCohort } =
        trpc.cohorts.addUserToCohort.useMutation();
    const { mutateAsync: addUsersToCohort } =
        trpc.cohorts.addUsersToCohort.useMutation();

    const { mutateAsync: removeUserFromCohort } =
        trpc.cohorts.deleteUserFromCohort.useMutation();

    const { mutateAsync: updateCohort, isLoading } =
        trpc.cohorts.updateCohort.useMutation();

    const { data: users } = trpc.users.getOrgUsersByPage.useQuery(
        {
            organizationId: context.organizationId || "",
            pagination: false,
        },
        { enabled: !!context.organizationId },
    );

    const cancel = () => {
        setAddUserHidden();
        setAddMultipleUsersHidden();
        setError("");
    };

    useEffect(() => {
        if (cohort) {
            context.setOrganizationId(cohort?.organization.id);
            context.setOrganizationName(cohort?.organization.name);
            context.setOrganizationTimezone(
                cohort?.organization.timezone
                    ? cohort.organization.timezone.name
                    : "UTC",
            );
        }
    }, [cohort]);

    if (!cohort || !cohortId) {
        return <div>Not found.</div>;
    }

    return (
        <div className="mt-3">
            <ErrorModal error={error} key={errorIndex} />
            {editingName ? (
                <Form
                    cancel={() => setEditingName(false)}
                    submit={async () => {
                        await updateCohort({
                            cohortId: cohortId,
                            name: cohortName.trim(),
                        });
                        refetch();
                        setEditingName(false);
                    }}
                    change={(event) => setCohortName(event.currentTarget.value)}
                    disabled={isLoading}
                    label="Cohort name"
                    value={cohortName}
                />
            ) : (
                <div className="flex mb-4">
                    <h4 className="text-lg font-medium text-slate-800">
                        Cohort {cohort.name}
                    </h4>
                    <button
                        className={classNames(
                            "text-lg font-medium text-gray-900 cursor-pointer",
                        )}
                        onClick={() => {
                            setCohortName(cohort.name);
                            setEditingName(true);
                        }}
                        disabled={isLoading}
                    >
                        <PencilIcon className="w-4 ml-2 t-4 text-slate-500" />
                    </button>
                </div>
            )}

            {isSuperAdmin && <CohortSettings cohortId={cohortId} />}

            <div className="mt-6 flex flex-col gap-3">
                <AddUser
                    label="Add User"
                    component={
                        <div className="rounded-lg bg-gray-50 w-fit mt-6">
                            <div className="p-6">
                                <label
                                    htmlFor="user"
                                    className="block mb-1 text-sm font-medium text-gray-700"
                                >
                                    User
                                </label>
                                <UserSelect
                                    selectedUserId={userId}
                                    pending={pending}
                                    saved={false}
                                    users={users || []}
                                    attendedTags={false}
                                    onChange={(user: any) => {
                                        setUserId(user ? user.id : "");
                                    }}
                                    onSave={async () => {
                                        try {
                                            await addUserToCohort({
                                                userId,
                                                cohortId,
                                            });
                                            refetch();
                                        } catch (error) {
                                            setError((error as any).message);
                                            setErrorIndex(errorIndex + 1);
                                        }
                                    }}
                                />
                                <div className="mt-6">
                                    <Button
                                        label="Cancel"
                                        disabled={pending}
                                        onClick={cancel}
                                        secondary={true}
                                    />
                                </div>
                            </div>
                        </div>
                    }
                />

                <AddMultipleUsers
                    label="Add Multiple Users by Email"
                    component={
                        <div
                            className="mt-4"
                            style={{
                                width: "400px",
                            }}
                        >
                            <div className="flex">
                                <label
                                    htmlFor="addMultipleCohortUsers"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Add multiple participants by email
                                </label>
                                <Tooltip
                                    text={`Enter each email on a new line, or use a comma-separated list.`}
                                />
                            </div>
                            <div className="mt-1">
                                <textarea
                                    rows={4}
                                    name="addMultipleCohortUsers"
                                    id="addMultipleCohortUsers"
                                    className="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                                    onChange={(e) =>
                                        setUserList(e.target.value)
                                    }
                                    value={userList || ""}
                                ></textarea>
                            </div>
                            <div className="flex justify-end gap-2 mt-2">
                                <Button
                                    secondary
                                    disabled={pending}
                                    onClick={cancel}
                                    label="Cancel"
                                />
                                <Button
                                    disabled={pending || !userList?.length}
                                    onClick={async () => {
                                        try {
                                            const [updated, notFound] =
                                                await addUsersToCohort({
                                                    cohortId,
                                                    emails: userList,
                                                });
                                            refetch();
                                            if (notFound.length) {
                                                setError(
                                                    `The following users were not added because they couldn't be found: ${notFound.join(
                                                        ", ",
                                                    )}`,
                                                );
                                                setErrorIndex(errorIndex + 1);
                                            }
                                            setUserList("");
                                        } catch (error) {
                                            setError((error as any).message);
                                            setErrorIndex(errorIndex + 1);
                                        }
                                    }}
                                    label={"Add Participants"}
                                />
                            </div>
                        </div>
                    }
                />
            </div>

            <Table
                hidePages={true}
                columns={[
                    ["firstName", "First Name"],
                    ["lastName", "Last Name"],
                    ["email", "Email"],
                    ["remove", "Remove User"],
                ]}
                data={{
                    0: cohort.users.map((user) => {
                        return {
                            id: user.id,
                            firstName: user.firstName,
                            lastName: user.lastName,
                            email: user.emails
                                .map((email) => email.email)
                                .join(", "),
                            remove: (
                                <Button
                                    label="Remove"
                                    onClick={async () => {
                                        try {
                                            await removeUserFromCohort({
                                                userId: user.id,
                                                cohortId,
                                            });
                                            refetch();
                                        } catch (error) {
                                            setError((error as any).message);
                                            setErrorIndex(errorIndex + 1);
                                        }
                                    }}
                                />
                            ),
                            link: `/user/${user.id}`,
                        };
                    }),
                    1: [],
                }}
                initialSortField={"name"}
                onPageChange={(page: number) => {}}
                page={0}
                sortable={false}
            ></Table>
        </div>
    );
};

export default withParams(Cohort);
