import { trpc } from "./useTRPC";
import { AdminRouterOutput } from "server";

export const useUserData = () => {
    return trpc.user.getCurrentUser.useQuery();
};

export const useUserHasPermission = (permission: Permission) => {
    const { data: userData } = trpc.user.getCurrentUser.useQuery();
    return !!userData?.permissions?.[permission]?.value;
};

export const useIsSuperAdmin = () => {
    const { data: userData } = trpc.user.getCurrentUser.useQuery();
    return userData?.isSparkwiseAdmin;
};

export const useIsClientAdmin = () => {
    const { data: userData } = trpc.user.getCurrentUser.useQuery();
    return userData?.isClientAdmin;
};

export const useFullName = () => {
    const { data: userData } = trpc.user.getCurrentUser.useQuery();
    return userData ? `${userData?.firstName} ${userData?.lastName}` : null;
};

export const useOrgId = () => {
    const { data: userData } = trpc.user.getCurrentUser.useQuery();
    return userData?.organizationId;
};

type Permission =
    keyof AdminRouterOutput["user"]["getCurrentUser"]["permissions"];
