import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Select,
    Stack,
    Text,
} from "@chakra-ui/react";
import { DateTime } from "luxon";
import { useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { Navigate } from "react-router-dom";
import Loading from "../components/common/Loading";
import ImmersionSelect from "../components/factories/ImmersionSelect";
import InlineTooltip from "../components/factories/InlineTooltip";
import { useGlobalContext } from "../context";
import { trpc } from "../hooks/useTRPC";
import {
    convertDateTimeToISO,
    convertLocalJSDateToDateTime,
    localizeTimeToJSDate,
} from "../utils";
import { useUserHasPermission } from "../hooks/useUserData";

const AddSession = (props: { immersionId?: string }) => {
    const { post, organizationId, timezoneName } = useGlobalContext();

    const [error, setError] = useState<string | null>(null);
    const [immersionId, setImmersionId] = useState<string | null>(
        props.immersionId || null,
    );
    const [groupingStrategy, setGroupingStrategy] = useState("FIXED");
    const [sessionCohort, setSessionCohort] = useState("default");
    const [newSessionId, setNewSessionId] = useState("");
    const [pending, setPending] = useState(false);
    const [datetime, setDatetime] = useState(
        DateTime.utc().plus({ hour: 1 }).set({ minute: 0 }),
    );

    const hasCohortsPermissions = useUserHasPermission("cohorts");

    const isDisabled = pending || !immersionId || !groupingStrategy;

    // Don't reload for 2 minutes bc airtable is rate-limited.
    const { data: immersions } = trpc.immersions.getActiveImmersions.useQuery(
        undefined,
        { staleTime: 120000 },
    );

    const { data: cohorts } = trpc.cohorts.getCohorts.useQuery(
        {
            organizationId: organizationId || "",
        },
        { enabled: hasCohortsPermissions && !!organizationId },
    );

    const addSession = async () => {
        setError(null);
        setPending(true);
        try {
            const session = await post(`session/${organizationId}`, {
                immersionId,
                time: convertDateTimeToISO(datetime),
                groupingStrategy,
                cohortId: sessionCohort === "default" ? null : sessionCohort,
            });
            setPending(false);
            setNewSessionId(session.id);
        } catch (error) {
            setError((error as any).message);
            setPending(false);
        }
    };

    // Show the datetime in the picker in the current timezone
    const getDateTime = () => localizeTimeToJSDate(datetime, timezoneName);

    // Create a DateTime object from the local datetime
    // and the displayed timezone name
    const setDateTime = (localDatetime: Date | null) => {
        if (localDatetime) {
            setDatetime(
                convertLocalJSDateToDateTime(localDatetime, timezoneName),
            );
        }
    };

    if (newSessionId) {
        return <Navigate to={`/session/${newSessionId}`} />;
    } else if (!immersions) {
        return <Loading />;
    }

    return (
        <form
            onSubmit={async (event) => {
                event.preventDefault();
                return addSession();
            }}
        >
            <Flex
                bg={"gray.50"}
                borderRadius={"lg"}
                w="100%"
                maxW="960px"
                p={6}
                flexDirection="column"
                flexWrap="wrap"
                gap={5}
            >
                <FormControl
                    display={"flex"}
                    flexDirection="column"
                    flexGrow={1}
                    mt={"1px"}
                    alignContent={"space-around"}
                    justifyContent={"space-between"}
                >
                    <FormLabel
                        htmlFor="immersion"
                        fontSize="sm"
                        fontWeight={"medium"}
                        textColor={"gray.700"}
                    >
                        Module
                    </FormLabel>
                    <ImmersionSelect
                        pending={pending}
                        onChange={(immersion) =>
                            setImmersionId(immersion?.id || null)
                        }
                        showClear={"inline"}
                    />
                </FormControl>
                <Stack direction={{ base: "column", md: "row" }} gap={5}>
                    <FormControl
                        display={"flex"}
                        flexDirection={"column"}
                        w={"180px"}
                        alignContent={"space-around"}
                        justifyContent={"space-between"}
                    >
                        <FormLabel
                            htmlFor="immersion"
                            mb={1}
                            fontSize="sm"
                            fontWeight={"medium"}
                            textColor={"gray.700"}
                        >
                            Time ({timezoneName})
                        </FormLabel>
                        <Box minW={"180px"}>
                            <DateTimePicker
                                className="text-sm font-medium"
                                calendarIcon={null}
                                clearIcon={null}
                                disableClock={true}
                                locale="en-US"
                                minDetail="month"
                                onChange={setDateTime}
                                format="y/M/d h:mm a"
                                value={getDateTime()}
                            />
                        </Box>
                    </FormControl>

                    <FormControl
                        w="248px"
                        display={"flex"}
                        flexDirection={"column"}
                        alignContent={"space-around"}
                        justifyContent={"space-between"}
                    >
                        <FormLabel
                            htmlFor="immersion"
                            fontSize="sm"
                            fontWeight={"medium"}
                            textColor={"gray.700"}
                        >
                            <Text display={"inline"}>
                                Grouping{" "}
                                <InlineTooltip text="Fixed group sessions have 1 pre-set group of 2-5 participants. Dynamic group sessions will have groups of 2-5 automatically formed when people join, and can have up to 50 participants." />
                            </Text>
                        </FormLabel>
                        <Select
                            size={"md"}
                            borderColor={"gray.500"}
                            bg="white"
                            value={groupingStrategy}
                            onChange={(e) =>
                                setGroupingStrategy(e.target.value)
                            }
                            h={"36px"}
                        >
                            <option key="option1" value="FIXED">
                                Fixed (2-5 participants)
                            </option>
                            <option key="option2" value="DYNAMIC">
                                Dynamic (6+ participants)
                            </option>
                        </Select>
                    </FormControl>

                    {!!cohorts?.length && (
                        <FormControl
                            w="248px"
                            display={"flex"}
                            flexDirection={"column"}
                            alignContent={"space-around"}
                            justifyContent={"space-between"}
                        >
                            <FormLabel
                                htmlFor="immersion"
                                fontSize="sm"
                                fontWeight={"medium"}
                                textColor={"gray.700"}
                            >
                                <Text display={"inline"}>Cohort</Text>
                            </FormLabel>
                            <Select
                                size={"md"}
                                borderColor={"gray.500"}
                                bg="white"
                                value={sessionCohort}
                                onChange={(e) =>
                                    setSessionCohort(e.target.value)
                                }
                                h={"36px"}
                            >
                                <option key="default" value="default">
                                    All users
                                </option>
                                {cohorts?.map((cohort: any) => (
                                    <option key={cohort.id} value={cohort.id}>
                                        {cohort.name}
                                    </option>
                                ))}
                            </Select>
                        </FormControl>
                    )}
                </Stack>
                <Flex>
                    <Button
                        size={"md"}
                        fontWeight={"semibold"}
                        colorScheme={isDisabled ? "gray" : "blue"}
                        bg={isDisabled ? "dark.200" : "blue.500"}
                        textColor={"white"}
                        _hover={{
                            bg: "blue.600",
                        }}
                        px={4}
                        mt={4}
                        isDisabled={isDisabled}
                        type={"submit"}
                    >
                        Add session
                    </Button>
                </Flex>
            </Flex>
            {error ? (
                <Alert mt={2} status={"error"} variant={"left-accent"}>
                    <AlertIcon />
                    {error}
                </Alert>
            ) : null}
        </form>
    );
};

export default AddSession;
