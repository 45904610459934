import { useCallback, useEffect, useState } from "react";
import { useGlobalContext } from "../../context";
import Loading from "../common/Loading";
import SessionTable from "./SessionTable";
import { isFullyScheduled } from "../../utils";
import { useIsSuperAdmin } from "../../hooks/useUserData";

interface Props {
    all?: boolean;
    immersionId?: string;
    organizationOrUserId?: string;
    pastOrFuture: "past" | "future";
}

const SessionsByTime = (props: Props) => {
    const { get } = useGlobalContext();
    const isSuperAdmin = useIsSuperAdmin();
    const [error, setError] = useState("");
    const [page, setPage] = useState(0);
    const [data, setData] = useState<{
        [page: string]: any[];
    }>({});

    const endpoint = props.all
        ? `sessions-all/${props.pastOrFuture}`
        : props.organizationOrUserId
          ? `sessions/${props.organizationOrUserId}/${
                props.immersionId || "all"
            }/${props.pastOrFuture}`
          : `sessions-by-immersion/${props.immersionId || "all"}/${
                props.pastOrFuture
            }`;

    const fetchPage = useCallback(
        async (page: number) => {
            try {
                if (data[page]) {
                    return;
                }
                const sessions = await get(`${endpoint}/${page}`);
                for (const session of sessions) {
                    session.link = `/session/${session.id}`;
                    session.fullyScheduled = isFullyScheduled(session)
                        ? "✅"
                        : "❌";
                    session.account = session.organization.name;
                    session.moduleCode = session.immersion.name;
                    session.moduleName = session.immersion.tactic;
                    session.category = session.immersion.topic.name;
                    session.cohortName = session.cohort?.name;
                }
                setData({ ...data, [page]: sessions });
            } catch (error) {
                setError((error as any).message);
            }
        },
        [data, endpoint, get],
    );

    useEffect(() => {
        fetchPage(page);
        fetchPage(page + 1);
    }, [fetchPage, page]);

    if (Object.keys(data).length === 0 || error) {
        return <Loading error={error} />;
    }

    return (
        <div>
            <SessionTable
                data={data}
                hidePages={false}
                onPageChange={(page: number) => setPage(page)}
                page={page}
                sortDirection={props.pastOrFuture === "past" ? "desc" : "asc"}
                showOrganization={!props.organizationOrUserId}
                showAdvanced={isSuperAdmin}
            />
        </div>
    );
};

export default SessionsByTime;
