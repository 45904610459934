import { Dashboard } from "@uppy/react";
import useSparkwiseUppy from "../hooks/useSparkwiseUppy";

const ImmersionVideoUploadForm = ({ immersionId }: { immersionId: string }) => {
    const uppy = useSparkwiseUppy(immersionId);

    return (
        <div className="my-7">
            <Dashboard
                uppy={uppy}
                showSelectedFiles
                showProgressDetails
                proudlyDisplayPoweredByUppy={false}
            />
        </div>
    );
};

export default ImmersionVideoUploadForm;
