import {
    Box,
    Flex,
    Input,
    InputGroup,
    InputLeftElement,
    InputRightElement,
    ListItem,
    ListItemProps,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import {
    CheckIcon,
    ChevronDownIcon,
    ChevronUpIcon,
} from "@heroicons/react/24/outline";
import Downshift from "downshift";
import React, { ReactElement } from "react";

const DEFAULT_WIDTH = 400;

type InsightsSelectItem<T> = {
    item: T;
    key: string;
    disabled?: boolean;
    metadata?: {
        email: string;
    };
};

interface InsightsSelectProps<T> {
    items: InsightsSelectItem<T>[];
    itemToString: (item: InsightsSelectItem<T> | null) => string;
    leftHandIcon?: React.ReactNode;
    /**
     * Placeholder text for the input field
     */
    placeholder?: string;
    onChange?: (item: InsightsSelectItem<T> | null) => void;
    initialItem?: InsightsSelectItem<T>;
    /**
     * If set to true, the input field will be functional and items can be filtered
     */
    searchable?: boolean;
    width?: number;
    /**
     * Allows the select item to be cleared by using an item with the "placeholder" text
     */
    clearable?: boolean;
    variant?: "default" | "text";
    checkOnActive?: boolean;
    /**
     * An optional subtitle to show before all items
     */
    subtitle?: string | ReactElement;
    inputItemToString?: (item: InsightsSelectItem<T> | null) => string;
}

export const InsightsSelect = <T extends object>(
    props: InsightsSelectProps<T>,
) => {
    return (
        <Downshift
            itemToString={props.itemToString}
            onChange={props.onChange}
            initialSelectedItem={props.initialItem}
        >
            {({
                getRootProps,
                isOpen,
                openMenu,
                closeMenu,
                inputValue,
                getItemProps,
                getInputProps,
                itemToString,
                selectedItem,
                highlightedIndex,
                clearSelection,
            }) => {
                const variant = props.variant || "default";
                const listItemStyle: ListItemProps = {
                    cursor: "pointer",
                    paddingX: "16px",
                    paddingY: "12px",
                    fontSize: "14px",
                    fontWeight: 400,
                    lineHeight: "17px",
                    bg: "white",
                    color: "dark.600",
                };
                return (
                    <Box {...getRootProps()}>
                        {variant === "text" ? (
                            <Flex
                                alignItems={"center"}
                                gap={"8px"}
                                cursor={"pointer"}
                                onClick={() =>
                                    isOpen ? closeMenu() : openMenu()
                                }
                            >
                                <Text
                                    fontSize={"18px"}
                                    lineHeight={"25px"}
                                    color={"black"}
                                    fontWeight={600}
                                >
                                    {itemToString(selectedItem)}
                                </Text>
                                {!isOpen ? (
                                    <ChevronDownIcon height={"18px"} />
                                ) : (
                                    <ChevronUpIcon height={"18px"} />
                                )}
                            </Flex>
                        ) : null}
                        <InputGroup
                            display={variant === "text" ? "none" : undefined}
                        >
                            {props.leftHandIcon && (
                                <InputLeftElement
                                    pointerEvents={"none"}
                                    mt={0.5}
                                    ml={0.5}
                                >
                                    {props.leftHandIcon}
                                </InputLeftElement>
                            )}
                            {props.searchable ? (
                                <Input
                                    {...getInputProps()}
                                    placeholder={props.placeholder}
                                    _placeholder={{
                                        color: "gray.900",
                                    }}
                                    onFocus={() => openMenu()}
                                    bg={"white"}
                                    width={props.width}
                                    height={"44px"}
                                    fontWeight={600}
                                    color={"gray.900"}
                                />
                            ) : (
                                <Input
                                    readOnly
                                    cursor={"pointer"}
                                    value={
                                        props.inputItemToString
                                            ? props.inputItemToString(
                                                  selectedItem,
                                              )
                                            : itemToString(selectedItem)
                                    }
                                    onFocus={() => openMenu()}
                                    onClick={() => openMenu()}
                                    bg={"white"}
                                    width={props.width}
                                    height={"44px"}
                                    color={"gray.900"}
                                    _placeholder={{
                                        color: "gray.900",
                                    }}
                                    fontWeight={600}
                                />
                            )}
                            <InputRightElement
                                cursor={"pointer"}
                                onClick={() => {
                                    if (isOpen) {
                                        closeMenu();
                                    } else {
                                        openMenu();
                                    }
                                }}
                            >
                                {!isOpen && <ChevronDownIcon height={"18px"} />}
                                {isOpen && <ChevronUpIcon height={"18px"} />}
                            </InputRightElement>
                        </InputGroup>
                        {isOpen && (
                            <Box
                                position={"absolute"}
                                zIndex={100}
                                mt="6px"
                                p={0}
                                bg="white"
                                borderRadius={"8px"}
                                borderColor={"gray.200"}
                                borderStyle={"solid"}
                                borderWidth={"1px"}
                                display={"block"}
                                maxHeight={"200px"}
                                overflow={"scroll"}
                                width={props.width || DEFAULT_WIDTH}
                            >
                                <UnorderedList
                                    overflow={"scroll"}
                                    styleType={"none"}
                                    paddingY={"4px"}
                                    paddingX={0}
                                    m={0}
                                >
                                    <ListItem
                                        {...listItemStyle}
                                        color={"dark.600"}
                                        display={
                                            props.items.length
                                                ? "none"
                                                : undefined
                                        }
                                        fontStyle={"italic"}
                                    >
                                        No matching results
                                    </ListItem>
                                    <ListItem
                                        {...listItemStyle}
                                        color={
                                            !selectedItem
                                                ? "blue.500"
                                                : "dark.600"
                                        }
                                        _hover={{
                                            bg: "gray.50",
                                        }}
                                        onClick={() => clearSelection()}
                                        display={
                                            !props.clearable ||
                                            props.items.length === 0
                                                ? "none"
                                                : undefined
                                        }
                                    >
                                        <Flex direction={"row"} gap={"8px"}>
                                            {!selectedItem &&
                                                props.checkOnActive && (
                                                    <CheckIcon
                                                        height={"14px"}
                                                    />
                                                )}
                                            {selectedItem &&
                                                props.checkOnActive && (
                                                    <Box marginRight={"14px"} />
                                                )}
                                            {props.placeholder}
                                        </Flex>
                                    </ListItem>
                                    <Box
                                        height={"1px"}
                                        width={"100%"}
                                        borderBottom={"1px solid"}
                                        borderColor={"gray.200"}
                                        display={
                                            !props.clearable ||
                                            props.items.length === 0
                                                ? "none"
                                                : undefined
                                        }
                                    />
                                    {props.subtitle ? (
                                        <ListItem
                                            {...listItemStyle}
                                            _hover={{
                                                bg: "gray.50",
                                            }}
                                            onClick={() => clearSelection()}
                                            display={
                                                !props.clearable ||
                                                props.items.length === 0
                                                    ? "none"
                                                    : undefined
                                            }
                                            fontWeight={600}
                                        >
                                            Groups for:
                                        </ListItem>
                                    ) : null}
                                    {props.items
                                        .filter((_) => {
                                            if (
                                                !props.searchable ||
                                                !!selectedItem
                                            ) {
                                                return true;
                                            }
                                            return itemToString(_)
                                                .toLowerCase()
                                                .includes(
                                                    inputValue?.toLowerCase() ||
                                                        "",
                                                );
                                        })
                                        .map((item, index) => {
                                            const isSelected =
                                                selectedItem &&
                                                selectedItem.key === item.key;
                                            return (
                                                <ListItem
                                                    {...getItemProps({
                                                        item,
                                                        disabled: item.disabled,
                                                    })}
                                                    key={item.key}
                                                    {...listItemStyle}
                                                    bg={
                                                        highlightedIndex ===
                                                        index
                                                            ? "gray.50"
                                                            : "white"
                                                    }
                                                    color={
                                                        item.disabled
                                                            ? "dark.200"
                                                            : isSelected
                                                              ? "blue.500"
                                                              : "dark.600"
                                                    }
                                                >
                                                    <Flex
                                                        direction={"row"}
                                                        gap={"8px"}
                                                    >
                                                        {isSelected &&
                                                            props.checkOnActive && (
                                                                <CheckIcon
                                                                    height={
                                                                        "14px"
                                                                    }
                                                                />
                                                            )}
                                                        {!isSelected &&
                                                            props.checkOnActive && (
                                                                <Box
                                                                    marginRight={
                                                                        "14px"
                                                                    }
                                                                />
                                                            )}
                                                        {itemToString(item)}{" "}
                                                        {item.metadata
                                                            ?.email && (
                                                            <Text
                                                                color={
                                                                    item.disabled
                                                                        ? "dark.200"
                                                                        : "dark.400"
                                                                }
                                                                fontSize={
                                                                    "14px"
                                                                }
                                                                fontWeight={400}
                                                                fontStyle={
                                                                    "italic"
                                                                }
                                                            >
                                                                (
                                                                {
                                                                    item
                                                                        .metadata
                                                                        .email
                                                                }
                                                                )
                                                            </Text>
                                                        )}
                                                    </Flex>
                                                </ListItem>
                                            );
                                        })}
                                </UnorderedList>
                            </Box>
                        )}
                    </Box>
                );
            }}
        </Downshift>
    );
};
