import {
    Button,
    Grid,
    GridItem,
    Switch,
    TabPanel,
    Text,
    Textarea,
    Tooltip,
} from "@chakra-ui/react";
import { EventSendSettings } from "@prisma/client";
import { CUSTOM_PARAGRAPH_HINT } from "./constants";

export const FollowupTab = ({
    unsavedChanges,
    setSendFollowupEmails,
    sendFollowupEmails,
    preEmailFollowupText,
    setPreEmailFollowupText,
    sendSampleFollowupEmail,
}: {
    unsavedChanges: boolean;
    setSendFollowupEmails: (value: EventSendSettings) => void;
    sendFollowupEmails: EventSendSettings;
    preEmailFollowupText: string;
    setPreEmailFollowupText: (value: string) => void;
    sendSampleFollowupEmail: () => Promise<void>;
}) => {
    return (
        <TabPanel>
            <Grid templateColumns="2fr 2fr 2fr">
                <GridItem h="50px">
                    <Text fontWeight="semibold" fontSize="sm">
                        Enabled
                    </Text>
                </GridItem>
                <GridItem h="50px">
                    <Switch
                        isChecked={
                            sendFollowupEmails === EventSendSettings.SEND
                        }
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSendFollowupEmails(EventSendSettings.SEND);
                            } else {
                                setSendFollowupEmails(
                                    EventSendSettings.DO_NOT_SEND,
                                );
                            }
                        }}
                    ></Switch>
                </GridItem>
                <GridItem
                    hidden={
                        sendFollowupEmails === EventSendSettings.DO_NOT_SEND
                    }
                >
                    <Tooltip
                        label={unsavedChanges ? "Save changes to preview" : ""}
                    >
                        <Button
                            float="right"
                            marginBottom="20px"
                            isDisabled={unsavedChanges}
                            onClick={async () =>
                                await sendSampleFollowupEmail()
                            }
                        >
                            Email me a preview
                        </Button>
                    </Tooltip>
                </GridItem>
            </Grid>
            {sendFollowupEmails !== EventSendSettings.DO_NOT_SEND && (
                <Grid templateColumns="1fr 2fr">
                    <GridItem h="100px">
                        <Text fontWeight="semibold" fontSize="sm">
                            Email Message
                        </Text>
                        <Text fontWeight="medium" fontSize="xs" color="gray">
                            (Enter custom text at the start of any of the
                            emails)
                        </Text>
                    </GridItem>
                    <GridItem h="100px">
                        <Textarea
                            value={preEmailFollowupText}
                            placeholder={CUSTOM_PARAGRAPH_HINT}
                            onChange={(e) =>
                                setPreEmailFollowupText(e.target.value)
                            }
                        ></Textarea>
                    </GridItem>
                </Grid>
            )}
        </TabPanel>
    );
};
