import {
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import { ArrowDownTrayIcon, PrinterIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useInsights } from "./InsightsContext";

export const ExportToAIModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    const {downloadJSONForAI, expandContent, setShowMetadata} = useInsights()
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent minW={["auto", "600px"]} w={["80%"]} p={"20px"}>
                <ModalHeader>
                    <Heading
                        fontWeight="bold"
                        fontSize="22px"
                    >
                        Export
                    </Heading>
                </ModalHeader>
                <ModalBody mt={"4px"}>
                    <Text lineHeight={"22px"} mb={'22px'} fontWeight={400} fontSize={'14px'}>
                        This export is designed to let you export the resources that you just selected for offline review or for AI analysis. 
                    </Text>
                    <Text lineHeight={"22px"} mb={'22px'} fontWeight={400} fontSize={'14px'}>
                        "Export for AI" will download a ".zip" file archive containing several files, including a README that explains how to use the contents with specific AI services. "Print/PDF" will open your browser's print dialog, which you can use to format and export resources as a PDF.
                    </Text>
                </ModalBody>
                <ModalFooter pb={0}>
                    <Flex
                        w={"100%"}
                        justifyContent={"flex-end"}
                        direction={"row"}
                        gap={"16px"}
                    >
                        <Button
                            onClick={onClose}
                            variant="outline"
                            colorScheme="gray"
                            background="white"
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outline"
                            colorScheme="gray"
                            background="white"
                            onClick={async () => {
                                onClose();
                                expandContent();
                                setShowMetadata(true); 
                                setTimeout(window.print, 250);
                            }}
                        >
                            <PrinterIcon height={"20px"} />
                            <Text ml={2}>Print/PDF</Text>
                        </Button>
                        <Button
                            onClick={async () => {
                                await downloadJSONForAI();
                                onClose();
                            }}
                            variant="solid"
                            colorScheme="blue"
                        >
                            <ArrowDownTrayIcon height={"20px"} />
                            <Text ml={2}>Export for AI</Text>
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
