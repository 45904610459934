import { useCallback, useEffect, useState } from "react";
import { ParamsProps, withParams } from "../utils";
import { useGlobalContext } from "../context";
import Form from "../components/common/Form";
import Button from "../components/common/Button";
import Loading from "../components/common/Loading";
import Table from "../components/common/Table";
import { Navigate } from "react-router-dom";
import classNames from "classnames";
import useSetPassword from "../components/common/SetPasswordForm";
import { trpc } from "../hooks/useTRPC";
import { useIsSuperAdmin, useUserHasPermission } from "../hooks/useUserData";
import { Checkbox, Select, Text } from "@chakra-ui/react";
import ErrorModal from "../components/common/ErrorModal";
import Tooltip from "../components/factories/Tooltip";

const Users = (props: ParamsProps) => {
    const { setOrganizationId, setOrganizationName, setOrganizationTimezone } =
        useGlobalContext();

    const [adding, setAdding] = useState(false);
    const [error, setError] = useState("");
    const [modalError, setModalError] = useState("");
    const [errorIndex, setErrorIndex] = useState(1);
    const [email, setEmail] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [page, setPage] = useState(0);
    const [pending, setPending] = useState(false);
    const [userId, setUserId] = useState("");
    const isSuperAdmin = useIsSuperAdmin();
    const hasCohortPermissions = useUserHasPermission("cohorts");

    const { data: users, refetch } = trpc.users.getOrgUsersByPage.useQuery({
        organizationId: props.params.organizationId || "",
        page,
        pagination: true,
        includeCohorts: hasCohortPermissions,
    });

    const [userData, setUserData] = useState<Record<number, any>>({});

    useEffect(() => {
        setUserData({
            ...userData,
            [page]: users?.map((user) => ({
                ...user,
                attendedSessions: user.participants.filter(
                    (sessionParticipant) =>
                        sessionParticipant.firstVisitGroup !== null &&
                        sessionParticipant.lastVisitGroup !== null,
                ),
                cohorts: user.cohorts?.map((cohort) => cohort.name).join(", "),
                link: `/user/${user.id}`,
            })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, users, errorIndex]);

    const orgById = trpc.org.getOrgById.useQuery(
        props.params.organizationId || "",
    );

    useEffect(() => {
        try {
            const { data: org } = orgById;
            if (!org) return;
            setOrganizationId(org.id);
            setOrganizationName(org.name);
            setOrganizationTimezone(org.timezone ? org.timezone.name : "UTC");
        } catch (error) {
            setError((error as any).message);
        }
    }, [
        orgById,
        setOrganizationId,
        setOrganizationName,
        setOrganizationTimezone,
    ]);

    const [passwordChanged, setPasswordChanged] = useState(false);
    const { mutateAsync: changeOrgPassword } =
        trpc.users.changeOrgPassword.useMutation();

    const postChangePassword = useCallback(
        async (password: string) => {
            try {
                setPending(true);
                await changeOrgPassword({
                    organizationId: props.params.organizationId || "",
                    password,
                });
                cancel();
                setPasswordChanged(true);
            } catch (error) {
                setError((error as any).message);
            } finally {
                setPending(false);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [email],
    );

    const { SetPasswordForm, showSetPassword, setPassword } = useSetPassword({
        changePassword: postChangePassword,
        label: passwordChanged
            ? "Password changed successfully for all users"
            : "Change password",
        disabled: pending,
    });

    const cancel = () => {
        setAdding(false);
        setEmail("");
        setFirstName("");
        setLastName("");
        setPassword("");
    };

    const { mutateAsync: createUserForOrg } =
        trpc.users.createUserForOrg.useMutation();

    const columns: (
        | [string, string]
        | [
              string,
              string,
              (field: any) => string | Date | JSX.Element,
              boolean?,
          ]
    )[] = [
        ["firstName", "First Name"],
        ["lastName", "Last Name"],
        [
            "emails",
            "Email",
            (value: { email: string }[]) =>
                value.map((email: any) => email.email).join(", "),
        ],
        [
            "active",
            "Status",
            (value: string) => (value ? "Active" : "Inactive"),
        ],
        ["participants", "Sessions", (value: any[]) => value.length.toString()],
        [
            "attendedSessions",
            "Attended",
            (value: any[]) => value.length.toString(),
        ],
    ];
    if (isSuperAdmin || hasCohortPermissions) {
        columns.push(["cohorts", "Cohort"]);
    }

    if (userId) {
        return <Navigate to={`/user/${userId}`} />;
    }
    if (
        error ||
        !userData ||
        !props.params.organizationId ||
        userData[page] === undefined
    ) {
        return <Loading error={error} />;
    }
    return (
        <>
            <ErrorModal error={modalError} key={errorIndex} />
            <h4 className="text-lg font-medium text-slate-800 mb-6">Users</h4>

            <div className="flex items-center gap-4">
                {!showSetPassword &&
                    (adding ? (
                        <Form
                            customInputs={[
                                <div>
                                    <label
                                        htmlFor="email"
                                        className="block text-sm font-medium text-gray-700 mb-1"
                                    >
                                        Email
                                    </label>
                                    <input
                                        type="text"
                                        name="input"
                                        id="input"
                                        className={classNames(
                                            "text-sm font-medium rounded-md mr-2 focus:outline outline-1 outline-blue-500",
                                            pending ? "bg-gray-100" : "",
                                        )}
                                        size={36}
                                        autoFocus={true}
                                        placeholder={"user@example.com"}
                                        value={email}
                                        onChange={(event) =>
                                            setEmail(event.currentTarget.value)
                                        }
                                        disabled={pending}
                                    />
                                </div>,
                                <div className="mt-4">
                                    <label
                                        htmlFor="firstName"
                                        className="block text-sm font-medium text-gray-700 mb-1"
                                    >
                                        First name
                                    </label>
                                    <input
                                        type="text"
                                        name="input"
                                        id="input"
                                        className={classNames(
                                            "text-sm font-medium rounded-md mr-2 focus:outline outline-1 outline-blue-500",
                                            pending ? "bg-gray-100" : "",
                                        )}
                                        size={36}
                                        placeholder={""}
                                        value={firstName}
                                        onChange={(event) =>
                                            setFirstName(
                                                event.currentTarget.value,
                                            )
                                        }
                                        disabled={pending}
                                    />
                                </div>,
                                <div className="mt-4">
                                    <label
                                        htmlFor="lastName"
                                        className="block text-sm font-medium text-gray-700 mb-1"
                                    >
                                        Last name
                                    </label>
                                    <input
                                        type="text"
                                        name="input"
                                        id="input"
                                        className={classNames(
                                            "text-sm font-medium rounded-md mr-2 focus:outline outline-1 outline-blue-500",
                                            pending ? "bg-gray-100" : "",
                                        )}
                                        size={36}
                                        placeholder={""}
                                        value={lastName}
                                        onChange={(event) =>
                                            setLastName(
                                                event.currentTarget.value,
                                            )
                                        }
                                        disabled={pending}
                                    />
                                </div>,
                            ]}
                            cancel={cancel}
                            submit={async () => {
                                setPending(true);
                                setError("");
                                try {
                                    const user = await createUserForOrg({
                                        organizationId:
                                            props.params.organizationId!,
                                        email: email.toLowerCase(),
                                        firstName: firstName,
                                        lastName: lastName,
                                    });
                                    setUserId(user.id);
                                    cancel();
                                } catch (error) {
                                    setError((error as any).message);
                                }
                                setPending(false);
                            }}
                            disabled={pending}
                            error={error}
                        />
                    ) : (
                        <Button
                            onClick={() => {
                                cancel();
                                setAdding(true);
                            }}
                            label="Add User"
                        />
                    ))}
                {!adding && isSuperAdmin && SetPasswordForm}
            </div>
            {isSuperAdmin && (
                <AddManyUsers
                    organizationId={props.params.organizationId}
                    setPending={setPending}
                ></AddManyUsers>
            )}
            <Table
                activeField={"active"}
                columns={columns}
                data={userData}
                initialSortField={"firstName"}
                onPageChange={(page: number) => setPage(page)}
                page={page}
                searchable={["firstName", "lastName", "emails"]}
                sortable={true}
            />
        </>
    );
};

export default withParams(Users);

const AddManyUsers = ({
    organizationId,
    setPending,
}: {
    organizationId: string;
    setPending: (pending: boolean) => void;
}) => {
    const utils = trpc.useContext();
    const [includeManagers, setIncludeManagers] = useState(false);
    const [errors, setErrors] = useState<string[]>([]);
    const [discrepancies, setDiscrepancies] = useState<string[]>([]);
    const [success, setSuccess] = useState("");
    const [usersMany, setUsersMany] = useState("");
    const [hidden, setHidden] = useState(true);
    const { mutateAsync: createUserForOrg } =
        trpc.users.createUserForOrg.useMutation({
            onSuccess(input) {
                utils.users.getOrgUsersByPage.invalidate();
            },
        });
    const { mutateAsync: assignManager } = trpc.users.assignManager.useMutation(
        {
            onSuccess(input) {
                utils.users.getOrgUsersByPage.invalidate();
            },
        },
    );
    if (hidden) {
        return (
            <Button onClick={() => setHidden(false)} label="Add Many Users" />
        );
    }
    return (
        <div
            className="mt-4"
            style={{
                width: "400px",
            }}
        >
            <div className="flex mb-2">
                <Checkbox
                    isChecked={includeManagers}
                    onChange={(e) => setIncludeManagers(e.target.checked)}
                >
                    Include Manager Assignments
                </Checkbox>
            </div>

            <div className="flex">
                <label
                    htmlFor="sessionParticipantMany"
                    className="block text-sm font-medium text-gray-700"
                >
                    {`Add multiple users ${includeManagers ? "and their managers" : ""} in CSV format`}
                </label>
                <Tooltip
                    stretchToFit
                    text={`Format each line as:\n
                        ${
                            includeManagers
                                ? "first?,last?,email,managerFirst?,managerLast?,managerEmail\n\nwhere ? denotes optional fields"
                                : "firstName,lastName,email"
                        }`}
                />
            </div>

            <div className="mt-1">
                <textarea
                    rows={4}
                    name="usersMany"
                    id="usersMany"
                    className="block w-full border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 sm:text-sm"
                    onChange={(e) =>
                        setUsersMany(e.target.value?.replace(/\t/g, ","))
                    }
                    value={usersMany || ""}
                ></textarea>
            </div>
            <div className="flex justify-end gap-2 mt-2">
                <Button
                    secondary
                    onClick={() => setHidden(true)}
                    label="Cancel"
                />
                <Button
                    disabled={!usersMany}
                    onClick={async () => {
                        setPending(true);
                        setErrors([]);
                        setDiscrepancies([]);
                        setSuccess("");
                        let currentLine = "";
                        try {
                            let failed = [];
                            let discrepancies = [];
                            for (const line of usersMany.split("\n")) {
                                const [
                                    firstName,
                                    lastName,
                                    email,
                                    managerFirst,
                                    managerLast,
                                    managerEmail,
                                ] = line.trim().split(",");

                                if (includeManagers) {
                                    if (!email || !managerEmail) {
                                        failed.push(
                                            `${line}: Row is missing data`,
                                        );
                                        continue;
                                    }
                                    currentLine = line;
                                    try {
                                        const [savedUser, savedManager] =
                                            await assignManager({
                                                organizationId,
                                                user: {
                                                    email: email.toLowerCase(),
                                                    firstName: firstName,
                                                    lastName: lastName,
                                                },
                                                manager: {
                                                    email: managerEmail.toLowerCase(),
                                                    firstName: managerFirst,
                                                    lastName: managerLast,
                                                },
                                            });
                                        if (
                                            savedUser.firstName !== firstName ||
                                            savedUser.lastName !== lastName
                                        ) {
                                            discrepancies.push(
                                                `Provided user name (${firstName} ${lastName}) ignored in favor of original name (${savedUser.firstName} ${savedUser.lastName})`,
                                            );
                                        }
                                        if (
                                            savedManager.firstName !==
                                                managerFirst ||
                                            savedManager.lastName !==
                                                managerLast
                                        ) {
                                            discrepancies.push(
                                                `Provided manager name (${managerFirst} ${managerLast}) ignored in favor of original name (${savedManager.firstName} ${savedManager.lastName})`,
                                            );
                                        }
                                    } catch (error) {
                                        const message = (error as any).message;
                                        failed.push(
                                            `${currentLine}: Failed to assign manager because ${
                                                message || "Unknown error"
                                            }`,
                                        );
                                    }
                                } else {
                                    if (!firstName || !email) {
                                        failed.push(
                                            `${line}: Row is missing data`,
                                        );
                                        continue;
                                    }
                                    currentLine = line;
                                    try {
                                        await createUserForOrg({
                                            organizationId,
                                            email: email.toLowerCase(),
                                            firstName: firstName,
                                            lastName: lastName,
                                        });
                                    } catch (error) {
                                        const message = (error as any).message;
                                        failed.push(
                                            `${currentLine}: Failed to create user because ${
                                                message || "Unknown error"
                                            }`,
                                        );
                                    }
                                }
                            }
                            setPending(false);
                            setUsersMany("");
                            if (failed.length) {
                                setErrors(failed);
                            } else {
                                setSuccess("All users added successfully");
                            }
                            if (discrepancies.length) {
                                setDiscrepancies(discrepancies);
                            }
                        } catch (error) {
                            setErrors([
                                `Error at line ${currentLine}: ${
                                    (error as any).message
                                }`,
                            ]);
                        }
                    }}
                    label={`Add Users ${includeManagers ? "and Managers" : ""}`}
                />
            </div>

            {errors.length > 0 && (
                <>
                    <Text fontSize="14px" color="gray.700">
                        The following rows failed:
                    </Text>
                    {errors.map((error, idx) => (
                        <Loading key={idx} error={error} />
                    ))}
                </>
            )}
            {discrepancies.length > 0 && (
                <>
                    <Text fontSize="14px" color="gray.700">
                        The following rows had name discrepancies. The original
                        names were retained.
                    </Text>
                    {discrepancies.map((discrepancy, idx) => (
                        <Loading key={idx} warning={discrepancy} />
                    ))}
                </>
            )}
            {success.length > 0 && <Loading success={success} />}
        </div>
    );
};
