import { useParams } from "react-router-dom";
import { trpc } from "../../hooks/useTRPC";
import { Flex } from "@chakra-ui/react";
import ReactPlayer from "react-player";

export const CloudflareStream = () => {
    const { systemId } = useParams();

    const { data: cloudflareToken } = trpc.learningDesign.getCloudflareStreamToken.useQuery(
        { systemId: systemId! },
    );

    const url =         `https://customer-xos73p96y4i4wugs.cloudflarestream.com/${cloudflareToken}/manifest/video.mpd`;
    const caption_url = `https://customer-xos73p96y4i4wugs.cloudflarestream.com/${cloudflareToken}/captions/en`;

    /* 
        ReactPlayer renders a container div with a video element inside
        The "width" and "height" props are used on the video element)
        The "style" prop is used on the container div
    */

    return (
        <ReactPlayer
            url={url}
            key={url}
            controls={true}
            width="100%"
            height="100%"
            // the styles below make the player respect the size limitations
            // of the Flex parent container and not flow outside the container
            // to maintain the aspect ratio
            style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                backgroundColor: "black",
            }}
            config={{
                file: {
                    attributes: {
                        disablePictureInPicture: true,
                        crossOrigin: 'anonymous',
                    },
                    tracks: [
                        {
                            kind: 'subtitles', 
                            label: 'English',
                            src: caption_url, 
                            srcLang: 'en', 
                            default: true,
                        },
                    ],
                },
            }}
        />
    );
};
