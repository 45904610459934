import { useTRPC } from "./useTRPC";
import { useInsights } from "../App/Insights/InsightsContext";
import { AdminRouterOutput } from "server/src/trpc";

type InsightParticipant =
    | AdminRouterOutput["insights"]["getOrgParticipants"][0]
    | AdminRouterOutput["insights"]["getUsersForInsights"][0];

export const useInsightsUsers = (): Array<{
    item: InsightParticipant;
    disabled?: boolean;
    key: string;
    metadata: {
        email: string;
    };
}> | null => {
    const trpc = useTRPC();
    const { filterConfig } = useInsights();
    const { data: allUsers } = trpc.insights.getOrgParticipants.useQuery({
        organizationId: filterConfig.organizationId,
    });
    const { data: users } = trpc.insights.getUsersForInsights.useQuery(
        {
            ...filterConfig,
            userIds: undefined,
        },
        {
            enabled: !!filterConfig.immersionId,
        },
    );
    if (!allUsers || !users) {
        return null;
    }
    const insightUsers = users
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((_) => ({
            item: _,
            disabled: false,
            key: _.userId,
            metadata: {
                email: _.email,
            },
        }));
    for (let u of allUsers.sort((a, b) => a.name.localeCompare(b.name))) {
        if (!insightUsers.some((_) => _.item.userId === u.userId)) {
            insightUsers.push({
                item: u,
                disabled: true,
                key: u.userId,
                metadata: {
                    email: u.email,
                },
            });
        }
    }
    return insightUsers;
};
