import { useGlobalContext } from "../../context";
import { useTRPC } from "../../hooks/useTRPC";
import { ParamsProps, withParams } from "../../utils";
import { useEffect, useState } from "react";
import Button from "../../components/common/Button";
import Form from "../../components/common/Form";
import Table from "../../components/common/Table";
import ErrorModal from "../../components/common/ErrorModal";

const Cohorts = (props: ParamsProps) => {
    const context = useGlobalContext();
    const trpc = useTRPC();

    const { data: org } = trpc.org.getOrgById.useQuery(
        props.params.organizationId || "",
    );

    const [addingCohort, setAddingCohort] = useState(false);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState("");
    const [errorIndex, setErrorIndex] = useState(1);
    const [name, setName] = useState("");

    const { data: cohorts, refetch } = trpc.cohorts.getCohorts.useQuery(
        {
            organizationId: context.organizationId || "",
        },
        { enabled: !!context.organizationId },
    );
    const { mutateAsync: createCohort } =
        trpc.cohorts.createCohort.useMutation();

    useEffect(() => {
        if (org) {
            context.setOrganizationId(org.id);
            context.setOrganizationName(org.name);
            context.setOrganizationTimezone(
                org.timezone ? org.timezone.name : "UTC",
            );
        }
    }, [context, org]);

    const cancel = () => {
        setAddingCohort(false);
        setName("");
        setError("");
    };

    return (
        <div className="mt-3">
            <ErrorModal error={error} key={errorIndex} />
            <h4 className="text-lg font-medium text-slate-800 mb-4">Cohorts</h4>
            {addingCohort ? (
                <Form
                    cancel={cancel}
                    submit={async () => {
                        setPending(true);
                        setError("");
                        try {
                            await createCohort({
                                organizationId: context.organizationId || "",
                                name,
                            });
                            refetch();
                            cancel();
                        } catch (error) {
                            setError((error as any).message);
                            setErrorIndex(errorIndex + 1);
                        }
                        setPending(false);
                    }}
                    change={(event) => setName(event.currentTarget.value)}
                    disabled={pending}
                    label="Name"
                    placeholder={"Cohort Name"}
                    value={name}
                />
            ) : (
                <Button
                    onClick={() => {
                        setAddingCohort(true);
                    }}
                    label="Add Cohort"
                />
            )}
            <Table
                hidePages={true}
                columns={[
                    ["name", "Name"],
                    ["users", "Users"],
                ]}
                data={{
                    0:
                        cohorts?.map((cohort: any) => {
                            return {
                                id: cohort.id,
                                name: cohort.name,
                                users: cohort.users.length,
                                link: `/cohort/${cohort.id}`,
                            };
                        }) || [],
                    1: [],
                }}
                initialSortField={"name"}
                onPageChange={(page: number) => {}}
                page={0}
                sortable={false}
            ></Table>
        </div>
    );
};

export default withParams(Cohorts);
