import {
    Button,
    Flex,
    Grid,
    GridItem,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TabPanel,
    Text,
    Textarea,
    Tooltip,
} from "@chakra-ui/react";
import { EventSendSettings, LinksBulletsType } from "@prisma/client";
import { CustomBullets } from "./CustomBullets";
import { CUSTOM_PARAGRAPH_HINT } from "./constants";

export const LinksTab = ({
    unsavedChanges,
    sendSampleEmail,
    linksBulletsType,
    setLinksBulletsType,
    customBulletsList,
    setCustomBulletsList,
    setSendLinkEmails,
    sendLinkEmails,
    preEmailLinksText,
    setPreEmailLinksText,
    defaultLinksBullets,
}: {
    unsavedChanges: boolean;
    sendSampleEmail: () => Promise<void>;
    linksBulletsType: LinksBulletsType;
    setLinksBulletsType: (value: LinksBulletsType) => void;
    customBulletsList: string[];
    setCustomBulletsList: (value: string[]) => void;
    setSendLinkEmails: (value: EventSendSettings) => void;
    sendLinkEmails: EventSendSettings;
    preEmailLinksText: string;
    setPreEmailLinksText: (value: string) => void;
    defaultLinksBullets: string[];
}) => {
    return (
        <TabPanel>
            <Grid templateColumns="2fr 2fr 2fr">
                <GridItem h="50px">
                    <Text fontWeight="semibold" fontSize="sm">
                        Enabled
                    </Text>
                </GridItem>
                <GridItem h="50px">
                    <Switch
                        isChecked={sendLinkEmails === EventSendSettings.SEND}
                        onChange={(e) => {
                            if (e.target.checked) {
                                setSendLinkEmails(EventSendSettings.SEND);
                            } else {
                                setSendLinkEmails(
                                    EventSendSettings.DO_NOT_SEND,
                                );
                            }
                        }}
                    ></Switch>
                </GridItem>
                <GridItem
                    hidden={sendLinkEmails === EventSendSettings.DO_NOT_SEND}
                >
                    <Tooltip
                        label={unsavedChanges ? "Save changes to preview" : ""}
                    >
                        <Button
                            float="right"
                            marginBottom="20px"
                            isDisabled={unsavedChanges}
                            onClick={async () => await sendSampleEmail()}
                        >
                            Email me a preview
                        </Button>
                    </Tooltip>
                </GridItem>
            </Grid>
            {sendLinkEmails !== EventSendSettings.DO_NOT_SEND && (
                <Grid templateColumns="1fr 2fr">
                    <GridItem h="100px">
                        <Text fontWeight="semibold" fontSize="sm">
                            Email Message
                        </Text>
                        <Text fontWeight="medium" fontSize="xs" color="gray">
                            (Enter custom text at the start of any of the
                            emails)
                        </Text>
                    </GridItem>
                    <GridItem h="100px">
                        <Textarea
                            value={preEmailLinksText}
                            placeholder={CUSTOM_PARAGRAPH_HINT}
                            onChange={(e) =>
                                setPreEmailLinksText(e.target.value)
                            }
                        ></Textarea>
                    </GridItem>
                </Grid>
            )}
            {sendLinkEmails !== EventSendSettings.DO_NOT_SEND && (
                <Grid templateColumns="1fr 2fr">
                    <GridItem>
                        <Text fontWeight="semibold" fontSize="sm">
                            Custom Bullets
                        </Text>
                    </GridItem>
                    <GridItem>
                        <RadioGroup
                            onChange={setLinksBulletsType}
                            value={linksBulletsType}
                        >
                            <Stack direction="row">
                                <Radio value={LinksBulletsType.DEFAULT}>
                                    Default Bullets
                                </Radio>
                                <Radio value={LinksBulletsType.CUSTOM_BULLETS}>
                                    Custom Bullets
                                </Radio>
                            </Stack>
                        </RadioGroup>
                        {linksBulletsType ===
                        LinksBulletsType.CUSTOM_BULLETS ? (
                            <CustomBullets
                                customBulletsList={customBulletsList}
                                setCustomBulletsList={setCustomBulletsList}
                            />
                        ) : (
                            <Flex flexDir="column">
                                <Textarea isDisabled h="90px">
                                    {defaultLinksBullets?.join("\n")}
                                </Textarea>
                            </Flex>
                        )}
                    </GridItem>
                </Grid>
            )}
        </TabPanel>
    );
};
