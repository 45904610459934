import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

interface Props {
    error: string;
}

const ErrorBox = (props: Props) =>
    props.error ? (
        <div className="w-fit text-red-900 rounded-md mt-4 mb-2 p-2 bg-red-100 flex items-center">
            <ExclamationCircleIcon className="w-4 h-4 mr-2 text-red-700" />
            {props.error}
        </div>
    ) : null;

export default ErrorBox;
