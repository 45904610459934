import React, { useState, useEffect, useRef, useCallback } from "react";
import Button from "./Button";

interface MultiselectPopupProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (selectedValues: string[]) => void;
    values: string[];
    filterMap: Record<string, { value: string[]; active: boolean }>;
    filterField: string;
}

const MultiselectPopup = (props: MultiselectPopupProps) => {
    const wrapperRef = useRef<any>();

    const [selected, setSelected] = useState<string[]>(
        props.filterMap[props.filterField].value,
    );

    useEffect(() => {
        const handleClickAway = (e: any) => {
            if (wrapperRef.current && !wrapperRef.current.contains(e.target)) {
                handleSubmit();
            }
        };

        document.addEventListener("mousedown", handleClickAway);

        return () => {
            document.removeEventListener("mousedown", handleClickAway);
        };
    }, [wrapperRef, selected]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e.target.checked) {
            if (!selected.includes(e.target.value)) {
                setSelected([...selected, e.target.value]);
            }
        } else {
            if (selected.includes(e.target.value)) {
                setSelected(selected.filter((x) => x !== e.target.value));
            }
        }
    };

    const handleSubmit = () => {
        props.onSubmit(selected);
    };

    const clear = () => {
        props.onSubmit([]);
    };

    return (
        <div
            className="absolute flex flex-col bg-white shadow-md rounded-md p-4 top-full right-0"
            ref={wrapperRef}
        >
            <div className="flex">
                <ul>
                    {props.values.map((value) => (
                        <div key={value} className="flex flex-row py-1.5 gap-2">
                            <input
                                type="checkbox"
                                className="px-2 py-1 mt-1 text-sm text-gray-900 border rounded"
                                name={value}
                                value={value}
                                checked={selected.includes(value)}
                                onChange={handleChange}
                            />
                            <label>{value}</label>
                        </div>
                    ))}
                </ul>
            </div>

            <div className="flex gap-1">
                <Button onClick={handleSubmit} label="Apply" className="mt-2" />
                <Button
                    onClick={clear}
                    secondary
                    label="Clear"
                    className="mt-2"
                />
            </div>
        </div>
    );
};

export default MultiselectPopup;
