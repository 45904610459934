import {
    Box,
    Button,
    Flex,
    Image,
    keyframes,
    Link,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import InsightsLogo from "design/assets/InsightsLogo.png";
import InsightsDemo from "design/assets/InsightsDemo.png";
import { useInsights } from "./InsightsContext";
import { usePostHog } from "posthog-js/react";
import { wait } from "../../utils";

const fadeIn = keyframes`
from {
    opacity: 0;
    transform: translateX(-50%);
}
to {
    opacity: 1;
}
`;

const Loader: React.FC<{
    width: number;
    value: boolean;
    onDone: () => void;
}> = ({ width, value, onDone }) => {
    const min = 4;
    const loaderWidth = ((value ? 1 : 0) * width * (width - min)) / 100 + min;
    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (value) {
            timer = setTimeout(() => {
                onDone();
            }, 1700);
        }
        return () => clearTimeout(timer);
    }, [value]);
    return (
        <Box
            bg={"blue.100"}
            width={width + "px"}
            height={"4px"}
            borderRadius={"60px"}
            overflow={"hidden"}
        >
            <Box
                bg={"positive.0"}
                height={"4px"}
                borderRadius={"60px"}
                width={loaderWidth + "px"}
                transition={"width 1.5s ease-in-out"}
            ></Box>
        </Box>
    );
};

export const ComingSoonModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    const { openDownloadModal } = useInsights();
    const posthog = usePostHog();
    const [showLoader, setShowLoader] = useState(false);
    const [showThankYou, setShowThankYou] = useState(false);
    const [loaderValue, setLoaderValue] = useState(false);
    const [showButton, setShowButton] = useState(true);
    useEffect(() => {
        if (isOpen) {
            setShowLoader(false);
            setLoaderValue(false);
            setShowButton(true);
            setShowThankYou(false);
        }
    }, [isOpen]);
    const onSubmit = async () => {
        posthog.capture("insights_coming_soon_event");
        await wait(50);
        setShowButton(false);
        setShowLoader(true);
        await wait(100);
        setLoaderValue(true);
    };
    const onDone = () => {
        setShowLoader(false);
        setShowThankYou(true);
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                minW={["auto", "600px"]}
                w={["80%"]}
                p={"28px"}
                borderRadius={"20px"}
            >
                <ModalHeader p={0}>
                    <Flex justifyContent={"center"} mb={"16px"}>
                        <Image src={InsightsLogo} height={"40px"} />
                    </Flex>
                </ModalHeader>
                <ModalBody>
                    <Text
                        lineHeight={"22px"}
                        fontWeight={600}
                        fontSize={"22px"}
                        color={"dark.700"}
                        mb={"8px"}
                    >
                        In-app AI insights are coming soon...
                    </Text>
                    <Text
                        lineHeight={"20px"}
                        fontWeight={400}
                        fontSize={"13px"}
                        color={"dark.700"}
                        mb={"16px"}
                    >
                        In the next months we will introduce AI generative
                        insights. This new feature will allow you to gain deeper
                        insight into sessions by chatting with our AI.
                    </Text>
                    <Image src={InsightsDemo} mb={"32px"} />
                    <Text
                        lineHeight={"20px"}
                        fontWeight={400}
                        fontSize={"13px"}
                        color={"dark.500"}
                        mb={"28px"}
                    >
                        In the meantime you can{" "}
                        <Link
                            color={"blue.500"}
                            onClick={() => {
                                onClose();
                                openDownloadModal();
                            }}
                        >
                            export
                        </Link>{" "}
                        your data and use existing AI tools within your
                        organization. Contact us if you want to test this
                        feature.
                    </Text>
                </ModalBody>
                <ModalFooter pb={0}>
                    <Flex
                        w={"100%"}
                        justifyContent={"center"}
                        direction={"row"}
                        gap={"16px"}
                    >
                        <Button
                            onClick={onClose}
                            variant="ghost"
                            colorScheme="gray"
                            fontWeight={400}
                        >
                            Close
                        </Button>
                        <Flex justifyItems={"center"} alignItems={"center"}>
                            {showButton ? (
                                <Button
                                    colorScheme={"blue"}
                                    onClick={onSubmit}
                                    color={"white"}
                                    width={"150px"}
                                >
                                    I'd like to test it!
                                </Button>
                            ) : null}
                            {showLoader ? (
                                <Loader
                                    width={120}
                                    value={loaderValue}
                                    onDone={() => onDone()}
                                />
                            ) : null}
                            {showThankYou ? (
                                <Text
                                    animation={`${fadeIn} 0.25s`}
                                    color={"positive.0"}
                                >
                                    Thank you!
                                </Text>
                            ) : null}
                        </Flex>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
