import { Box } from "@chakra-ui/react";
import React, { useState } from "react";
import {
    ExclamationTriangleIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";

interface Props {
    warning?: boolean;
    text: string;
}

const InlineTooltip: React.FC<Props> = ({ warning, text }) => {
    const [state, setState] = useState(false);
    return (
        <Box
            as={"span"}
            display={"inline-block"}
            ml={1}
            verticalAlign={"middle"}
            onMouseEnter={() => setState(true)}
            onMouseLeave={() => setState(false)}
        >
            {warning ? (
                <ExclamationTriangleIcon className="text-gray-500 cursor-pointer w-5 h-5 hover:text-orange-500" />
            ) : (
                <QuestionMarkCircleIcon className="text-gray-500 cursor-pointer w-5 h-5" />
            )}
            <Box
                as={"span"}
                pos={"absolute"}
                zIndex={20}
                mt={2}
                p={2}
                fontSize={"sm"}
                textColor={"gray.700"}
                bg={"white"}
                borderWidth={1}
                borderColor={"gray.300"}
                boxShadow={"lg"}
                display={state ? "block" : "none"}
            >
                {text}
            </Box>
        </Box>
    );
};

export default InlineTooltip;
