import classNames from "classnames";
import Downshift from "downshift";
import ResetSaveButtons from "./ResetSaveButtons";
import { ReactNode } from "react";

export type UserSelectUserType = {
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    emails: {
        email: string;
    }[];
    participants: {
        id: string;
        firstVisitTimeslot?: string | null;
        lastVisitTimeslot?: string | null;
        firstVisitGroup?: string | null;
        lastVisitGroup?: string | null;
    }[];
};

interface Props {
    selectedUserId?: string;
    pending: boolean;
    saved: boolean;
    cohortName?: string;
    users: UserSelectUserType[];
    onChange: (user: UserSelectUserType) => void;
    onSave: (silently?: boolean) => void;
    attendedTags: boolean;
    additionalControls?: ReactNode;
}

const UserSelect = (props: Props) => {
    // Format users for users dropdown
    const formatUserForDropdown = (user?: UserSelectUserType) =>
        user
            ? `${user.firstName || ""} ${user.lastName || ""} (${user.emails
                  .map((email) => email.email)
                  .join(", ")})`
            : "";

    const selectedUser = props.users.find(
        (user) => user.id === props.selectedUserId,
    );

    const userVisited = (user: UserSelectUserType) =>
        !!user.participants?.some((x) => x.firstVisitGroup);
    const userScheduled = (user: UserSelectUserType) =>
        !userVisited(user) && !!user.participants?.length;

    return (
        <div className="mt-2">
            <Downshift
                initialInputValue={
                    selectedUser ? formatUserForDropdown(selectedUser) : ""
                }
                initialSelectedItem={selectedUser}
                itemToString={formatUserForDropdown as any}
                onChange={props.onChange as any}
            >
                {({
                    clearSelection,
                    getInputProps,
                    getItemProps,
                    highlightedIndex,

                    inputValue,
                    isOpen,
                    openMenu,
                    selectedItem,
                }) => {
                    return (
                        <div className="flex flex-row items-end">
                            <div>
                                <div>
                                    <input
                                        {...getInputProps()}
                                        id="user"
                                        type="text"
                                        onFocus={() => {
                                            openMenu();
                                        }}
                                        className={classNames(
                                            "text-sm font-medium rounded-md mr-2 focus:outline outline-1 outline-blue-500",
                                            props.pending ? "bg-gray-100" : "",
                                        )}
                                        style={{
                                            width: "400px",
                                        }}
                                    />
                                </div>
                                {isOpen && (
                                    <ul
                                        className={classNames(
                                            "absolute max-h-48 overflow-auto w-1/2",
                                            "text-sm font-medium rounded-md mr-2",
                                            "bg-white",
                                            "border-slate-500 border-2",
                                            "z-10",
                                        )}
                                        style={{
                                            marginTop: "12px",
                                            minHeight: "60px",
                                            width: "500px",
                                        }}
                                    >
                                        {props.users
                                            .filter(
                                                (user) =>
                                                    !inputValue ||
                                                    formatUserForDropdown(
                                                        user,
                                                    ).includes(inputValue),
                                            )
                                            .map((user, index) => (
                                                <li
                                                    key={`${user.id}${index}`}
                                                    {...getItemProps({
                                                        item: user,
                                                        index,
                                                    })}
                                                    className={classNames(
                                                        highlightedIndex ===
                                                            index
                                                            ? "bg-slate-200"
                                                            : "bg-white",
                                                        selectedItem &&
                                                            selectedItem.id ===
                                                                user.id
                                                            ? "font-bold"
                                                            : "font-normal",
                                                        "cursor-pointer",
                                                        "py-2 pl-4",
                                                    )}
                                                >
                                                    <p
                                                        className={classNames(
                                                            "m-0 p-0",
                                                        )}
                                                    >
                                                        {formatUserForDropdown(
                                                            user,
                                                        )}
                                                        {props.attendedTags &&
                                                            userVisited(
                                                                user,
                                                            ) && (
                                                                <span className="ml-2 text-green-500 rounded-md px-1.5 py-1 bg-green-50">
                                                                    attended
                                                                </span>
                                                            )}
                                                        {props.attendedTags &&
                                                            userScheduled(
                                                                user,
                                                            ) && (
                                                                <span className="ml-2 text-yellow-500 rounded-md px-1.5 py-1 bg-yellow-50">
                                                                    scheduled
                                                                </span>
                                                            )}
                                                    </p>
                                                </li>
                                            ))}
                                    </ul>
                                )}
                            </div>
                            <div className="mx-2" />
                            <ResetSaveButtons
                                disabled={
                                    props.pending || selectedItem === null
                                }
                                onReset={clearSelection}
                                onSave={() => {
                                    props.onSave();
                                    clearSelection();
                                }}
                                saved={props.saved}
                                resetLabel={"Clear"}
                                saveLabel="Add"
                                savedLabel="Added!"
                                additionalControls={props.additionalControls}
                            />
                        </div>
                    );
                }}
            </Downshift>
        </div>
    );
};

export default UserSelect;
