import { useEffect, useState } from "react";
import classNames from "classnames";
import { useGlobalContext } from "../../context";
import { ParamsProps, withParams } from "../../utils";
import Form from "../../components/common/Form";
import Domains from "../Domains";
import Loading from "../../components/common/Loading";
import Toggle from "../../components/common/Toggle";
import ErrorBox from "../../components/common/ErrorBox";
import AuditLink from "../AuditLink";
import Flags from "../../components/factories/Flags";
import Tooltip from "../../components/factories/Tooltip";
import { useTRPC } from "../../hooks/useTRPC";
import { useIsSuperAdmin } from "../../hooks/useUserData";
import { Button, Flex, Image, Text } from "@chakra-ui/react";
import axios from "axios";
import ErrorModal from "../../components/common/ErrorModal";
import { XCircleIcon } from "@heroicons/react/24/outline";
import { RescheduleCustomization } from "./RescheduleCustomization";
import EventsTable from "../../components/factories/EventsTable";
import { OrgPermissions } from "./permissions/OrgPermissions";

const Org = (props: ParamsProps) => {
    const context = useGlobalContext();

    const trpc = useTRPC();
    const { data: org, refetch: refetchOrgData } = trpc.org.getOrgById.useQuery(
        props.params.organizationId || "",
    );

    const { data: logoUploadUrl, refetch: refetchLogoUploadUrl } =
        trpc.org.getLogoUploadUrl.useQuery({
            organizationId: props.params.organizationId || "",
        });

    const { mutateAsync: saveLogoUrl } = trpc.org.saveLogoUrl.useMutation();

    const [file, setFile] = useState<File>();

    const { data: allFlags } = trpc.flags.getOrgFlags.useQuery();
    const { data: timezones } = trpc.info.getTimezones.useQuery();
    const [errorText, setErrorText] = useState("");
    const [errorIndex, setErrorIndex] = useState(0);

    const isSuperAdmin = useIsSuperAdmin();

    const [contactName, setContactName] = useState("");
    const [editingContactName, setEditingContactName] = useState(false);
    const [editingName, setEditingName] = useState(false);
    const [editingTimezone, setEditingTimezone] = useState(false);
    const [error, setError] = useState("");
    const [name, setName] = useState(org?.name as unknown as string);
    const [pending, setPending] = useState(false);
    const [timezone, setTimezone] = useState(
        org?.timezone as unknown as string,
    );
    const { mutateAsync: updateEventById } =
        trpc.events.updateEventById.useMutation();

    const [showEventsTable, setShowEventsTable] = useState(false);

    const { mutateAsync: updateOrgMutation } = trpc.org.updateOrg.useMutation();
    const { mutateAsync: setRescheduleData } =
        trpc.org.setRescheduleData.useMutation();

    const cancel = () => {
        setEditingContactName(false);
        setEditingTimezone(false);
        setEditingName(false);
        setPending(false);
    };

    useEffect(() => {
        if (org) {
            context.setOrganizationId(org.id);
            context.setOrganizationName(org.name);
            context.setOrganizationTimezone(
                org.timezone ? org.timezone.name : "UTC",
            );
            setName(org.name);
        }
    }, [context, org]);

    if (!isSuperAdmin) {
        return <Text>Under Construction</Text>;
    }

    const updateOrg = async (data: any) => {
        setPending(true);
        try {
            await updateOrgMutation({
                ...data,
                id: props.params.organizationId,
            });
            await refetchOrgData();
        } catch (error) {
            setError((error as any).message);
        } finally {
            cancel();
        }
    };

    if (!org || !allFlags || !timezones) {
        return <Loading error={error} />;
    }

    return (
        <div>
            <ErrorModal error={errorText} key={errorIndex} />
            <div className="mt-3 flex items-stretch">
                <div>
                    <h4 className="text-base text-gray-500">Account Name</h4>
                    <div className="flex items-center mt-3">
                        {editingName ? (
                            <Form
                                cancel={cancel}
                                submit={() => {
                                    updateOrg({
                                        name: name?.trim(),
                                    });
                                }}
                                change={(event) =>
                                    setName(event.currentTarget.value)
                                }
                                disabled={pending}
                                error={error}
                                label="Name"
                                placeholder={"Organization"}
                                value={name}
                            />
                        ) : (
                            <button
                                className={classNames(
                                    "text-lg font-medium text-gray-900 cursor-pointer",
                                )}
                                onClick={() => {
                                    cancel();
                                    setEditingName(true);
                                }}
                                disabled={pending}
                            >
                                {org.name}
                            </button>
                        )}
                    </div>
                </div>
                <div className="mx-4" />
                <div className="ml-12 pl-4 border-slate-300 border-l">
                    <h4 className="text-base text-gray-500">Status</h4>
                    <div className="mt-3">
                        <Toggle
                            disabled={pending}
                            onChange={(active: boolean) => {
                                updateOrg({
                                    active,
                                });
                            }}
                            label={"Active"}
                            value={org.active}
                        />
                    </div>
                </div>
                <div className="mx-4" />
                <AuditLink modelId={org.id} />
            </div>
            <div className="flex mt-12">
                <Flags
                    disabled={pending}
                    flags={org.featureFlagOverrides}
                    allFlags={allFlags?.filter((flag) => !flag.retiredOn)}
                    onChange={(id: string, enabled: boolean) => {
                        updateOrg({
                            flag: {
                                id,
                                enabled,
                                clear: !enabled,
                            },
                        });
                    }}
                />
            </div>
            <Flex flexDir="column" mt="50px">
                <Flex>
                    <Text color="gray.500" fontWeight="normal" mb={"12px"}>
                        Rescheduling Options
                    </Text>
                    <Tooltip text="Define how to handle rescheduling. Rescheduling link appears at the bottom of emails & calendar invites." />
                </Flex>
                <RescheduleCustomization
                    serverRescheduleText={org.rescheduleText || ""}
                    serverRescheduleType={org.rescheduleType}
                    saveRescheduleData={async (
                        rescheduleType,
                        rescheduleText,
                    ) => {
                        await setRescheduleData({
                            organizationId: props.params.organizationId!,
                            rescheduleType,
                            rescheduleText,
                        });
                        await refetchOrgData();
                    }}
                />
            </Flex>

            <h4 className="text-base text-gray-500 mt-12">Timezone</h4>
            <div className="flex items-center mt-3">
                {editingTimezone ? (
                    timezones && (
                        <Form
                            cancel={cancel}
                            submit={() => {
                                updateOrg({
                                    timezone,
                                });
                                cancel();
                            }}
                            disabled={pending}
                            error={error}
                            label=""
                            customInput={
                                <select
                                    id="timezone"
                                    name="timezone"
                                    className={classNames(
                                        "rounded-md mr-2 text-sm font-medium",
                                        "disabled:bg-slate-100",
                                    )}
                                    defaultValue={org.timezone?.name}
                                    onChange={(event) =>
                                        setTimezone(event.currentTarget.value)
                                    }
                                    disabled={pending}
                                >
                                    <option key="None" value="">
                                        None
                                    </option>
                                    {timezones.map(
                                        (timezone: { name: string }) => (
                                            <option key={timezone.name}>
                                                {timezone.name}
                                            </option>
                                        ),
                                    )}
                                </select>
                            }
                        />
                    )
                ) : (
                    <button
                        className={
                            "text-gray-900 cursor-pointer text-sm font-medium "
                        }
                        onClick={() => {
                            cancel();
                            setEditingTimezone(true);
                        }}
                        disabled={pending}
                    >
                        {org.timezone
                            ? org.timezone.name || `${org.timezone}`
                            : "None"}
                    </button>
                )}
            </div>
            <div className="flex items-center mt-9">
                <div>
                    <h4 className="text-base text-gray-500">Contact Name</h4>
                    <div className="flex items-center mt-3">
                        {editingContactName ? (
                            <Form
                                cancel={cancel}
                                submit={() => {
                                    updateOrg({
                                        contactName: contactName?.trim(),
                                    });
                                }}
                                change={(event) =>
                                    setContactName(event.currentTarget.value)
                                }
                                disabled={pending}
                                error={error}
                                label="Contact Name"
                                placeholder={contactName}
                                value={contactName}
                            />
                        ) : (
                            <button
                                className={classNames(
                                    "text-sm font-medium text-gray-900 cursor-pointer",
                                )}
                                onClick={() => {
                                    cancel();
                                    setEditingContactName(true);
                                }}
                                disabled={pending}
                            >
                                {org.contactName || (
                                    <div className="flex">
                                        None
                                        <Tooltip text="Used in onboarding emails to participants." />
                                    </div>
                                )}
                            </button>
                        )}
                    </div>
                </div>
            </div>

            <Domains />

            <ErrorBox error={error} />
            {isSuperAdmin && (
                <Flex flexDir="column" marginTop="9">
                    <Flex>
                        <h4 className="text-base text-gray-500">
                            Custom Branding Logo
                        </h4>
                        <Tooltip text="Prefer wide over square. Prefer svg over png. Prefer transparant background. Used in app at 30px height, but higher resolution is better. Will appear side-by-side with sparkwise logo." />
                    </Flex>
                    {org.cobrandingLogoUrl ? (
                        <Flex>
                            <Image h="30px" src={org.cobrandingLogoUrl} />
                            <button
                                className={"ml-2"}
                                type="button"
                                onClick={async () => {
                                    try {
                                        await saveLogoUrl({
                                            organizationId:
                                                props.params.organizationId!,
                                            url: null,
                                        });
                                        await refetchOrgData();
                                        await refetchLogoUploadUrl();
                                    } catch (error) {
                                        setErrorText((error as any).message);
                                        setErrorIndex(errorIndex + 1);
                                    }
                                }}
                            >
                                <XCircleIcon className="w-5 h-5 text-slate-500" />
                            </button>
                        </Flex>
                    ) : (
                        "None"
                    )}
                    <form
                        className="mt-3 border-dashed border-2 border-gray-300 rounded-md p-2"
                        action={logoUploadUrl}
                        method="post"
                        encType="multipart/form-data"
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (!file) {
                                return;
                            }
                            const formData = new FormData();
                            formData.append("file", file);
                            const config = {
                                headers: {
                                    "Content-Type": "multipart/form-data",
                                },
                            };
                            axios
                                .post(logoUploadUrl, formData, config)
                                .then(async (response) => {
                                    try {
                                        await saveLogoUrl({
                                            organizationId:
                                                props.params.organizationId!,
                                            url: response.data.result
                                                .variants[1],
                                        });
                                        await refetchOrgData();
                                        await refetchLogoUploadUrl();
                                    } catch (error) {
                                        setErrorText((error as any).message);
                                        setErrorIndex(errorIndex + 1);
                                    }
                                })
                                .catch((error) => {
                                    setErrorText((error as any).message);
                                    setErrorIndex(errorIndex + 1);
                                });
                        }}
                    >
                        <input
                            type="file"
                            id="myFile"
                            name="file"
                            onChange={(e) => setFile(e.target.files?.[0])}
                        />
                        <Button type="submit">Upload</Button>
                    </form>
                </Flex>
            )}
            <Flex marginTop="20px" flexDir="column">
                <h4 className="text-base text-gray-500">
                    Organization Event Data
                </h4>
                <Button
                    colorScheme="blue"
                    size="sm"
                    marginTop="10px"
                    width="200px"
                    onClick={() => setShowEventsTable(!showEventsTable)}
                >
                    Show/hide events table
                </Button>
                {showEventsTable && (
                    <EventsTable
                        events={org.events.map((event: any) => {
                            return {
                                ...event,
                                sendImmediately: (
                                    <Button
                                        colorScheme="blue"
                                        onClick={async () => {
                                            try {
                                                await updateEventById({
                                                    id: event.id,
                                                    sendImmediately: true,
                                                });
                                                await refetchOrgData();
                                            } catch (error) {
                                                setError(
                                                    (error as any).message,
                                                );
                                            }
                                        }}
                                    >
                                        Send Immediately
                                    </Button>
                                ),
                            };
                        })}
                    />
                )}

                {props.params.organizationId ? (
                    <OrgPermissions
                        organizationId={props.params.organizationId}
                    />
                ) : null}
            </Flex>
        </div>
    );
};

export default withParams(Org);
