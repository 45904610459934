import { RescheduleOptions } from "@prisma/client";

import {
    Button,
    Flex,
    Input,
    InputGroup,
    InputLeftAddon,
    Radio,
    Stack,
    Text,
    RadioGroup,
    InputRightAddon,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { PencilIcon } from "@heroicons/react/24/outline";

export const RescheduleCustomization = ({
    serverRescheduleText,
    serverRescheduleType,
    saveRescheduleData,
}: {
    serverRescheduleText: string;
    serverRescheduleType: RescheduleOptions;
    saveRescheduleData: (
        rescheduleType: RescheduleOptions,
        rescheduleText: string,
    ) => void;
}) => {
    const [rescheduleType, setRescheduleType] = useState<string>(
        RescheduleOptions.DEFAULT,
    );
    const [rescheduleText, setRescheduleText] = useState("");
    const [editting, setEditting] = useState(false);

    useEffect(() => {
        setRescheduleType(serverRescheduleType);
        setRescheduleText(serverRescheduleText);
    }, [serverRescheduleText, serverRescheduleType]);

    if (!editting) {
        let content = <Text>{rescheduleType}</Text>;
        if (serverRescheduleType === RescheduleOptions.DEFAULT) {
            content = (
                <Text fontWeight={"500"} fontSize="14px">
                    Sparkwise Default Flow
                </Text>
            );
        } else if (serverRescheduleType === RescheduleOptions.NO_RESCHEDULE) {
            content = (
                <Text fontSize="14px" fontWeight="500">
                    Rescheduling Disabled
                </Text>
            );
        } else if (serverRescheduleType === RescheduleOptions.CUSTOM_TEXT) {
            content = (
                <Flex flexDir="row" alignItems="center">
                    <Text width="105px" fontWeight="500" fontSize="14px">
                        Custom Flow:
                    </Text>
                    <Text
                        display={
                            rescheduleType === RescheduleOptions.CUSTOM_TEXT
                                ? "flex"
                                : "none"
                        }
                        fontSize="14px"
                        color="gray.600"
                    >{`"Can't make it? ${rescheduleText}"`}</Text>
                </Flex>
            );
        }

        return (
            <Flex>
                {content}
                <Flex onClick={() => setEditting(true)} mr="5px">
                    <PencilIcon className="w-4 ml-2 t-4 text-slate-500" />
                </Flex>
            </Flex>
        );
    }
    return (
        <Flex
            flexDir="column"
            width="fit-content"
            p="20px"
            borderRadius=".5rem"
            bg="gray.50"
        >
            <RadioGroup onChange={setRescheduleType} value={rescheduleType}>
                <Stack direction="column">
                    <Radio value={RescheduleOptions.DEFAULT}>
                        Sparkwise Default Flow
                    </Radio>
                    <Radio value={RescheduleOptions.NO_RESCHEDULE}>
                        No Rescheduling
                    </Radio>
                    <Radio value={RescheduleOptions.CUSTOM_TEXT}>
                        Custom Flow
                    </Radio>
                </Stack>
            </RadioGroup>
            <InputGroup
                visibility={
                    rescheduleType === RescheduleOptions.CUSTOM_TEXT
                        ? undefined
                        : "hidden"
                }
            >
                <InputLeftAddon>Can't make it?</InputLeftAddon>
                <Input
                    placeholder="Email ... to reschedule"
                    value={rescheduleText || ""}
                    onChange={(e) => setRescheduleText(e.target.value)}
                    w="250px"
                />
            </InputGroup>
            <Flex marginTop="10px">
                <Button
                    size="sm"
                    width="80px"
                    mr="15px"
                    onClick={async () => {
                        setRescheduleText(serverRescheduleText);
                        setRescheduleType(serverRescheduleType);
                        setEditting(false);
                    }}
                >
                    Discard
                </Button>
                <Button
                    size="sm"
                    width="60px"
                    colorScheme="blue"
                    onClick={async () => {
                        setEditting(false);
                        await saveRescheduleData(
                            rescheduleType as RescheduleOptions,
                            rescheduleText,
                        );
                    }}
                >
                    Save
                </Button>
            </Flex>
        </Flex>
    );
};
