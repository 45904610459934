import {
    Flex,
    Select,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
} from "@chakra-ui/react";
import Button from "../components/common/Button";
import Loading from "../components/common/Loading";
import SessionsByTime from "../components/factories/SessionsByTime";
import { trpc } from "../hooks/useTRPC";
import { ParamsProps, withParams } from "../utils";
import useShowHide from "../hooks/useShowHide";
import ImmersionVideoUploadForm from "./ImmersionVideoUploadForm";
import Toggle from "../components/common/Toggle";
import { useState } from "react";
import { XCircleIcon } from "@heroicons/react/24/outline";

const Immersion = (props: ParamsProps) => {
    const utils = trpc.useContext();
    const { data: immersion, error } =
        trpc.immersions.getImmersionById.useQuery({
            immersionId: props.params.immersionId! || "-1",
        });

    const { data: orgs } = trpc.orgs.getOrgsByPage.useQuery({ active: true });

    const [selectedOrg, setSelectedOrg] = useState("");

    const { mutateAsync: deleteImmersionOrg } =
        trpc.immersions.deleteImmersionOrganization.useMutation({
            onSuccess: (immersion) => {
                utils.immersions.getImmersionById.setData(
                    {
                        immersionId: immersion.id,
                    },
                    immersion,
                );
            },
        });
    const { mutateAsync: addImmersionOrg } =
        trpc.immersions.addImmersionOrganization.useMutation({
            onSuccess: (immersion) => {
                utils.immersions.getImmersionById.setData(
                    {
                        immersionId: immersion.id,
                    },
                    immersion,
                );
            },
        });

    const { mutateAsync: setImmersionCustom } =
        trpc.immersions.setImmersionCustom.useMutation({
            onSuccess: (immersion) => {
                utils.immersions.getImmersionById.setData(
                    {
                        immersionId: immersion.id,
                    },
                    immersion,
                );
            },
        });

    const {
        show: showUploader,
        setHidden: setUploaderHidden,
        ShowHideButton,
    } = useShowHide();

    if (!immersion) {
        return <Loading error={error?.message} />;
    }

    return (
        <div>
            <h4 className="text-lg font-medium text-slate-800 mb-2">
                {immersion.name}
            </h4>
            {showUploader && (
                <Button onClick={setUploaderHidden} label="Hide Uploader" />
            )}
            <ShowHideButton
                show={showUploader}
                label="Upload new video"
                component={
                    <ImmersionVideoUploadForm immersionId={immersion.id} />
                }
            />

            <Flex mt="30px">
                <h4 className="text-md font-medium text-slate-600 mr-2">
                    Custom Module
                </h4>
                <Toggle
                    label=""
                    disabled={false}
                    onChange={() => {
                        setImmersionCustom({
                            immersionId: props.params.immersionId || "",
                            custom: !immersion.custom,
                        });
                    }}
                    value={immersion.custom}
                />
            </Flex>
            {immersion.custom && (
                <Flex flexDir="column">
                    <Flex marginTop="10px" marginBottom="4px">
                        <Select
                            h="38px"
                            w="200px"
                            marginRight="10px"
                            placeholder="Organization"
                            value={selectedOrg}
                            onChange={(e) => {
                                setSelectedOrg(e.target.value);
                            }}
                        >
                            {orgs?.map((org) => {
                                return (
                                    <option key={org.id} value={org.id}>
                                        {org.name}
                                    </option>
                                );
                            })}
                        </Select>
                        <Button
                            onClick={() => {
                                addImmersionOrg({
                                    organizationId: selectedOrg,
                                    immersionId: props.params.immersionId || "",
                                });
                            }}
                            label="Link Org"
                        />
                    </Flex>
                    {immersion.organizations.map((org) => {
                        return (
                            <Flex mt="3px" key={org.id}>
                                <button
                                    className={"ml-2"}
                                    type="button"
                                    onClick={async () => {
                                        deleteImmersionOrg({
                                            immersionId:
                                                props.params.immersionId || "",
                                            organizationId: org.id,
                                        });
                                    }}
                                >
                                    <XCircleIcon className="w-5 h-5 text-slate-500" />
                                </button>
                                <Text>{org.name}</Text>
                            </Flex>
                        );
                    })}
                </Flex>
            )}

            <Tabs variant="unstyled">
                <TabList mt="40px">
                    <Tab
                        color="dark.500"
                        borderRadius="8px"
                        fontWeight="600"
                        _selected={{ bgColor: "gray.50", color: "blue.600" }}
                    >
                        Future Sessions
                    </Tab>
                    <Tab
                        color="dark.500"
                        borderRadius="8px"
                        fontWeight="600"
                        _selected={{ bgColor: "gray.50", color: "blue.600" }}
                    >
                        Past Sessions
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <SessionsByTime
                            immersionId={immersion.id}
                            pastOrFuture="future"
                        />
                    </TabPanel>
                    <TabPanel>
                        <SessionsByTime
                            immersionId={immersion.id}
                            pastOrFuture="past"
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default withParams(Immersion);
