import { Button as ChakraButton } from "@chakra-ui/react";
import {
    ChartBarIcon,
    FunnelIcon,
    PencilIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, {
    PropsWithChildren,
    ReactElement,
    useMemo,
    useRef,
    useState,
} from "react";
import { NavLink } from "react-router-dom";
import { useGlobalContext } from "../../context";
import MultiselectPopup from "./MultiselectPopup";
import SearchPopup from "./SearchPopup";

interface ContextualAction<T = any> {
    top: number;
    left: number;
    height: number;
    row: T;
}

export interface TableData {
    [page: string]: {
        [column: string]:
            | any
            | {
                  value: any;
                  link: string;
              };
        link?: string;
    }[];
}
interface Props {
    activeField?: string;
    activeFunction?: (row: any) => void;
    data: TableData;
    columns: (
        | [string, string]
        | [
              string,
              string,
              (field: any) => string | Date | JSX.Element,
              boolean?,
          ]
    )[];
    disableLinks?: boolean;
    hidePages?: boolean;
    initialSortDirection?: "asc" | "desc";
    initialSortField?: string;
    onPageChange: (page: number) => void;
    page: number;
    searchable?: string[];
    multiselectable?: string[];
    sortable: boolean;

    /**
     * Adds an element to the right side of the table that should be rendered
     * when a row is being hovered.
     */
    contextualActionElement?: (row: any) => ReactElement;
}

const Table: React.FC<Props> = (props) => {
    const context = useGlobalContext();
    const [sortDirection, setSortDirection] = useState<"asc" | "desc">(
        props.initialSortDirection || "asc",
    );
    const [sortField, setSortField] = useState(
        props.initialSortField || undefined,
    );

    const [filterMap, setFilterMap] = useState<
        Record<string, { value: string[]; active: boolean }>
    >({});

    const multiselectValues: Record<string, string[]> = useMemo(() => {
        const data = props.data[props.page] ?? [];
        return (
            props.multiselectable?.reduce(
                (acc, column) => {
                    acc[column] = data.reduce((values, row) => {
                        if (!values.includes(row[column])) {
                            values.push(row[column]);
                        }
                        return values;
                    }, []) as string[];
                    return acc;
                },
                {} as Record<string, string[]>,
            ) || {}
        );
    }, [props]);

    const handleActiveSearchChange = (field: string, value: string) => {
        setFilterMap((prev) => ({
            ...prev,
            [field]: {
                value: value.length ? [value] : [],
                active: prev[field].active,
            },
        }));
    };

    const handleSearchSubmit = (field: string) => {
        setFilterMap({
            ...filterMap,
            [field]: {
                ...filterMap[field],
                active: false,
            },
        });
    };

    const handleMultiselectSubmit = (field: string, values: string[]) => {
        setFilterMap((prev) => ({
            ...prev,
            [field]: {
                value: values,
                active: false,
            },
        }));
    };

    const contentByIndex = (row: any) => {
        return props.columns.map(([field, index, formatter, customElement]) => {
            const unwrappedValue =
                row[field] && row[field].value !== undefined
                    ? row[field].value
                    : row[field];
            const formatted = formatField(unwrappedValue, formatter);
            const value =
                formatted instanceof Date
                    ? DateTime.fromJSDate(formatted)
                          .setZone(context.timezoneName)
                          .toLocaleString(DateTime.DATETIME_SHORT)
                    : formatted;
            const link =
                row.link && !props.disableLinks
                    ? row.link
                    : (row[field] &&
                          typeof row[field].link === "string" &&
                          row[field].link) ||
                      null;
            return (
                <td
                    className={classNames(
                        !customElement && "py-4 pl-4 pr-3 text-sm",
                        "text-gray-600 break-all whitespace-pre-wrap",
                    )}
                    key={field + index}
                >
                    {link && !React.isValidElement(value) ? (
                        <NavLink
                            className="flex w-full text-gray-600 hover:underline underline-offset-2"
                            to={link}
                        >
                            {value}
                        </NavLink>
                    ) : (
                        value
                    )}
                </td>
            );
        });
    };

    const formatField = (
        field: any,
        formatter?: (field: any) => string | Date | JSX.Element,
    ) => {
        if (field === null || field === undefined) {
            return "";
        }
        if (formatter) {
            return formatter(field);
        }
        if (
            typeof field === "string" ||
            typeof field === "number" ||
            React.isValidElement(field)
        ) {
            return field;
        }
        if (
            Array.isArray(field) &&
            field.length &&
            React.isValidElement(field[0])
        ) {
            return <>{field}</>;
        }
        let { link, ...fieldWithoutLink } = field;
        const allKeys = new Set<string>();
        JSON.stringify(fieldWithoutLink, (key, value) => {
            allKeys.add(key);
            return value;
        });
        return JSON.stringify(fieldWithoutLink, Array.from(allKeys).sort(), 2);
    };

    const sortFunction = (a: any, b: any) => {
        const column =
            props.columns.find(([field]) => field === sortField) || [];
        if (!sortField) {
            return 0;
        }
        const formattedA = formatField(a[sortField], column[2]);
        const formattedB = formatField(b[sortField], column[2]);
        if (typeof formattedA === "string" && typeof formattedB === "string") {
            return (
                formattedA.localeCompare(formattedB) *
                (sortDirection === "asc" ? 1 : -1)
            );
        }
        if (formattedA < formattedB) {
            return sortDirection === "asc" ? -1 : 1;
        }
        if (formattedA > formattedB) {
            return sortDirection === "asc" ? 1 : -1;
        }
        return 0;
    };

    const toggleSortDirection = (field: string) => {
        const newSortDirection =
            sortField === field && sortDirection === "asc" ? "desc" : "asc";
        setSortDirection(newSortDirection);
        setSortField(field);
    };

    const headers = props.columns.map(([field, label]) => {
        const searchable = props.searchable?.includes(field);
        const searchOpen = filterMap[field]?.active;
        const searchActive = filterMap[field]?.value?.length;

        const multiselectable = props.multiselectable?.includes(field);
        const multiselectOpen = filterMap[field]?.active;
        const multiselectActive = filterMap[field]?.value?.length;

        const isFirstColumn = props.columns?.[0]?.[0] === field;
        const isFilterable = searchable || multiselectable;

        return (
            <th
                scope="col"
                key={label}
                className={classNames(
                    "py-3.5 pl-4 pr-3 text-left",
                    "text-sm font-semibold text-gray-800",
                    "group",
                )}
            >
                <div
                    className={classNames(
                        "flex items-center justify-between w-full",
                        // do not shrink column on no results so that filter popup stays in view
                        isFirstColumn && isFilterable && "min-w-[187px]",
                    )}
                >
                    <span className="text-left">{label}</span>
                    <div className="flex items-center relative">
                        {multiselectable && (
                            <FunnelIcon
                                className={classNames(
                                    "group-hover:visible group-focus:visible",
                                    "ml-1 flex-shrink-0 h-4 w-4 inline rounded text-gray-600",
                                    "hover:text-blue-700",
                                    (multiselectActive &&
                                        "visible text-blue-700") ||
                                        "invisible",
                                )}
                                aria-hidden="true"
                                onClick={() => {
                                    setFilterMap((prev) => ({
                                        ...prev,
                                        [field]: {
                                            value: prev[field]?.value || [],
                                            active: true,
                                        },
                                    }));
                                }}
                            />
                        )}
                        {searchable && (
                            <PencilIcon
                                className={classNames(
                                    "group-hover:visible group-focus:visible",
                                    "ml-1 flex-shrink-0 h-4 w-4 inline rounded text-gray-600",
                                    "hover:text-blue-700",
                                    (searchActive && "visible text-blue-700") ||
                                        "invisible",
                                )}
                                aria-hidden="true"
                                onClick={() => {
                                    setFilterMap((prev) => ({
                                        ...prev,
                                        [field]: {
                                            value: prev[field]?.value || [],
                                            active: true,
                                        },
                                    }));
                                }}
                            />
                        )}
                        {multiselectable && multiselectOpen && (
                            <MultiselectPopup
                                onChange={(e) => {
                                    handleActiveSearchChange(
                                        field,
                                        e.target.value,
                                    );
                                }}
                                onSubmit={(selectedValues: string[]) => {
                                    handleMultiselectSubmit(
                                        field,
                                        selectedValues,
                                    );
                                }}
                                values={multiselectValues[field]}
                                filterMap={filterMap}
                                filterField={field}
                            />
                        )}
                        {searchable && searchOpen && (
                            <SearchPopup
                                onChange={(e) => {
                                    handleActiveSearchChange(
                                        field,
                                        e.target.value,
                                    );
                                }}
                                onSubmit={(e) => {
                                    e.preventDefault();
                                    handleSearchSubmit(field);
                                }}
                                onBlur={() => {
                                    handleSearchSubmit(field);
                                }}
                                filterMap={filterMap}
                                filterField={field}
                            />
                        )}

                        {props.sortable && (
                            <ChartBarIcon
                                className={classNames(
                                    "group-hover:visible group-focus:visible",
                                    "ml-1 flex-shrink-0 h-4 w-4 inline rounded text-gray-600",
                                    "hover:text-blue-700",
                                    (sortField === field &&
                                        "visible text-blue-700") ||
                                        "invisible",
                                    sortField === field &&
                                        sortDirection === "desc" &&
                                        "-scale-x-100",
                                )}
                                aria-hidden="true"
                                onClick={() => toggleSortDirection(field)}
                            />
                        )}
                    </div>
                </div>
            </th>
        );
    });

    const page = props.page;

    const data = (props.data[page] ?? [])
        .filter((row) => {
            return (
                Object.keys(filterMap).length === 0 ||
                Object.entries(row).every(([key, value]) => {
                    const filterValues = filterMap[key]?.value;
                    // ensure 'value' is always a string for filtering
                    if (!(typeof value === "string")) {
                        try {
                            value = JSON.stringify(value, null, 1);
                        } catch {
                            value = "";
                        }
                    }
                    return (
                        !(key in filterMap) ||
                        !filterMap[key]?.value?.length ||
                        filterValues.some((filterValue) =>
                            value
                                ?.toLowerCase()
                                .includes(filterValue?.toLowerCase()),
                        )
                    );
                })
            );
        })
        .sort(sortFunction);

    const [contextualAction, setContextualAction] =
        useState<ContextualAction | null>(null);
    const contextualActionHoverTimer = useRef<NodeJS.Timeout | null>(null);

    const cancelHoverTimer = () => {
        if (contextualActionHoverTimer.current) {
            clearTimeout(contextualActionHoverTimer.current);
            contextualActionHoverTimer.current = null;
        }
    };

    const tableBodyRef = useRef<HTMLTableSectionElement>(null);

    const rows = useMemo(
        () =>
            data.map((row, index) => (
                <TableRow
                    key={index}
                    row={row}
                    index={index}
                    activeField={props.activeField}
                    activeFunction={props.activeFunction}
                    onMouseEnter={(rect: DOMRect) => {
                        const tableBodyRect =
                            tableBodyRef.current?.getBoundingClientRect();
                        if (!tableBodyRect) {
                            return;
                        }
                        cancelHoverTimer();
                        const topPosition = rect.y - tableBodyRect.y;
                        setContextualAction({
                            // Place the contextual action at the top of each row
                            top: topPosition,
                            // Place the contextual action to the right of the table
                            left: tableBodyRect.width,
                            // Set the height of the contextual action to the height of the row
                            height: rect.height,
                            row,
                        });
                    }}
                    onMouseLeave={() => {
                        contextualActionHoverTimer.current = setTimeout(() => {
                            setContextualAction(null);
                        }, 1000);
                        return;
                    }}
                >
                    {contentByIndex(row)}
                </TableRow>
            )),
        [props.page, sortFunction, filterMap],
    );

    if (!data) {
        return null;
    }

    return (
        <div className="mt-8">
            {!props.hidePages && (
                <div className="flex items-center">
                    <ChakraButton
                        mr="8px"
                        size="sm"
                        backgroundColor="white"
                        border="1px solid black"
                        color="black"
                        isDisabled={props.page === 0}
                        _hover={{
                            backgroundColor:
                                props.page === 0 ? "white" : "#E0E0E0",
                        }}
                        onClick={() => props.onPageChange(props.page - 1)}
                    >
                        Previous
                    </ChakraButton>
                    <ChakraButton
                        size="sm"
                        backgroundColor="white"
                        border="1px solid black"
                        color="black"
                        isDisabled={
                            props.data[props.page + 1] &&
                            props.data[props.page + 1].length === 0
                        }
                        _hover={{
                            backgroundColor:
                                props.data[props.page + 1] &&
                                props.data[props.page + 1].length === 0
                                    ? "white"
                                    : "#E0E0E0",
                        }}
                        onClick={() => props.onPageChange(props.page + 1)}
                    >
                        Next
                    </ChakraButton>
                </div>
            )}
            <div
                className="my-4 w-fit ring-1 ring-slate-200"
                style={{ position: "relative" }}
            >
                {props.contextualActionElement ? (
                    <div
                        style={{
                            position: "absolute",
                            top: contextualAction?.top || 0,
                            left: contextualAction?.left || 0,
                            height: contextualAction?.height,
                            display: contextualAction ? "block" : "none",
                        }}
                        onMouseEnter={() => {
                            cancelHoverTimer();
                        }}
                        onMouseLeave={() => {
                            cancelHoverTimer();
                            contextualActionHoverTimer.current = setTimeout(
                                () => setContextualAction(null),
                                1000,
                            );
                        }}
                    >
                        {contextualAction
                            ? props.contextualActionElement(
                                  contextualAction.row,
                              )
                            : null}
                    </div>
                ) : null}
                <table className="border-collapse divide-y table-auto divide-slate-200">
                    <thead ref={tableBodyRef} className="bg-slate-50">
                        <tr className="divide-x divide-slate-200">{headers}</tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-slate-200">
                        {rows}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

const TableRow: React.FC<
    PropsWithChildren<{
        activeFunction?: (row: any) => void;
        activeField?: string;
        row: any;
        index: number;
        onMouseEnter: (rect: DOMRect) => void;
        onMouseLeave: () => void;
    }>
> = ({
    children,
    activeFunction,
    activeField,
    row,
    index,
    onMouseEnter,
    onMouseLeave,
}) => {
    const active = activeFunction
        ? activeFunction(row)
        : activeField === undefined || (activeField && row[activeField]);
    return (
        <tr
            key={index}
            className={classNames(
                "divide-x divide-slate-200",
                active ? undefined : "bg-gray-50",
            )}
            onMouseEnter={(event) => {
                const rect = event.currentTarget.getBoundingClientRect();
                onMouseEnter(rect);
            }}
            onMouseLeave={onMouseLeave}
        >
            {children}
        </tr>
    );
};

export default Table;
