import { ArrowPathIcon } from "@heroicons/react/24/outline";

// Full-screen loading page
const LoadingPage = () => (
    <div className="flex flex-col items-center justify-center h-screen mx-auto text-gray-100 bg-slate-800">
        <ArrowPathIcon className="w-6 h-6 animate-spin" />
        <div className="mt-6">Sparkwise</div>
    </div>
);

export default LoadingPage;
