import ErrorCard from "./ErrorCard";
import React from "react";

const ErrorModal = ({ error }: { error: string | undefined }) => {
    const [open, setOpen] = React.useState(true);

    return error && open ? (
        <div
            className="relative z-10"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                    <ErrorCard
                        title="Error"
                        subtitle={error}
                        cta={
                            <div className="mt-5 sm:mt-6">
                                <button
                                    type="button"
                                    className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-700 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:text-sm"
                                    onClick={() => setOpen(false)}
                                >
                                    Close
                                </button>
                            </div>
                        }
                    />
                </div>
            </div>
        </div>
    ) : null;
};

export default ErrorModal;
