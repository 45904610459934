import { Box, Text, Button, Flex } from "@chakra-ui/react";
import { InsightsSelect } from "./InsightsSelect";
import { BookOpenIcon } from "@heroicons/react/24/outline";
import { useInsights } from "./InsightsContext";
import { useInsightsImmersions } from "../../hooks/useInsightsImmersions";
import { useState } from "react";

export const InsightsIntroScreen = () => {
    const immersions = useInsightsImmersions();
    const { setModuleId } = useInsights();
    const [selectedModule, setSelectedModule] = useState<string | null>(null);
    return (
        <Box flexGrow={1} px="40px">
            <Box
                borderRadius={"20px"}
                justifyItems={"center"}
                paddingTop="80px"
                mt="100px"
            >
                <Box mb={"60px"} justifyItems={"center"}>
                    <Text
                        color={"#202020"}
                        fontSize={"80px"}
                        fontWeight={400}
                        lineHeight={"80px"}
                        fontFamily={"Proxima Nova"}
                        bg={
                            "linear-gradient(79deg, #6D54E8 19.7%, #1A6CE5 81.59%)"
                        }
                        backgroundClip={"text"}
                        mb={"20px"}
                    >
                        insights
                    </Text>
                    <Text
                        fontSize={"14px"}
                        fontWeight={400}
                        color="dark.500"
                        lineHeight={"20px"}
                        maxWidth={"410px"}
                        textAlign={"center"}
                    >
                        Gain deeper insight into Sparkwise sessions by analyzing
                        discussion transcripts and participant resources with AI
                        tools.
                    </Text>
                </Box>
                <Flex direction={"row"} gap={"12px"}>
                    <InsightsSelect
                        searchable
                        itemToString={(_) => _?.item.tactic || ""}
                        items={(immersions || []).map((_) => ({
                            item: _,
                            key: _.id,
                        }))}
                        placeholder="Select module"
                        onChange={(_) => {
                            if (_) {
                                setSelectedModule(_.item.id);
                            }
                        }}
                        width={360}
                        leftHandIcon={<BookOpenIcon height={"18px"} />}
                    />
                    <Button
                        onClick={() => {
                            if (selectedModule) {
                                setModuleId(selectedModule);
                            }
                        }}
                        colorScheme={"black"}
                        bg={"gray.800"}
                        h={"44px"}
                        borderRadius={"8px"}
                        disabled={!selectedModule}
                    >
                        Browse resources
                    </Button>
                </Flex>
            </Box>
        </Box>
    );
};
