import {
    Box,
    Icon,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
} from "@chakra-ui/react";
import { ReactComponent as Notepad } from "design/assets/Notepad.svg";
import { ArtifactCard, ArtifactCardProps } from "./ArtifactCard";
import { InsightsArtifact, InsightsEtherpadArtifact } from "./InsightsContext";
import { useState } from "react";

export const NotepadCard: React.FC<
    ArtifactCardProps & {
        artifact: InsightsArtifact;
        etherpad: InsightsEtherpadArtifact;
    }
> = (props) => {
    const { artifact, etherpad } = props;
    const [tabIndex, setTabIndex] = useState(0);

    const metadata = {
        immersionName: artifact.immersion.name,
        immersionId: artifact.immersion.id,
        participants: etherpad.participants || [],
        steps: etherpad.steps || [],
        date: new Date(artifact.time),
        timeslotId: artifact.timeslotId,
        cohort: artifact.cohort || undefined,
    };

    return (
        <ArtifactCard
            title={"Notepad"}
            subtitle={etherpad.resourceName}
            icon={<Icon as={Notepad} mb={1} mr={1} />}
            groupType={etherpad.participationType}
            metadata={metadata}
            participants={etherpad.participants || []}
            type={artifact.type}
            {...props}
        >
            <Tabs
                index={tabIndex}
                onChange={(index) => setTabIndex(index)}
                variant="unstyled"
                pt={0}
                px={0}
            >
                <TabList
                    bg="gray.25"
                    justifyContent="center"
                    borderBottom="1px solid"
                    borderBottomColor="gray.100"
                >
                    <Tab
                        _selected={{
                            color: "blue.500",
                            borderBottom: "2px solid",
                            borderBottomColor: "blue.500",
                        }}
                        color="gray.400"
                        fontSize="11pt"
                        fontWeight="regular"
                    >
                        View edits
                    </Tab>
                    <Tab
                        _selected={{
                            color: "blue.500",
                            borderBottom: "2px solid",
                            borderBottomColor: "blue.500",
                        }}
                        color="gray.400"
                        fontSize="11pt"
                        fontWeight="regular"
                    >
                        Latest revision
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel p={0}>
                        <Box paddingX="40px" paddingY="24px">
                            <div
                                className={`notepad-content notepad-content-diff expanded-${props.expanded}`}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        etherpad.content || "(Not available)",
                                }}
                            ></div>
                        </Box>
                    </TabPanel>
                    <TabPanel p={0}>
                        <Box paddingX="40px" paddingY="24px">
                            <div
                                className={`notepad-content notepad-content-latest expanded-${props.expanded}`}
                                dangerouslySetInnerHTML={{
                                    __html:
                                        etherpad.finalRevisionHtml ||
                                        "(Not available)",
                                }}
                            ></div>
                        </Box>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </ArtifactCard>
    );
};
