import React from "react";

export interface ContextType {
    get(endpoint: string): Promise<any>;
    post(endpoint: string, data: object): Promise<any>;
    put(endpoint: string, data: object): Promise<any>;
    delete(endpoint: string): Promise<any>;
    email?: string;
    setEmail(email: string): void;
    organizationId: string;
    organizationName: string;
    organizationTimezone: string;
    setOrganizationId(id: string): void;
    setOrganizationName(name: string): void;
    setOrganizationTimezone(timezone: string): void;
    timezoneName: string;
    timezoneSource: "Account" | "UTC" | "Local";
    setTimezoneName(name: string): void;
    setTimezoneSource(source: "Account" | "UTC" | "Local"): void;
    maintenanceWarningDismissed: boolean;
    setMaintenanceWarningDismissed(dismissed: boolean): void;
}

const notImplemented = () => {
    throw new Error("Context fetch method not initialized");
};

export const Context = React.createContext<ContextType>({
    get: notImplemented,
    post: notImplemented,
    put: notImplemented,
    delete: notImplemented,
    email: "",
    setEmail: () => {},
    organizationId: "",
    organizationName: "",
    organizationTimezone: "",
    setOrganizationId: () => {},
    setOrganizationName: () => {},
    setOrganizationTimezone: () => {},
    timezoneName: "",
    timezoneSource: "Account",
    setTimezoneName: () => {},
    setTimezoneSource: () => {},
    maintenanceWarningDismissed: false,
    setMaintenanceWarningDismissed: () => {},
});

export const useGlobalContext = () => React.useContext(Context);
