import { useEffect, useState } from "react";
import Loading from "../components/common/Loading";
import Table from "../components/common/Table";
import { ParamsProps, timeParser, withParams } from "../utils";
import { useTRPC } from "../hooks/useTRPC";

const AuditLog = (props: ParamsProps) => {
    const [data, setData] = useState<{
        [page: string]: { [key: string]: any }[];
    }>({});
    const [page, setPage] = useState(0);

    const trpc = useTRPC();

    const { data: pageData, error } = props.params.modelId
        ? trpc.audit.getAuditLogPageByModelId.useQuery({
              modelId: props.params.modelId,
              page,
          })
        : trpc.audit.getAuditLogPage.useQuery({ page });

    useEffect(() => {
        if (data[page]) {
            return;
        }
        setData({ ...data, [page]: pageData });
    }, [data, page, pageData]);

    if (Object.keys(data).length === 0) {
        return <Loading error={error?.message} />;
    }

    const columns: (
        | [string, string]
        | [string, string, (field: any) => string | Date]
    )[] = [
        ["createdAt", "Timestamp", timeParser],
        ["actorName", "Actor Name"],
    ];
    if (!props.params.modelId) {
        columns.push(["model", "Model Name"]);
        columns.push(["modelId", "Model ID"]);
    }
    columns.push(["prevAttributes", "Previous Attributes"]);
    columns.push(["newAttributes", "New Attributes"]);

    return (
        <div>
            <h1 className="text-base font-normal text-gray-700">Audit Log</h1>
            <Table
                columns={columns}
                data={data}
                onPageChange={(page: number) => setPage(page)}
                page={page}
                sortable={true}
            />
        </div>
    );
};

export default withParams(AuditLog);
