import { Box, Button, Flex } from "@chakra-ui/react";
import React, { useState } from "react";
import { PlusIcon } from "@heroicons/react/20/solid";
import { AdminUserSelect } from "./AdminUserSelect";
import { AdminUser } from "./utils";

export const AdminUserCreateForm: React.FC<{
    users: AdminUser[];
    onAddUser: (userId: string) => void;
}> = ({ users, onAddUser }) => {
    const [selectedUser, setSelectedUser] = useState<AdminUser | null>(null);
    const [showInput, setShowInput] = useState<boolean>(false);
    return (
        <Flex>
            <Box>
                <h4 className="text-base text-gray-500 mb-2 mt-5">
                    Admins permissions
                </h4>
            </Box>
            <Box ml="auto">
                {!showInput ? (
                    <Button
                        colorScheme="gray"
                        size="sm"
                        marginTop="10px"
                        onClick={() => setShowInput(true)}
                    >
                        <PlusIcon height="17px" /> New admin
                    </Button>
                ) : (
                    <Box className="inline-flex w-full">
                        {showInput ? (
                            <AdminUserSelect
                                selectedItem={selectedUser}
                                users={users}
                                onChange={setSelectedUser}
                            />
                        ) : null}
                        <Button
                            colorScheme="gray"
                            variant="outline"
                            size="sm"
                            marginTop="10px"
                            marginLeft={2}
                            onClick={() => {
                                setShowInput(false);
                                setSelectedUser(null);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button
                            colorScheme="gray"
                            size="sm"
                            marginTop="10px"
                            marginLeft={2}
                            isDisabled={selectedUser === null}
                            onClick={() => {
                                if (selectedUser) {
                                    onAddUser(selectedUser.user.id);
                                    setSelectedUser(null);
                                    setShowInput(false);
                                }
                            }}
                        >
                            Add
                        </Button>
                    </Box>
                )}
            </Box>
        </Flex>
    );
};
