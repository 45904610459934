import {
    Box,
    Flex,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    useToast,
} from "@chakra-ui/react";
import {
    PencilIcon,
    TrashIcon,
    XCircleIcon,
} from "@heroicons/react/24/outline";
import classNames from "classnames";
import { useCallback, useEffect, useState } from "react";
import { Navigate, NavLink, useNavigate } from "react-router-dom";
import { AccountSelectModal } from "../components/common/AccountSelectModal";
import Button from "../components/common/Button";
import Form from "../components/common/Form";
import { FullScreenLoading } from "../components/common/FullScreenLoading";
import Loading from "../components/common/Loading";
import useSetPassword from "../components/common/SetPasswordForm";
import Toggle from "../components/common/Toggle";
import EventsTable from "../components/factories/EventsTable";
import Flags from "../components/factories/Flags";
import SessionsByTime from "../components/factories/SessionsByTime";
import Tooltip from "../components/factories/Tooltip";
import UserSelect, {
    UserSelectUserType,
} from "../components/factories/UserSelect";
import { useGlobalContext } from "../context";
import useStateObject from "../hooks/useStateObject";
import { useTRPC } from "../hooks/useTRPC";
import { useIsSuperAdmin } from "../hooks/useUserData";
import { ParamsProps, withParams } from "../utils";
import AuditLink from "./AuditLink";
import { User as UserType } from "./Types";

interface State {
    user?: UserType;
    flags: {
        id: string;
        name: string;
        enabled: boolean;
        retiredOn: string | null;
    }[];
    addingEmail: boolean;
    editingEmail: boolean;
    deleted: boolean;
    editingFirstName: boolean;
    editingLastName: boolean;
    email: string;
    error: string;
    nonce: number;
    managerId: string;
    passwordChanged: boolean;
    pending: boolean;
    settingManager: boolean;
    firstName: string;
    lastName: string;
    users: UserSelectUserType[];
}

const User = (props: ParamsProps) => {
    const context = useGlobalContext();
    const isSuperAdmin = useIsSuperAdmin();
    const trpc = useTRPC();
    const navigate = useNavigate();
    const [showAccountSelectModal, setShowAccountSelectModal] = useState(false);

    // Account management
    const { mutateAsync: setUserAccountMutation } =
        trpc.user.setUserAccount.useMutation();

    const [fullScreenLoading, setFullScreenLoading] = useState(false);
    const toast = useToast();

    const { data: trpcUser } = trpc.users.getUser.useQuery({
        userId: props.params.userId || "",
    });
    const userCohorts = trpcUser?.cohorts || [];
    const userRole = !!trpcUser?.clientAdminPermissions;

    const [state, setState] = useStateObject<State>({
        addingEmail: false,
        editingEmail: false,
        deleted: false,
        editingFirstName: false,
        editingLastName: false,
        email: "",
        error: "",
        nonce: 0,
        flags: [],
        managerId: "",
        passwordChanged: false,
        pending: false,
        settingManager: false,
        firstName: "",
        lastName: "",
        users: [],
    });

    const getEndpoint = useCallback(() => {
        return `user/${props.params.userId}`;
    }, [props.params.userId]);

    const cancelEvent = useCallback(
        async (eventId: string) => {
            setState({
                pending: true,
            });
            try {
                await context.delete(`event/${eventId}`);
                setState({
                    pending: false,
                });
            } catch (error) {
                setState({
                    error: (error as any).message,
                    nonce: state.nonce + 1,
                    pending: false,
                });
            }
        },
        [context, setState, state.nonce],
    );

    const fetchUser = useCallback(async () => {
        try {
            const user = await context.get(getEndpoint());
            for (const event of user.events) {
                event.cancel = (
                    <Button
                        onClick={async () => {
                            cancelEvent(event.id);
                            await fetchUser();
                        }}
                        label={"Cancel"}
                        disabled={event.status !== "PENDING"}
                    />
                );
            }
            setState({
                user,
                managerId: user?.manager ? user?.manager.id : "",
            });
            return user;
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
            });
        }
    }, [cancelEvent, context, getEndpoint, setState, state.nonce]);

    const { data: flagData } = trpc.flags.getUserFlags.useQuery();

    useEffect(() => {
        setState({
            flags: flagData,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagData]);

    const componentDidMount = useCallback(async () => {
        try {
            const user = await fetchUser();
            setState({
                managerId: user?.manager ? user?.manager.id : "",
            });
            context.setOrganizationId(user?.organization.id);
            context.setOrganizationName(user?.organization.name);
            context.setOrganizationTimezone(
                user?.organization.timezone
                    ? user?.organization.timezone.name
                    : "UTC",
            );
            setState({
                users: (await context.get(`${getEndpoint()}/users`)).sort(
                    (a: any, b: any) => {
                        return `${a.firstName} ${a.lastName}`.localeCompare(
                            `${b.firstName} ${b.lastName}`,
                        );
                    },
                ),
            });
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
            });
        }
    }, [context, fetchUser, getEndpoint, setState, state.nonce]);

    useEffect(() => {
        componentDidMount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.params.userId]);

    const cancel = () => {
        setState({
            addingEmail: false,
            editingEmail: false,
            editingFirstName: false,
            editingLastName: false,
            email: state.user?.emails?.[0]?.email || "",
            error: "",
            firstName: state.user?.firstName || "",
            lastName: state.user?.lastName || "",
            settingManager: false,
            managerId: state.user?.manager?.id || "",
        });
    };

    const postChangePassword = useCallback(
        async (password: string) => {
            try {
                setState({ pending: true });
                await context.post(`${getEndpoint()}/change-password`, {
                    password,
                });
                cancel();
                setState({ passwordChanged: true });
            } catch (error) {
                setState({ error: (error as any).message });
            } finally {
                setState({ pending: false });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [context.post],
    );

    const { SetPasswordForm } = useSetPassword({
        changePassword: postChangePassword,
        label: state.passwordChanged
            ? "Password changed successfully"
            : "WARNING: Verify user before resetting their password.",
        disabled: state.pending,
    });

    if (!state.user) {
        return <Loading error={state.error} />;
    }

    const doDelete = async () => {
        setState({
            pending: true,
        });
        try {
            await context.delete(getEndpoint());
            setState({
                deleted: true,
            });
            cancel();
        } catch (error) {
            setState({
                error: (error as any).message,
                pending: false,
                nonce: state.nonce + 1,
            });
        }
    };

    const put = async (data: any) => {
        setState({
            pending: true,
        });
        try {
            await context.put(getEndpoint(), data);
            await fetchUser();
            setState({
                pending: false,
            });
            cancel();
        } catch (error) {
            setState({
                error: (error as any).message,
                pending: false,
                nonce: state.nonce + 1,
            });
        }
    };

    const firstName = () =>
        state.user && (
            <div className="mb-3" key={"firstName"}>
                <h3 className="mb-2 text-gray-500">First name</h3>
                {state.editingFirstName ? (
                    <Form
                        cancel={cancel}
                        submit={async () => {
                            put({
                                firstName: state.firstName.trim(),
                            });
                        }}
                        change={(event) =>
                            setState({
                                firstName: event.currentTarget.value,
                            })
                        }
                        disabled={state.pending}
                        error={state.error}
                        label="First name"
                        value={state.firstName}
                    />
                ) : (
                    <div className="flex">
                        {state.user.firstName}
                        {isSuperAdmin && (
                            <button
                                className={classNames(
                                    "text-lg font-medium text-gray-900 cursor-pointer",
                                )}
                                onClick={() => {
                                    cancel();
                                    setState({
                                        editingFirstName: true,
                                    });
                                }}
                                disabled={state.pending}
                            >
                                <PencilIcon className="w-4 ml-2 t-4 text-slate-500" />
                            </button>
                        )}
                    </div>
                )}
            </div>
        );

    const lastName = () =>
        state.user && (
            <div className="mb-3" key={"lastName"}>
                <h3 className="mb-2 text-gray-500">Last name</h3>
                {state.editingLastName ? (
                    <Form
                        cancel={cancel}
                        submit={async () => {
                            put({
                                lastName: state.lastName.trim(),
                            });
                        }}
                        change={(event) =>
                            setState({
                                lastName: event.currentTarget.value,
                            })
                        }
                        disabled={state.pending}
                        label="Last name"
                        value={state.lastName}
                    />
                ) : (
                    <div className="flex">
                        {state.user.lastName}
                        {isSuperAdmin && (
                            <button
                                className={classNames(
                                    "text-lg font-medium text-gray-900 cursor-pointer",
                                )}
                                onClick={() => {
                                    cancel();
                                    setState({
                                        editingLastName: true,
                                    });
                                }}
                                disabled={state.pending}
                            >
                                <PencilIcon className="w-4 ml-2 t-4 text-slate-500" />
                            </button>
                        )}
                    </div>
                )}
            </div>
        );

    const active = () =>
        state.user && (
            <div className="pl-4 ml-12 border-l border-slate-300">
                <h4 className="text-base text-gray-500">Status</h4>
                <div className="mt-3">
                    <Toggle
                        disabled={state.pending}
                        onChange={async (active: boolean) => {
                            put({
                                active,
                            });
                        }}
                        label={"Active"}
                        value={state.user.active}
                    />
                </div>
            </div>
        );

    const emails = () =>
        state.user && (
            <div className="mt-10">
                <h4 className="mt-6 text-base text-gray-500">Emails</h4>
                {state.user.emails.map((data, _) => (
                    <div className="flex mt-2 items-center" key={data.email}>
                        {state.editingEmail ? (
                            <Form
                                cancel={cancel}
                                submit={async () => {
                                    put({
                                        email: data.email,
                                        newEmail: state.email.trim(),
                                        updateExisting: true,
                                    });
                                }}
                                change={(event) =>
                                    setState({
                                        email: event.currentTarget.value,
                                    })
                                }
                                disabled={state.pending}
                                label="Email"
                                placeholder={"name@example.com"}
                                value={state.email}
                                error={state.error}
                            />
                        ) : (
                            <div className="flex">
                                {data.email}
                                {isSuperAdmin && (
                                    <button
                                        className={classNames(
                                            "text-lg font-medium text-gray-900 cursor-pointer",
                                        )}
                                        onClick={() => {
                                            cancel();
                                            setState({
                                                editingEmail: true,
                                            });
                                        }}
                                        disabled={state.pending}
                                    >
                                        <PencilIcon className="w-4 ml-2 t-4 text-slate-500" />
                                    </button>
                                )}
                            </div>
                        )}
                        {isSuperAdmin && !state.editingEmail && (
                            <Button
                                onClick={() => {
                                    put({
                                        email: data.email,
                                        delete: true,
                                    });
                                }}
                                disabled={props.pending}
                                label={
                                    <TrashIcon className="w-4 h-4 text-slate-500" />
                                }
                                className="bg-white hover:bg-white ml-2 py-[8px] px-[8px] border-gray-100 shadow-none focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-white"
                                requiresConfirmation
                                confirmationTheme="red"
                            />
                        )}
                    </div>
                ))}
                {state.user.emails.length === 0 && (
                    <div className="flex items-center mt-3">
                        {state.addingEmail ? (
                            <Form
                                cancel={cancel}
                                submit={async () => {
                                    put({
                                        email: state.email.trim(),
                                    });
                                }}
                                change={(event) =>
                                    setState({
                                        email: event.currentTarget.value,
                                    })
                                }
                                disabled={state.pending}
                                label="Email"
                                placeholder={"name@example.com"}
                                value={state.email}
                            />
                        ) : (
                            isSuperAdmin && (
                                <Button
                                    onClick={() => {
                                        cancel();
                                        setState({
                                            addingEmail: true,
                                        });
                                    }}
                                    label="Add Email"
                                />
                            )
                        )}
                    </div>
                )}
            </div>
        );

    const flags = () =>
        state.user && (
            <Flex marginTop="10px" flexDir={"column"}>
                <Flags
                    disabled={state.pending}
                    flags={state.user.featureFlagOverrides}
                    allFlags={state.flags?.filter((flag) => !flag.retiredOn)}
                    onChange={(id: string, enabled: boolean) => {
                        if (enabled) {
                            put({
                                flag: {
                                    id,
                                    enabled,
                                },
                            });
                        } else {
                            put({
                                flag: {
                                    id,
                                    clear: true,
                                },
                            });
                        }
                    }}
                />
                <Flex marginTop="10px" flexDirection="row">
                    <Toggle
                        label="Client Admin"
                        disabled={true}
                        value={userRole || false}
                        onChange={() => {}}
                    ></Toggle>
                    <Tooltip text="Access to admin.sparkwise.co" />
                </Flex>
            </Flex>
        );

    const manager = () => {
        const managerUser = state.users!.find(
            (user) => user.id === state.user?.manager?.id,
        );

        return (
            <div className="flex flex-col mt-6">
                <h4 className="mt-6 text-base text-gray-500">Manager</h4>
                <div className="mt-2">
                    {managerUser ? (
                        <div className="flex">
                            <NavLink
                                className="flex text-sky-700 hover:underline underline-offset-2"
                                key={managerUser.id}
                                to={`/user/${managerUser.id}`}
                            >
                                {managerUser.firstName || ""}{" "}
                                {managerUser.lastName || ""} (
                                {managerUser.emails
                                    .map((email) => email.email)
                                    .join(", ")}
                                )
                            </NavLink>
                            <button
                                className={"ml-2"}
                                disabled={state.pending}
                                type="button"
                                onClick={async () => {
                                    put({
                                        managerId: "",
                                    });
                                }}
                            >
                                <XCircleIcon className="w-6 h-6 text-slate-500" />
                            </button>
                        </div>
                    ) : (
                        "None"
                    )}
                </div>
                <div className="flex items-center mt-3">
                    {state.settingManager ? (
                        <div className="rounded-lg bg-gray-50 w-fit">
                            <div className="p-6">
                                <label
                                    htmlFor="user"
                                    className="block mb-1 text-sm font-medium text-gray-700"
                                >
                                    Manager
                                </label>
                                <UserSelect
                                    selectedUserId={state.managerId}
                                    pending={state.pending}
                                    saved={false}
                                    users={state.users}
                                    attendedTags={true}
                                    onChange={(user: any) => {
                                        setState({
                                            managerId: user ? user.id : "",
                                        });
                                    }}
                                    onSave={async () => {
                                        await put({
                                            managerId: state.managerId,
                                        });
                                    }}
                                />
                                <div className="mt-6">
                                    <Button
                                        label="Cancel"
                                        disabled={state.pending}
                                        onClick={cancel}
                                        secondary={true}
                                    />
                                </div>
                            </div>
                        </div>
                    ) : (
                        <Button
                            onClick={() => {
                                cancel();
                                setState({
                                    settingManager: true,
                                });
                            }}
                            label="Assign Manager"
                        />
                    )}
                </div>
            </div>
        );
    };

    const manages = () =>
        state.user &&
        state.user.manages &&
        state.user.manages.length > 0 && (
            <div className="flex flex-col mt-2">
                <h4 className="mt-6 text-base text-gray-500">Manages</h4>
                <div className="mt-2">
                    {state.user.manages.map((user) => (
                        <NavLink
                            className="flex w-full text-sky-700 hover:underline underline-offset-2"
                            key={user.id}
                            to={`/user/${user.id}`}
                        >
                            {`${user.firstName || ""} ${user.lastName || ""}`}
                        </NavLink>
                    ))}
                </div>
                <div className="flex flex-row items-end mt-6">
                    <Button
                        onClick={() => {
                            put({
                                action: "manager-email",
                            });
                        }}
                        label={"Send manager email"}
                        disabled={state.pending}
                    />
                </div>
            </div>
        );

    const sessions = () =>
        state.user && (
            <div className="mt-10">
                <Tabs variant="unstyled">
                    <TabList mt="40px">
                        <Tab
                            color="dark.500"
                            borderRadius="8px"
                            fontWeight="600"
                            _selected={{
                                bgColor: "gray.50",
                                color: "blue.600",
                            }}
                        >
                            Future Sessions
                        </Tab>
                        <Tab
                            color="dark.500"
                            borderRadius="8px"
                            fontWeight="600"
                            _selected={{
                                bgColor: "gray.50",
                                color: "blue.600",
                            }}
                        >
                            Past Sessions
                        </Tab>
                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <SessionsByTime
                                organizationOrUserId={state.user.id}
                                pastOrFuture="future"
                            />
                        </TabPanel>
                        <TabPanel>
                            <SessionsByTime
                                organizationOrUserId={state.user.id}
                                pastOrFuture="past"
                            />
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </div>
        );

    const deleteComponent = () =>
        state.user && (
            <div className="pl-4 ml-12 border-l border-slate-300">
                <h4 className="text-base text-slate-400">Delete</h4>
                <div className="flex items-center mt-3">
                    {state.user.participants.length ||
                    state.user.emails.length ? (
                        <>
                            <p className="w-48 text-sm text-slate-400">
                                Users with emails or sessions cannot be deleted.
                            </p>
                        </>
                    ) : (
                        <Button
                            disabled={state.pending}
                            onClick={doDelete}
                            label={"Delete User"}
                        />
                    )}
                </div>
            </div>
        );

    if (state.deleted) {
        return <Navigate to={`/users`} />;
    }

    return (
        <div>
            <h4 className="text-lg font-medium text-slate-800">User</h4>
            <div className="flex items-stretch mt-3">
                {firstName()}
                <div className="mx-4" />
                {lastName()}
                {isSuperAdmin && (
                    <div>
                        <div className="mx-4" />
                        {active()}
                        <div className="mx-4" />
                        {deleteComponent()}
                        <div className="mx-4" />
                        <AuditLink modelId={state.user.id} />
                    </div>
                )}
            </div>
            {isSuperAdmin && flags()}
            {emails()}
            <div className="mt-4">{isSuperAdmin && SetPasswordForm}</div>
            {isSuperAdmin && (
                <div className="mt-10">
                    <h4 className="mt-6 text-base text-gray-500">Cohort</h4>
                    <div>
                        {userCohorts.length ? (
                            <Flex direction="column">
                                {userCohorts.map((cohort) => (
                                    <NavLink
                                        key={cohort.id}
                                        to={`/cohort/${cohort.id}`}
                                    >
                                        {cohort.name}
                                    </NavLink>
                                ))}
                            </Flex>
                        ) : (
                            "None"
                        )}
                    </div>
                </div>
            )}
            {isSuperAdmin && manager()}
            {isSuperAdmin && manages()}
            {isSuperAdmin &&
                state.user.manages &&
                state.user.manages.length > 0 && (
                    <>
                        <h4 className="mt-8 text-base text-gray-500">Events</h4>
                        <EventsTable events={state.user.events} />
                    </>
                )}
            {isSuperAdmin && (
                <Flex mt={6} flexDir={"column"}>
                    <h4 className="mt-6 text-base text-gray-500">
                        Account Management
                    </h4>
                    <Flex mt={2}>
                        <Button
                            label="Move user to another account"
                            disabled={!!trpcUser?.clientAdminPermissions}
                            onClick={() => {
                                setShowAccountSelectModal(true);
                            }}
                        />
                        {!!trpcUser?.clientAdminPermissions && (
                            <Box mt={1}>
                                <Tooltip text="Please remove Client Admin status before moving this user" />
                            </Box>
                        )}
                    </Flex>
                </Flex>
            )}
            {isSuperAdmin && (
                <AccountSelectModal
                    userId={
                        (showAccountSelectModal && props.params.userId) || null
                    }
                    onCancel={() => setShowAccountSelectModal(false)}
                    onCommit={async (account) => {
                        if (
                            props.params.userId &&
                            props.params.userId !== "" &&
                            account
                        ) {
                            try {
                                setFullScreenLoading(true);
                                setShowAccountSelectModal(false);
                                await setUserAccountMutation({
                                    userId: props.params.userId,
                                    accountId: account.id,
                                });
                                setFullScreenLoading(false);
                                navigate(`/account/${account.id}`);
                                setTimeout(() => {
                                    navigate(`/user/${props.params.userId}`);
                                    toast({
                                        duration: 3000,
                                        position: "top",
                                        render: () => (
                                            <Box
                                                width="max-content"
                                                background="gray.700"
                                                position="relative"
                                                borderRadius="4px"
                                                color="white"
                                                p={4}
                                            >
                                                <Text fontSize={"sm"}>
                                                    User has been moved
                                                    successfully! You are now in{" "}
                                                    {account.name} account.
                                                </Text>
                                            </Box>
                                        ),
                                    });
                                }, 500);
                            } catch (e) {
                                setFullScreenLoading(false);
                                setShowAccountSelectModal(false);
                                alert((e as Error).message);
                            }
                        }
                    }}
                />
            )}
            {sessions()}
            <FullScreenLoading isOpen={fullScreenLoading} />
        </div>
    );
};

export default withParams(User);
