import { createContext, PropsWithChildren, useContext, useState } from "react";
import { useUserData } from "../hooks/useUserData";
import { Chatwoot } from "../App/Chatwoot";

export interface ChatwootContext {
    hide: () => void;
    show: () => void;
}

export const ChatwootContext = createContext<ChatwootContext>(
    null as unknown as ChatwootContext,
);

export const ChatwootContextProvider: React.FC<PropsWithChildren> = ({
    children,
}) => {
    const userData = useUserData();
    const isClientAdmin = userData.data?.isClientAdmin;
    const [visible, setVisible] = useState(true);
    const context: ChatwootContext = {
        show: () => {
            return setVisible(true);
        },
        hide: () => {
            return setVisible(false);
        },
    };
    return (
        <ChatwootContext.Provider value={context}>
            {children}
            {!!isClientAdmin ? <Chatwoot visible={visible} /> : null}
        </ChatwootContext.Provider>
    );
};

export const useChatwoot = () => useContext(ChatwootContext);
