import { Box, Flex } from "@chakra-ui/react";
import { Global } from "@emotion/react";
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNeedsSidebarCollapsed } from "../context/SidebarContext";
import { useChatwoot } from "../contexts/ChatwootContext";
import { useOrganization } from "../hooks/useOrganization";
import {
    InsightsContextProvider,
    useInsights,
} from "./Insights/InsightsContext";
import { InsightsEmptyState } from "./Insights/InsightsEmptyState";
import { InsightsIntroScreen } from "./Insights/InsightsIntroScreen";
import { InsightsMain } from "./Insights/InsightsMain";
import { InsightsSidebar } from "./Insights/InsightsSidebar";
import { InsightsSelect } from "./Insights/InsightsSelect";
import { useInsightsImmersions } from "../hooks/useInsightsImmersions";
import { ArrowRightEndOnRectangleIcon } from "@heroicons/react/24/outline";

export const Insights: React.FC = () => {
    const params = useParams();
    useOrganization(params.organizationId || "");
    const { show, hide } = useChatwoot();

    useEffect(() => {
        hide();
        return () => {
            show();
        };
    }, []);

    return (
        <InsightsContextProvider>
            <InsightsInner />
        </InsightsContextProvider>
    );
};

const InsightsInner = () => {
    useNeedsSidebarCollapsed();
    const { state, data, setModuleId, openExitModal } = useInsights();
    const immersions = useInsightsImmersions();
    const isFilterSet = !!state.filters.moduleId;
    const hasData = data?.length > 0;
    const showIntroScreen = !isFilterSet;
    const showEmptyState = isFilterSet && !hasData;
    const showMain = isFilterSet && hasData;
    if (showIntroScreen) {
        return (
            <Flex
                bg={"gray.50"}
                pos={"absolute"}
                top={0}
                left={0}
                width={"100%"}
                height={"100%"}
            >
                <InsightsIntroScreen />
            </Flex>
        );
    }
    const selectedImmersion = immersions?.find(
        (_) => _.id === state.filters.moduleId,
    );

    return (
        <>
            <Global
                styles={{
                    "@media print": {
                        "html, body": {
                            height: "auto",
                            width: "100%",
                            margin: 0,
                            padding: 0,
                        },
                        ".print-header": {
                            position: "fixed",
                            top: "0",
                            left: "0",
                            right: "0",
                            margin: 0,
                            padding: 0,
                            background: "white",
                            borderBottom: "1px solid #ccc",
                            zIndex: 100,
                            color: "#999CA3",
                        },
                        ".print-footer": {
                            position: "fixed",
                            bottom: "0",
                            left: "0",
                            right: "0",
                            margin: 0,
                            padding: 0,
                            background: "white",
                            borderTop: "1px solid #ccc",
                            zIndex: 100,
                            color: "#999CA3",
                        },
                        // Main content needs padding to avoid overlap with headers/footers
                        ".print-content": {
                            marginTop: "1.5cm",
                            marginBottom: "1.5cm",
                            paddingTop: "0.5cm",
                            paddingBottom: "0.5cm",
                        },
                        "@page": {
                            size: "auto",
                            margin: "0cm",
                        },
                        // Hide unnecessary UI elements when printing
                        ".chakra-modal__overlay": {
                            display: "none !important",
                        },
                        // Ensure content breaks appropriately
                        "p, h1, h2, h3, h4, h5, h6": {
                            orphans: 3,
                            widows: 3,
                        },
                        "h1, h2, h3, h4, h5": {
                            pageBreakAfter: "avoid",
                        },
                        // Force background colors and images to print
                        "*": {
                            WebkitPrintColorAdjust: "exact",
                            printColorAdjust: "exact",
                        },
                    },
                }}
            />

            <Flex
                pos={"absolute"}
                top={0}
                left={0}
                width={"100%"}
                height={"100%"}
                bg={"gray.50"}
                direction={"column"}
            >
                <Flex
                    h={"52px"}
                    px={"24px"}
                    py={"12px"}
                    borderBottom={"1px solid"}
                    borderColor={"gray.200"}
                    justifyContent={"space-between"}
                    sx={{
                        "@media print": {
                            display: "none",
                        },
                    }}
                >
                    {selectedImmersion ? (
                        <InsightsSelect
                            variant={"text"}
                            searchable
                            itemToString={(_) => _?.item.tactic || ""}
                            items={(immersions || []).map((_) => ({
                                item: _,
                                key: _.id,
                            }))}
                            initialItem={
                                selectedImmersion
                                    ? {
                                          item: selectedImmersion,
                                          key: selectedImmersion.id,
                                      }
                                    : undefined
                            }
                            onChange={(_) => {
                                if (_) {
                                    setModuleId(_.item.id);
                                }
                            }}
                            width={360}
                        />
                    ) : null}
                    <Box
                        color={"black"}
                        _hover={{
                            cursor: "pointer",
                            color: "gray.800",
                        }}
                        _active={{
                            color: "gray.500",
                        }}
                        onClick={openExitModal}
                        display={"none"}
                    >
                        <ArrowRightEndOnRectangleIcon height={"24px"} />
                    </Box>
                </Flex>
                <Flex
                    grow={1}
                    position={"relative"}
                    direction={["column", "row"]}
                    className="print-content"
                    sx={{
                        "@media print": {
                            flexDirection: "column",
                        },
                    }}
                >
                    <InsightsSidebar />
                    {showMain ? (
                        <>
                            <InsightsMain />
                        </>
                    ) : null}
                    {showEmptyState ? <InsightsEmptyState /> : null}
                </Flex>
            </Flex>
        </>
    );
};
