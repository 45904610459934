import { Flex } from "@chakra-ui/react";
import { useParams } from "react-router-dom";

export const Excalidraw = () => {
    const { recordId } = useParams();
    const url = `https://draw-prod.sparkwise.co/index.html?mode=full#room=${recordId}`;

    const systemId = url?.split("room=")?.[1] ?? "";

    return (
        <Flex
            flex="1"
            direction="column"
            borderWidth="1px"
            borderColor="gray.100"
            borderRadius="12px"
            height={"90%"}
            overflow="hidden"
        >
            <iframe
                title={"Sample title"}
                key={systemId}
                src={url}
                height="100%"
                width="100%"
            />
        </Flex>
    );
};
