import React from "react";
import { Text } from "@chakra-ui/react";
import { getAppVersion } from "../../env";
import { useIsSuperAdmin } from "../../hooks/useUserData";

export const AppVersion: React.FC = () => {
    const version = getAppVersion();
    const isSuperAdmin = useIsSuperAdmin();
    if (!version || !isSuperAdmin) {
        return null;
    }
    return <Text fontSize={"sm"}>{version}</Text>;
};
