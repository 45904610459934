import React from "react";
import { getAdminUserName } from "./utils";
import { NavLink } from "react-router-dom";

const Link: React.FC<{
    href: string;
    label: string;
}> = ({ href, label }) => (
    <NavLink
        className="flex w-full text-dark-800 hover:underline underline-offset-2"
        to={href}
    >
        {label}
    </NavLink>
);

export const AdminUserItem: React.FC<{
    link?: string;
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    inline?: boolean;
}> = ({ firstName, lastName, email, link, inline }) => {
    const name = getAdminUserName(firstName, lastName);
    if (name) {
        if (inline) {
            return (
                <div>
                    <span className="text-dark-800">
                        {link ? <Link href={link} label={name} /> : name}
                    </span>{" "}
                    <span className="text-dark-400">({email})</span>
                </div>
            );
        }
        return (
            <div>
                <p>{link ? <Link href={link} label={name} /> : name}</p>
                <p className="text-xs text-dark-400 mt-1">({email})</p>
            </div>
        );
    }
    return link ? <Link href={link} label={email} /> : email;
};
