import { useEffect, useState } from "react";
import DateTimePicker from "react-datetime-picker";
import { Navigate } from "react-router-dom";
import Button from "../components/common/Button";
import Loading from "../components/common/Loading";
import Table from "../components/common/Table";
import { useGlobalContext } from "../context";
import {
    convertDateTimeToISO,
    convertLocalJSDateToDateTime,
    formatTime,
    isFullyScheduled,
    isoTimeSameMinute,
    localizeTimeToJSDate,
    ParamsProps,
    timeParser,
    withParams,
} from "../utils";
import { Session as SessionType } from "./Types";

import {
    Button as ChakraButton,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalOverlay,
    Text,
    useDisclosure,
} from "@chakra-ui/react";
import {
    ArrowTopRightOnSquareIcon,
    ExclamationTriangleIcon,
} from "@heroicons/react/24/outline";
import { BillingType } from "@prisma/client";
import classNames from "classnames";
import { DateTime } from "luxon";
import { ROOT_USERS } from "shared/constants";
import ErrorModal from "../components/common/ErrorModal";
import Toggle from "../components/common/Toggle";
import EventsTable from "../components/factories/EventsTable";
import Flags from "../components/factories/Flags";
import ImmersionSelect from "../components/factories/ImmersionSelect";
import ResetSaveButtons from "../components/factories/ResetSaveButtons";
import Tooltip from "../components/factories/Tooltip";
import { UserSelectUserType } from "../components/factories/UserSelect";
import { getSparkwiseUrl } from "../env";
import useShowHide from "../hooks/useShowHide";
import useStateObject from "../hooks/useStateObject";
import { useTRPC } from "../hooks/useTRPC";
import { useIsSuperAdmin } from "../hooks/useUserData";
import SessionParticipants from "./SessionParticipants";

export interface SessionState {
    // Internal state
    deleted: boolean;
    error: string;
    fullyScheduled: boolean;
    nonce: number;
    pending: boolean;
    saved: {
        immersionId: boolean;
        notes: boolean;
        participant: boolean;
        time: boolean;
        sessionParticipantMany: boolean;
    };
    // Fetched values
    session?: SessionType;
    flags: {
        id: string;
        name: string;
        enabled: boolean;
        retiredOn: string | null;
    }[];
    immersions: {
        id: string;
        name: string;
        tactic: string | null;
    }[];
    mementos: {
        id: string;
    }[];
    users: UserSelectUserType[];
    // Input values
    datetime: DateTime;
    immersionId?: string;
    notes?: string;
    sessionParticipantMany?: string;
    participantId: string;
    showAllEvents: boolean;
}

const Session = (props: ParamsProps) => {
    const context = useGlobalContext();
    const trpc = useTRPC();
    const [state, setState] = useStateObject<SessionState>({
        deleted: false,
        error: "",
        fullyScheduled: false,
        nonce: 0,
        pending: false,
        saved: {
            immersionId: false,
            notes: false,
            participant: false,
            time: false,
            sessionParticipantMany: false,
        },
        flags: [],
        immersions: [],
        mementos: [],
        users: [],
        datetime: DateTime.utc(),
        participantId: "",
        showAllEvents: true,
    });
    const isDynamicGrouping = state.session?.groupingStrategy === "DYNAMIC";
    const isSuperAdmin = useIsSuperAdmin();

    const endpoint = `session/${props.params.sessionId}`;

    const { mutateAsync: getRecordingById } =
        trpc.session.getRecordingById.useMutation();

    const { mutateAsync: getChimeRecordingById } =
        trpc.session.getChimeRecordingById.useMutation();

    const warningDisclosure = useDisclosure();
    const [warningModalCallback, setWarningModalCallback] = useState<
        (() => Promise<void>) | null
    >(null);

    const componentDidMount = async () => {
        try {
            const session: SessionType = await context.get(endpoint);
            for (const event of session.events) {
                if (event.status === "FAILED") {
                    event.cancel = (
                        <Button
                            onClick={() => {
                                retryEvent(event.id);
                            }}
                            label={"Send again"}
                            disabled={state.pending}
                        />
                    );
                } else {
                    event.cancel = (
                        <Button
                            onClick={() => {
                                cancelEvent(event.id);
                            }}
                            label={"Cancel"}
                            disabled={
                                state.pending || event.status !== "PENDING"
                            }
                        />
                    );
                }

                event.sendImmediately = (
                    <Button
                        className={`${event.type}-${event.status}`}
                        onClick={() => {
                            sendEvent(event.id);
                        }}
                        label={"Send Immediately"}
                        disabled={state.pending || event.status !== "PENDING"}
                    />
                );
                event.complete = (
                    <Button
                        onClick={() => completeEvent(event.id)}
                        label={"Mark as Complete"}
                        disabled={state.pending || event.status !== "PENDING"}
                    />
                );
            }
            for (const recording of session.recordings) {
                const isDownloadable =
                    recording.twilioCompositionSid ||
                    recording.chimeConcatenationFinished;
                const recordingId =
                    recording.twilioCompositionSid ||
                    recording.chimeConcatenationMediaPipelineId;
                let downloadFunc = recording.chimeConcatenationFinished
                    ? getChimeRecordingById
                    : getRecordingById;
                recording.download = (
                    <Button
                        disabled={!isDownloadable}
                        onClick={async () =>
                            window.open(
                                await downloadFunc({
                                    recordingId: recordingId,
                                }),
                                "_blank",
                            )
                        }
                        label={"Download"}
                    />
                );
            }
            setState({
                session,
                // Set initial form values to fetched value
                datetime: DateTime.fromISO(session.time),
                immersionId: session?.immersion?.id,
                notes: session.notes,
                sessionParticipantMany: "",
                mementos: session.participants.map((p) => p.mementos).flat(),
                fullyScheduled: isFullyScheduled(session),
            });
            // Set context from fetched values
            context.setOrganizationId(session.organization.id);
            context.setOrganizationName(session.organization.name);
            context.setOrganizationTimezone(
                session.organization.timezone
                    ? session.organization.timezone.name
                    : "UTC",
            );
            // Fetch form values
            setState({
                users: (await context.get(`${endpoint}/users`)).sort(
                    (a: any, b: any) => {
                        return `${a.firstName} ${a.lastName}`.localeCompare(
                            `${b.firstName} ${b.lastName}`,
                        );
                    },
                ),
                pending: false,
            });
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
            });
        }
    };

    useEffect(() => {
        componentDidMount();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const { data: flagData } = trpc.flags.getSessionFlags.useQuery();

    useEffect(() => {
        setState({
            flags: flagData || [],
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flagData]);

    const sessionVisited = () =>
        state.session &&
        state.session.participants.some(
            (participant) => participant.firstVisitTimeslot !== null,
        );

    const { mutateAsync: updateEventById } =
        trpc.events.updateEventById.useMutation();

    const cancelEvent = async (eventId: string) => {
        setState({
            pending: true,
        });
        try {
            await updateEventById({
                id: eventId,
                status: "CANCELLED",
            });
            componentDidMount();
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const sendEvent = async (eventId: string) => {
        setState({
            pending: true,
        });
        try {
            componentDidMount();
            await updateEventById({
                id: eventId,
                sendImmediately: true,
            });
            componentDidMount();
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const retryEvent = async (eventId: string) => {
        setState({
            pending: true,
        });
        try {
            await updateEventById({
                id: eventId,
                status: "PENDING",
            });
            componentDidMount();
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const completeEvent = async (eventId: string) => {
        setState({
            pending: true,
        });
        try {
            await updateEventById({
                id: eventId,
                complete: true,
            });
            componentDidMount();
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const deleteSession = async () => {
        setState({
            pending: true,
        });
        try {
            await context.delete(endpoint);
            setState({
                deleted: true,
            });
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const put = async (key: string, value: any) => {
        setState({
            saved: {
                ...state.saved,
                [key]: false,
            },
        });
        setState({
            pending: true,
        });
        try {
            const response = await context.put(endpoint, {
                [key]: value,
            });
            componentDidMount();
            setState({
                saved: {
                    ...state.saved,
                    [key]: true,
                },
            });
            return response;
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const removeSessionParticipant = async (
        sessionParticipantId: string,
        silently = false,
    ) => {
        setState({
            pending: true,
        });
        try {
            await context.put(endpoint, {
                removeSessionParticipant: sessionParticipantId,
                silently,
            });
            componentDidMount();
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const setCohortId = async (cohortId: string) => {
        setState({
            pending: true,
        });
        try {
            await context.put(endpoint, {
                setCohortId: cohortId,
            });
            componentDidMount();
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const sendSessionParticipantTrialCodes = async (
        sessionParticipantId: string,
    ) => {
        setState({
            pending: true,
        });
        try {
            await context.put(endpoint, {
                sendSessionParticipantTrialCodes: sessionParticipantId,
            });
            componentDidMount();
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const { mutateAsync: updateBillingTypeAsync } =
        trpc.sessionParticipant.updateBillingType.useMutation();

    const updateBillingType = async (
        isFree: boolean,
        sessionParticipantId: string,
    ) => {
        setState({
            pending: true,
        });
        try {
            await updateBillingTypeAsync({
                sessionParticipantId,
                billingType: isFree ? BillingType.FREE : BillingType.STANDARD,
            });
            componentDidMount();
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const generateDialInCode = async (sessionParticipantId: string) => {
        setState({
            pending: true,
        });
        try {
            await context.put(endpoint, {
                generateDialInCode: sessionParticipantId,
            });
            componentDidMount();
        } catch (error) {
            setState({
                error: (error as any).message,
                nonce: state.nonce + 1,
                pending: false,
            });
        }
    };

    const deleteComponent = () =>
        state.session && (
            <div>
                <h4 className="text-base text-slate-400">Delete</h4>
                <div className="flex items-center mt-3">
                    {state.session.participants.length ||
                    state.session.events.some(
                        (event) => event.status === "COMPLETE",
                    ) ? (
                        <>
                            <p className="w-48 text-sm text-slate-400">
                                Sessions with participants or completed events
                                cannot be deleted.
                            </p>
                        </>
                    ) : (
                        <Button
                            disabled={state.pending}
                            onClick={deleteSession}
                            label={"Delete Session"}
                        />
                    )}
                </div>
            </div>
        );

    const accountAdminFlags = () => {
        return (
            state.session && (
                <Flex flexDir="column">
                    <Text
                        fontSize="sm"
                        color="gray.600"
                        fontWeight="medium"
                        marginBottom="10px"
                    >
                        Flags
                    </Text>
                    <Flex>
                        <Toggle
                            disabled
                            label="Dynamic Grouping"
                            value={state.session.groupingStrategy === "DYNAMIC"}
                            onChange={() => {}}
                        ></Toggle>
                        <Tooltip
                            text={`This session uses ${
                                state.session.groupingStrategy === "DYNAMIC"
                                    ? "dynamic"
                                    : "fixed"
                            } grouping. Participant grouping can only be set when creating a session.`}
                        ></Tooltip>
                    </Flex>
                </Flex>
            )
        );
    };

    const flags = () => {
        return (
            state.session && (
                <Flex flexDirection="column" gap="10px">
                    <Flags
                        disabled={state.pending}
                        flags={state.session.featureFlagOverrides}
                        allFlags={state.flags.filter(
                            (flag) =>
                                // don't show retired flags
                                !flag.retiredOn &&
                                // If a session is dynamic group, don't show some flags.
                                (!isDynamicGrouping ||
                                    ![
                                        "Author preview",
                                        "Always accessible",
                                        "Catch up",
                                    ].includes(flag.name)),
                        )}
                        onChange={(id: string, enabled: boolean) => {
                            if (enabled) {
                                put("flag", {
                                    id,
                                    enabled,
                                });
                            } else {
                                put("flag", {
                                    id,
                                    clear: true,
                                });
                            }
                        }}
                    />
                    <Toggle
                        disabled
                        label="Dynamic Grouping"
                        value={state.session.groupingStrategy === "DYNAMIC"}
                        onChange={() => {}}
                    ></Toggle>
                </Flex>
            )
        );
    };

    const getDateTime = () => {
        // Show the datetime in the picker in the current timezone
        return localizeTimeToJSDate(state.datetime, context.timezoneName);
    };

    const setDateTime = (localDatetime: Date | null) => {
        // Create a DateTime object from the local datetime
        // and the displayed timezone name
        if (localDatetime) {
            setState({
                datetime: convertLocalJSDateToDateTime(
                    localDatetime!,
                    context.timezoneName,
                ),
                saved: {
                    ...state.saved,
                    time: false,
                },
            });
        }
    };

    const notes = () => {
        return (
            state.session && (
                <div className="">
                    <label
                        htmlFor="notes"
                        className="flex text-sm font-medium text-gray-700"
                    >
                        Notes
                        <Tooltip text="Notes are only visible internally." />
                    </label>
                    <textarea
                        className={classNames(
                            "shadow-sm focus:ring-indigo-500 ",
                            "focus:border-indigo-500 block w-48",
                            "sm:text-sm border-gray-300 rounded-md mt-2",
                        )}
                        id="notes"
                        onChange={(event) => {
                            setState({
                                notes: event?.target.value,
                                saved: {
                                    ...state.saved,
                                    notes: false,
                                },
                            });
                        }}
                        name="notes"
                        rows={4}
                        value={state.notes || ""}
                    />
                    <div className="mt-4">
                        <ResetSaveButtons
                            disabled={
                                state.pending ||
                                state.session.notes === state.notes
                            }
                            onReset={() =>
                                setState({
                                    notes: state.session!.notes,
                                })
                            }
                            onSave={() => put("notes", state.notes)}
                            saved={state.saved.notes}
                        />
                    </div>
                </div>
            )
        );
    };

    const recordings = () => {
        return (
            state.session && (
                <div className="mt-12">
                    <h4 className="mt-6 text-base text-gray-500">Recordings</h4>
                    <Table
                        columns={[
                            ["createdOn", "Created On", timeParser],
                            [
                                "size",
                                "Size",
                                (size: number) => {
                                    return `${(size / 1000000).toFixed(2)} MB`;
                                },
                            ],
                            [
                                "duration",
                                "Duration",
                                (duration: number) => {
                                    return `${Math.floor(duration / 60)}m:${
                                        duration % 60
                                    }s`;
                                },
                            ],
                            ["twilioCompositionSid", "ID"],
                            ["download", "Download"],
                            ["groupId", "Group ID"],
                        ]}
                        data={{
                            1: state.session.recordings,
                            2: [],
                        }}
                        hidePages={true}
                        initialSortField={"createdOn"}
                        initialSortDirection={"asc"}
                        onPageChange={() => {}}
                        page={1}
                        sortable={true}
                    />
                </div>
            )
        );
    };

    const mementos = () => {
        return (
            state.session && (
                <div className="mt-12">
                    <h4 className="mt-6 text-base text-gray-500">Mementos</h4>
                    <Table
                        columns={[
                            ["createdOn", "Created On", timeParser],
                            ["participant", "Participant"],
                            ["recordId", "Record ID"],
                        ]}
                        data={{
                            1: state.mementos,
                            2: [],
                        }}
                        hidePages={true}
                        initialSortField={"createdOn"}
                        initialSortDirection={"asc"}
                        onPageChange={() => {}}
                        page={1}
                        sortable={true}
                    />
                </div>
            )
        );
    };

    const time = () => {
        return (
            state.session && (
                <>
                    <div className="flex items-stretch mt-12">
                        <div className="flex flex-row items-end">
                            <div>
                                <label
                                    htmlFor="time"
                                    className="flex mb-1 text-sm font-medium text-gray-700"
                                >
                                    Time ({context.timezoneName})
                                    <Tooltip
                                        warning={true}
                                        text="All emails will be scheduled again if the time is updated, but the reminder email will be skipped if there are less than 24 hours until the session."
                                    />
                                </label>
                                <DateTimePicker
                                    className="w-48 text-sm font-medium"
                                    calendarIcon={null}
                                    clearIcon={null}
                                    disableClock={true}
                                    locale="en-US"
                                    minDetail="month"
                                    onChange={setDateTime}
                                    format="y/M/d h:mm a"
                                    value={getDateTime()}
                                />
                            </div>
                            <div className="mx-2" />
                            <ResetSaveButtons
                                disabled={
                                    state.pending ||
                                    isoTimeSameMinute(
                                        state.session.time,
                                        convertDateTimeToISO(state.datetime)!,
                                    )
                                }
                                onReset={() =>
                                    setState({
                                        datetime: DateTime.fromISO(
                                            state.session!.time,
                                        ),
                                    })
                                }
                                onSave={() =>
                                    put("time", state.datetime.toISO())
                                }
                                saved={state.saved.time}
                            />
                        </div>
                    </div>
                    <div className="mt-2 ml-1 text-sm text-gray-500 w-96">
                        <div></div>
                    </div>
                </>
            )
        );
    };

    const actions = () => {
        return (
            state.session && (
                <div className="mt-12">
                    <div>Actions</div>
                    <div className="flex flex-col items-start mt-6 space-y-2">
                        {Object.entries(state.session.actionSchedule.email).map(
                            ([emailType, scheduledTime]) => (
                                <Button
                                    key={emailType}
                                    onClick={() => {
                                        put("action", {
                                            type: "email",
                                            data: {
                                                email: emailType,
                                            },
                                        });
                                    }}
                                    label={`Schedule ${emailType} emails for ${
                                        formatTime(
                                            scheduledTime,
                                            context.timezoneName,
                                        ) || "immediately"
                                    } ${
                                        emailType === "onboarding"
                                            ? " (immediately if live onboarding or first session)"
                                            : ""
                                    }`}
                                />
                            ),
                        )}
                        <Button
                            onClick={() => {
                                put("action", {
                                    type: "clone_resources",
                                });
                            }}
                            label={"Regenerate resources immediately"}
                        />
                        <Button
                            onClick={() => {
                                put("action", {
                                    type: "calendar_invites",
                                });
                            }}
                            label={"Send calendar invites immediately"}
                        />
                        <Button
                            onClick={() => {
                                put("action", {
                                    type: "cancel_events",
                                });
                            }}
                            label={"Cancel all pending events"}
                        />
                        {ROOT_USERS.includes(context.email || "") && (
                            <Button
                                onClick={() => {
                                    put("action", {
                                        type: "complete_events",
                                    });
                                }}
                                label={"Complete all pending events"}
                            />
                        )}
                    </div>
                </div>
            )
        );
    };

    const { ShowHideButton, show: showAdvanced, setHidden } = useShowHide();

    if (state.deleted) {
        return <Navigate to={`/sessions`} />;
    }

    if (!state.session) {
        return <Loading error={state.error} />;
    }

    const sessionUrl = `${getSparkwiseUrl()}/immersion/${state.session.id}/intro/0`;

    return (
        <div>
            <ErrorModal error={state.error} key={state.nonce} />
            <h4 className="mb-6 text-lg font-medium text-slate-800">Session</h4>
            <div className="flex items-stretch mt-3">
                <div className="lg:min-w-[600px] md:min-w-96">
                    <div className="flex flex-row items-end mr-4">
                        <div className="mr-4 w-full">
                            <label
                                htmlFor="time"
                                className="flex mb-1 text-sm font-medium text-gray-700"
                            >
                                Module
                                <Tooltip
                                    warning={true}
                                    text="Resources will automatically be regenerated if the
                        session is changed. Emails and calendar invites will be updated if they have not been sent already, but not sent again."
                                />
                            </label>
                            <ImmersionSelect
                                savedImmersionId={state.session.immersion.id}
                                savedImmersionName={
                                    state.session?.immersion.name
                                }
                                savedImmersionTopic={
                                    state.session?.immersion?.topic?.name
                                }
                                savedImmersionTactic={
                                    state.session?.immersion.tactic
                                }
                                selectedImmersionId={state.immersionId}
                                pending={state.pending}
                                onChange={(immersion) =>
                                    setState({
                                        immersionId: immersion
                                            ? immersion.id
                                            : undefined,
                                        saved: {
                                            ...state.saved,
                                            immersionId: false,
                                        },
                                    })
                                }
                                disabled={state.pending || sessionVisited()}
                                helpText={
                                    (sessionVisited() &&
                                        "The module for a visited session cannot be changed") ||
                                    ""
                                }
                                showClear={!sessionVisited()}
                                showSave={!sessionVisited()}
                                saveDisabled={
                                    state.pending ||
                                    state.saved.immersionId ||
                                    state.session.immersion.id ===
                                        state.immersionId ||
                                    !state.immersionId
                                }
                                saveLabel={
                                    state.saved.immersionId ? "Saved!" : "Save"
                                }
                                onSave={() =>
                                    put("immersionId", state.immersionId)
                                }
                            />
                        </div>
                    </div>
                    {time()}
                </div>
                {isSuperAdmin && <div className="mx-4 border-l" />}
                {isSuperAdmin ? flags() : accountAdminFlags()}
            </div>
            <SessionParticipants
                {...state}
                onUserChange={(user: any) =>
                    setState({
                        participantId: user ? user.id : "",
                    })
                }
                onUserSave={async (silently = false) => {
                    if (state.session) {
                        const timeslotDate = new Date(state.session.time);
                        const now = new Date();
                        if (timeslotDate < now) {
                            setWarningModalCallback(
                                () => async () =>
                                    await put("addSessionParticipant", {
                                        userId: state.participantId,
                                        silently,
                                    }),
                            );
                            warningDisclosure.onOpen();
                            return;
                        }
                        await put("addSessionParticipant", {
                            userId: state.participantId,
                            silently,
                        });
                    }
                    return;
                }}
                onSessionParticipantManyChange={(event) => {
                    setState({
                        sessionParticipantMany: event?.target.value,
                        saved: {
                            ...state.saved,
                            sessionParticipantMany: false,
                        },
                    });
                }}
                onAddParticipants={async (silently = false) => {
                    const { notFound } = await put("sessionParticipantMany", {
                        emailString: state.sessionParticipantMany,
                        silently,
                    });
                    if (notFound.length) {
                        setState({
                            error: `The following emails were not found: ${notFound.join(
                                ", ",
                            )}`,
                            nonce: state.nonce + 1,
                        });
                    }
                    // in case of error, still reload
                    await componentDidMount();
                }}
                generateDialInCode={generateDialInCode}
                removeSessionParticipant={removeSessionParticipant}
                sendSessionParticipantTrialCodes={
                    sendSessionParticipantTrialCodes
                }
                updateBillingType={updateBillingType}
                refetch={componentDidMount}
                onSetCohortId={(cohortId) => setCohortId(cohortId)}
            />
            {isSuperAdmin && (
                <div className="flex mt-10">
                    <Button
                        // TODO: pull this onClick logic into a function in a model file
                        label="Schedule emails and calendar invites"
                        onClick={async () => {
                            setState({ pending: true });
                            Object.entries(
                                state.session!.actionSchedule.email,
                            ).map(async ([emailType, _]) => {
                                await put("action", {
                                    type: "email",
                                    data: {
                                        email: emailType,
                                    },
                                });
                            });
                            await put("action", {
                                type: "calendar_invites",
                            });
                            setState({ pending: false });
                        }}
                        disabled={state.pending || state.fullyScheduled}
                        requiresConfirmation
                    />
                    <Tooltip
                        warning={true}
                        text="Some events may not be scheduled if the session time is in the past or too soon."
                    />
                </div>
            )}
            <div className="w-full mt-10 mb-10 border-t-2 border-slate-400" />
            {isSuperAdmin && (
                <div className="pl-2 ml-2 border-l-2 border-slate-300">
                    🚪 Enter session at{" "}
                    <a
                        href={sessionUrl}
                        className="text-blue-600 "
                        target={"_blank"}
                        rel="noreferrer"
                    >
                        {sessionUrl}
                        <ArrowTopRightOnSquareIcon className="inline w-4 h-4 align-top" />
                    </a>
                </div>
            )}
            {isSuperAdmin && recordings()}
            {isSuperAdmin && mementos()}
            {!showAdvanced && <div className="mt-10" />}
            {showAdvanced && (
                <Button
                    className="mt-6"
                    onClick={() => setHidden()}
                    secondary
                    label="Hide Advanced"
                />
            )}
            {isSuperAdmin && (
                <ShowHideButton
                    label="Show Advanced"
                    component={
                        <>
                            {actions()}
                            <div className="my-8" />
                            <Toggle
                                label="Show completed/cancelled events"
                                value={state.showAllEvents}
                                onChange={(value) =>
                                    setState({
                                        showAllEvents: !state.showAllEvents,
                                    })
                                }
                            />
                            <EventsTable
                                events={state.session.events.filter(
                                    (event) =>
                                        !(
                                            event.status === "COMPLETE" ||
                                            event.status === "CANCELLED"
                                        ) || state.showAllEvents,
                                )}
                            />
                        </>
                    }
                />
            )}
            <div className="flex mt-10 mb-10">{isSuperAdmin && notes()}</div>
            {isSuperAdmin && deleteComponent()}
            <ParticipantWarningModal
                isOpen={!!(warningDisclosure.isOpen && warningModalCallback)}
                onClose={warningDisclosure.onClose}
                callback={warningModalCallback}
            />
        </div>
    );
};

const ParticipantWarningModal: React.FC<{
    onClose: () => void;
    isOpen: boolean;
    callback: (() => Promise<void>) | null;
}> = ({ onClose, isOpen, callback }) => {
    return (
        <Modal isCentered isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                borderRadius={"20px"}
                pt="24px"
                pb="12px"
                px="28px"
                width={"520px"}
                maxWidth={"520px"}
            >
                <ModalBody p={0}>
                    <Flex mb="16px">
                        <Flex
                            bg="info.-5"
                            p={"3px"}
                            borderRadius={"4px"}
                            boxSize={"32px"}
                            alignItems={"center"}
                            justifyContent={"center"}
                            color="info.1"
                        >
                            <ExclamationTriangleIcon height="24px" />
                        </Flex>
                        <Text
                            fontSize={"22px"}
                            lineHeight={"28px"}
                            fontWeight={600}
                            ml={2}
                        >
                            Warning
                        </Text>
                    </Flex>
                    <Text fontSize={"14px"} fontWeight={400}>
                       This session's start time is in the past.
                    </Text>
                    <Text fontSize={"14px"} fontWeight={400} mb="24px">
                        If you continue, any work done so far by participants will be lost.
                        Please instruct all participants to refresh their browser.
                    </Text>
                </ModalBody>
                <ModalFooter p={0}>
                    <ChakraButton
                        onClick={() => {
                            if (callback) {
                                callback();
                            }
                            onClose();
                        }}
                        variant="ghost"
                        mr="20px"
                    >
                        Continue
                    </ChakraButton>
                    <ChakraButton onClick={onClose} colorScheme={"black"}>
                        Cancel
                    </ChakraButton>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default withParams(Session);
