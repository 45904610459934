import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";

const AuditLink = ({ modelId }: { modelId: string }) => (
    <div>
        <a
            className={"flex items-center font-bold text-sm text-slate-400"}
            href={`${window.location.origin}/audit-log/${modelId}`}
            target="_blank"
            rel="noreferrer"
        >
            Audit Log
            <DocumentDuplicateIcon className="ml-1 w-4 h-4 text-slate-400" />
        </a>
    </div>
);

export default AuditLink;
