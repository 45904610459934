import {
    Box,
    Checkbox,
    CheckboxGroup,
    Flex,
    Skeleton,
    Stack,
    Text,
} from "@chakra-ui/react";
import {
    CalendarDaysIcon,
    RectangleGroupIcon,
    UserGroupIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import { useInsights } from "./InsightsContext";
import { InsightsSelect } from "./InsightsSelect";
import { useInsightsImmersions } from "../../hooks/useInsightsImmersions";
import { useInsightsUsers } from "../../hooks/useInsightsUsers";
import { useInsightsCohorts } from "../../hooks/useInsightsCohorts";

export const InsightsSidebar: React.FC = () => {
    const {
        state,
        showEtherpad,
        showExcalidraw,
        showTranscripts,
        setDateFilter,
        setCohortId,
        loading,
        setUserId,
    } = useInsights();
    const immersions = useInsightsImmersions();
    const cohorts = useInsightsCohorts();
    // @ts-ignore
    const selectedCohort = cohorts?.find(
        (_) => _.item.id === state.filters.cohortId,
    );
    const users = useInsightsUsers();
    const selectedUser = users?.find(
        (_) => _.item.userId === state.filters.userIds?.at(0),
    );
    const isReady = !!immersions && !!cohorts;
    const days = [
        {
            name: "Last 24 hours",
            id: "1",
        },
        {
            name: "Last 7 days",
            id: "7",
        },
        {
            name: "Last 30 days",
            id: "30",
        },
        {
            name: "Last 365 days",
            id: "365",
        },
    ];
    if (!state.filters.moduleId) {
        return null;
    }
    const selectedDate = days.find(
        (_) => _.id === state.filters.date.toString(),
    );
    return (
        <Flex
            basis={"300px"}
            grow={0}
            shrink={0}
            p={"24px"}
            direction={"column"}
            id={"insights-sidebar"}
        >
            <Box mb="48px">
                <Text
                    as="h3"
                    color="black"
                    fontSize={"18px"}
                    fontWeight={600}
                    lineHeight={"25px"}
                    mb={"24px"}
                    sx={{
                        "@media print": {
                            display: "none",
                        },
                    }}
                >
                    Session filters
                </Text>
                <Skeleton isLoaded={isReady}>
                    <InsightsSelect
                        initialItem={
                            selectedDate
                                ? {
                                      item: selectedDate,
                                      key: selectedDate.id,
                                  }
                                : undefined
                        }
                        itemToString={(_) => _?.item.name || ""}
                        items={days.map((_) => ({
                            item: _,
                            key: _.id,
                        }))}
                        onChange={(value) => {
                            if (value && !isNaN(parseInt(value.item.id))) {
                                setDateFilter(parseInt(value.item.id));
                            }
                        }}
                        leftHandIcon={<CalendarDaysIcon height={"18px"} />}
                        checkOnActive
                    />
                </Skeleton>
                <Box mt="12px" />
                <Skeleton isLoaded={isReady}>
                    <InsightsSelect
                        searchable
                        clearable
                        itemToString={(_) => _?.item.name || ""}
                        placeholder="All cohorts"
                        items={cohorts || []}
                        onChange={(value) => {
                            if (value) {
                                setCohortId(value.item.id);
                            } else {
                                setCohortId(undefined);
                            }
                        }}
                        leftHandIcon={<RectangleGroupIcon height={"18px"} />}
                        checkOnActive
                        initialItem={selectedCohort}
                    />
                </Skeleton>
                <Box mt="12px" />
                <Skeleton isLoaded={isReady}>
                    <InsightsSelect
                        clearable
                        itemToString={(_) => _?.item.name || ""}
                        inputItemToString={(_) => {
                            if (!_) {
                                return "All groups";
                            }
                            return _?.item.firstName + "'s groups";
                        }}
                        placeholder="All groups"
                        subtitle={"Groups for:"}
                        items={users || []}
                        onChange={(value) => {
                            if (value) {
                                setUserId(value.item.userId);
                            } else {
                                setUserId(undefined);
                            }
                        }}
                        initialItem={selectedUser}
                        leftHandIcon={<UserGroupIcon height={"18px"} />}
                        checkOnActive
                    />
                </Skeleton>
            </Box>
            <Box>
                <Text
                    as="h3"
                    color="black"
                    fontSize={"18px"}
                    fontWeight={600}
                    lineHeight={"25px"}
                    mb={"24px"}
                >
                    Resource types
                </Text>
                <CheckboxGroup
                    onChange={(values) => {
                        showEtherpad(values.includes("etherpad"));
                        showTranscripts(values.includes("transcript"));
                        showExcalidraw(values.includes("excalidraw"));
                    }}
                    defaultValue={[
                        state.filters.showEtherpad ? "etherpad" : "",
                        state.filters.showTranscripts ? "transcript" : "",
                        state.filters.showExcalidraw ? "excalidraw" : "",
                    ]}
                >
                    <Stack direction={"column"} spacing="20px" ml="12px">
                        <Checkbox value="transcript">
                            <Skeleton isLoaded={!loading}>
                                Conversations{" "}
                                <Text as="span" color="gray.300">
                                    ({state.transcriptCount})
                                </Text>
                            </Skeleton>
                        </Checkbox>
                        <Checkbox value="etherpad">
                            <Skeleton isLoaded={!loading}>
                                Notepads{" "}
                                <Text as="span" color="gray.300">
                                    ({state.etherpadCount})
                                </Text>
                            </Skeleton>
                        </Checkbox>
                        <Checkbox value="excalidraw">
                            <Skeleton isLoaded={!loading}>
                                Whiteboards{" "}
                                <Text as="span" color="gray.300">
                                    ({state.excalidrawCount})
                                </Text>
                            </Skeleton>
                        </Checkbox>
                    </Stack>
                </CheckboxGroup>
            </Box>
        </Flex>
    );
};
