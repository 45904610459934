import {
    Button,
    Flex,
    Heading,
    Modal,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Text,
    TextProps,
} from "@chakra-ui/react";
import { ArrowDownTrayIcon, PrinterIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useInsights } from "./InsightsContext";

export const ExportToAIModal: React.FC<{
    isOpen: boolean;
    onClose: () => void;
}> = ({ isOpen, onClose }) => {
    const { downloadJSONForAI, expandAll, setShowMetadata } = useInsights();
    const pStyle: TextProps = {
        lineHeight: "22px",
        mb: "22px",
        fontWeight: 400,
        fontSize: "14px",
    };
    return (
        <Modal isOpen={isOpen} onClose={onClose}>
            <ModalOverlay />
            <ModalContent
                minW={["auto", "600px"]}
                w={["80%"]}
                p={"20px"}
                borderRadius={"20px"}
            >
                <ModalHeader>
                    <Heading fontWeight="bold" fontSize="22px">
                        Export
                    </Heading>
                </ModalHeader>
                <ModalBody mt={"4px"}>
                    <Text {...pStyle}>
                        Export your Sparkwise data to explore it with your organization's internal AI tools.
                    </Text>
                    <Text {...pStyle}>
                        You will download a ".zip" archive with all resources matching your filters, with additional metadata and prompts to support AI prompting. 
                    </Text>
                    <Text {...pStyle}>
                        Open the archive file and upload the “data.json” file to your preferred AI tool alongside one of the 3 prompts. Check "README.txt" for more details and help in selecting the right prompt for your model.
                    </Text>
                </ModalBody>
                <ModalFooter pb={0}>
                    <Flex
                        w={"100%"}
                        justifyContent={"flex-end"}
                        direction={["column", "row"]}
                        gap={"16px"}
                    >
                        <Button
                            onClick={onClose}
                            variant="ghost"
                            colorScheme="gray"
                            fontWeight={400}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="outline"
                            colorScheme="gray"
                            fontWeight={400}
                            onClick={async () => {
                                onClose();
                                expandAll();
                                setShowMetadata(true);
                                setTimeout(window.print, 250);
                            }}
                        >
                            <PrinterIcon height={"20px"} />
                            <Text ml={2}>Print</Text>
                        </Button>
                        <Button
                            onClick={async () => {
                                await downloadJSONForAI();
                                onClose();
                            }}
                            variant="solid"
                            colorScheme="blue"
                        >
                            <ArrowDownTrayIcon height={"20px"} />
                            <Text ml={2}>Export</Text>
                        </Button>
                    </Flex>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
