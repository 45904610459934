import { Box, Button, Flex, Skeleton, Text } from "@chakra-ui/react";
import { Transition } from "@headlessui/react";
import { forwardRef, PropsWithChildren, useState } from "react";
import {
    InsightsArtifactMetadata,
    InsightsArtifactMetadataProps,
} from "./InsightsArtifactMetadata";
import { InsightsArtifactType, InsightsParticipant } from "./InsightsContext";
import { Participants } from "./Participants";
import { ReactComponent as UpDownArrow } from "design/assets/UpDownArrow.svg";

export interface ArtifactCardProps {
    showMetadata?: boolean;
    expanded?: boolean;
    onExpand?: () => void;
    onCollapse?: () => void;
}

export const ArtifactCard = forwardRef<
    HTMLDivElement,
    PropsWithChildren<
        {
            icon?: React.ReactElement;
            title: string;
            subtitle?: string | React.ReactElement | null;
            groupType?: "shared" | "individual";
            showMetadata?: boolean;
            metadata?: InsightsArtifactMetadataProps;
            isLoading?: boolean;
            participants: InsightsParticipant[];
            type: InsightsArtifactType;
        } & ArtifactCardProps
    >
>((props, ref) => {
    const {
        children,
        icon,
        title,
        subtitle,
        groupType,
        showMetadata,
        metadata,
        isLoading,
        expanded,
        participants,
        type,
        onExpand,
        onCollapse,
    } = props;
    const [isHovered, setIsHovered] = useState(false);
    return (
        <Box
            bg={"white"}
            borderRadius={"12px"}
            border={"1px solid"}
            borderColor={"gray.100"}
            mb="24px"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            overflow={"hidden"}
            boxShadow={"0px 6px 20px 0px rgba(0, 0, 0, 0.04);"}
            className="artifact-card"
            sx={{
                "@media print": {
                    marginTop: "2cm",
                }
            }}
        >
            <Box
                paddingX="24px"
                paddingY="16px"
                borderBottom={"1px solid"}
                borderColor={"gray.100"}
            >
                <Flex direction="row" justifyContent={"space-between"}>
                    <Flex direction={["column", "row"]} gap={["8px"]} grow={1}>
                        <Flex direction={"row"} wrap={"wrap"} gap={["8px", 0]}>
                            <Skeleton isLoaded={!isLoading}>
                                <Text
                                    fontSize={"18px"}
                                    fontWeight={600}
                                    display={"inline"}
                                    whiteSpace={"nowrap"}
                                >
                                    {icon}
                                    {title}
                                </Text>
                            </Skeleton>
                            {subtitle && (
                                <Box
                                    borderLeft={"1px solid"}
                                    borderColor={"gray.300"}
                                    width={0}
                                    height={"30px"}
                                    display={["none", "inline-block"]}
                                    mb={"-8px"}
                                    marginX={"0.5rem"}
                                />
                            )}
                            <Skeleton isLoaded={!isLoading} mt="2px">
                                {subtitle && (
                                    <Text
                                        fontWeight={400}
                                        color={"gray.900"}
                                        display={"inline"}
                                        fontSize={"16px"}
                                    >
                                        {subtitle}
                                    </Text>
                                )}
                            </Skeleton>
                        </Flex>
                        <Participants
                            participants={participants}
                            groupType={groupType}
                            colored={
                                groupType === "shared" &&
                                ["ETHERPAD", "EXCALIDRAW"].includes(type)
                            }
                        />
                    </Flex>
                </Flex>
            </Box>
            <Transition.Root show={showMetadata || false}>
                <Transition.Child
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    {metadata && <InsightsArtifactMetadata {...metadata} />}
                </Transition.Child>
            </Transition.Root>
            <Box
                maxH={expanded ? undefined : "320px"}
                overflowY={expanded ? undefined : "hidden"}
                ref={ref}
                position={"relative"}
            >
                {children}
                {!expanded && (
                    <Box
                        position={"absolute"}
                        bottom={0}
                        left={0}
                        width={"100%"}
                        height={"30%"}
                        bg={
                            "linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);"
                        }
                    />
                )}
                {isHovered && !expanded && onExpand && (
                    <Flex
                        position="absolute"
                        bottom="0"
                        width={"100%"}
                        justify={"center"}
                        sx={{
                            "@media print": {
                                "display": "none",
                            },
                        }}
                    >
                        <Button
                            bg={"gray.800"}
                            color={"white"}
                            borderRadius={"80px"}
                            mb="8px"
                            fontSize={"13px"}
                            _hover={{ backgroundColor: "black" }}
                            onClick={onExpand}
                        >
                            <UpDownArrow
                                height={"16px"}
                                style={{
                                    marginTop: "-2px",
                                    marginRight: "2px",
                                }}
                            />{" "}
                            Expand
                        </Button>
                    </Flex>
                )}
                {isHovered && expanded && onCollapse && (
                    <Flex
                        position="absolute"
                        bottom="0"
                        width={"100%"}
                        justify={"center"}
                        sx={{
                            "@media print": {
                                "display": "none",
                            },
                        }}
                    >
                        <Button
                            bg={"gray.800"}
                            color={"white"}
                            borderRadius={"80px"}
                            mb="8px"
                            fontSize={"13px"}
                            _hover={{ backgroundColor: "black" }}
                            onClick={onCollapse}
                        >
                            <UpDownArrow
                                height={"16px"}
                                style={{
                                    marginTop: "-2px",
                                    marginRight: "2px",
                                }}
                            />{" "}
                            Collapse
                        </Button>
                    </Flex>
                )}
            </Box>
        </Box>
    );
});
