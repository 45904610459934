import { ArrowPathIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useGlobalContext } from "../context";
import { useTRPC } from "../hooks/useTRPC";

const Info = () => {
    const context = useGlobalContext();
    const [apiServer, setApiServer] = useState<string | undefined>();
    const [version, setVersion] = useState<string | undefined>();

    const trpc = useTRPC();
    const { data: info } = trpc.info.userInfo.useQuery({
        email: context.email || "",
    });

    const componentDidMount = async () => {
        setApiServer(process.env.REACT_APP_API_SERVER);
        setVersion(process.env.COMMIT);
    };

    useEffect(() => {
        componentDidMount();
    }, []);

    if (!info) {
        return (
            <div className="px-4 sm:px-6 lg:px-8">
                <div className="sm:flex sm:items-center">
                    <div className="sm:flex-auto">
                        <h1 className="text-xl font-semibold text-gray-900">
                            Info
                        </h1>
                        <div className="bg-green-50 border-l-4 border-green-400 p-4 mt-3">
                            <div className="flex">
                                <div className="flex-shrink-0">
                                    <ArrowPathIcon
                                        className="h-5 w-5 text-green-400"
                                        aria-hidden="true"
                                    />
                                </div>
                                <div className="ml-3">
                                    <p className="text-sm text-green-700">
                                        Loading ...
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className="px-4 sm:px-6 lg:px-8">
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900">
                        Info
                    </h1>
                </div>
            </div>
            <div className="mt-8 flex flex-col">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8 font-mono">
                        <pre>
                            {JSON.stringify(
                                { info, apiServer, version },
                                null,
                                4,
                            )}
                        </pre>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Info;
