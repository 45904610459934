import { ReactNode } from "react";
import Button from "../common/Button";

interface Props {
    disabled: boolean;
    onReset: () => void;
    onSave: () => void;
    saved: boolean;
    resetLabel?: string;
    saveLabel?: string;
    savedLabel?: string;
    additionalControls?: ReactNode;
}

const ResetSaveButtons = ({
    disabled,
    onReset,
    onSave,
    saved,
    resetLabel,
    saveLabel,
    savedLabel,
    additionalControls,
}: Props) => (
    <div className="flex flex-row gap-2">
        <Button
            disabled={disabled}
            onClick={onSave}
            label={saved ? savedLabel || "Saved!" : saveLabel || "Save"}
        />
        <Button
            disabled={disabled}
            onClick={onReset}
            label={resetLabel || "Reset"}
            secondary={true}
        />
        {additionalControls}
    </div>
);

export default ResetSaveButtons;
