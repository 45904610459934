import { useState } from "react";
import { useGlobalContext } from "../context";
import { trpc } from "../hooks/useTRPC";
import { useUserHasPermission } from "../hooks/useUserData";
import { Session } from "./Types";
import {
    Box,
    Button,
    ButtonGroup,
    Flex,
    ListItem,
    Text,
    UnorderedList,
} from "@chakra-ui/react";
import Downshift from "downshift";
import {
    CheckIcon,
    ChevronDownIcon,
    PencilIcon,
} from "@heroicons/react/24/outline";

type Cohort = {
    id: string;
    name: string;
};

const allParticipants: Cohort = {
    id: "",
    name: "Account",
};

export const SessionParticipantsCohort: React.FC<{
    session: Session;
    onSetCohortId: (cohortId: string | null) => void;
}> = ({ session, onSetCohortId }) => {
    const [editorOpen, setEditorOpen] = useState(false);
    const [selectedCohortId, setSelectedCohortId] = useState<string | null>(
        session.cohort?.id || null,
    );
    const [showPencil, setShowPencil] = useState(false);
    const { organizationId } = useGlobalContext();
    const hasCohortsPermissions = useUserHasPermission("cohorts");
    const { data: rawCohorts } = trpc.cohorts.getCohorts.useQuery(
        {
            organizationId: organizationId || "",
        },
        { enabled: hasCohortsPermissions && !!organizationId },
    );
    const cohortName = session?.cohort?.name || allParticipants.name;
    const cohorts: Cohort[] = rawCohorts || [];
    return (
        <Flex mt="6" height={"24px"}>
            <Flex
                grow={0}
                onMouseEnter={() => setShowPencil(true)}
                onMouseLeave={() => setShowPencil(false)}
            >
                <Text color="dark.800" fontWeight={600} fontSize={"16px"}>
                    Participants in{" "}
                    <Text
                        cursor={editorOpen ? "default" : "pointer"}
                        display={"inline"}
                        textDecoration={showPencil ? "underline" : undefined}
                        onClick={() => setEditorOpen(true)}
                    >
                        {!editorOpen ? cohortName : ""}
                    </Text>
                </Text>
                {!editorOpen && showPencil && (
                    <Flex
                        width={"32px"}
                        height={"32px"}
                        justifyContent={"center"}
                        alignItems={"center"}
                        borderRadius={"6px"}
                        mt="-5px"
                        ml={2}
                        cursor={"pointer"}
                        _hover={{
                            bg: "gray.50",
                        }}
                        color={"blue.500"}
                        onClick={() => setEditorOpen(true)}
                    >
                        <PencilIcon height={"16px"} />
                    </Flex>
                )}
            </Flex>

            {editorOpen ? (
                <Flex mt="-5px">
                    <CohortsSelect
                        items={cohorts}
                        initialSelection={
                            cohorts.find((_) => _.id === selectedCohortId) ||
                            allParticipants
                        }
                        onChange={(_) => {
                            if (_) {
                                if (_.id === "") {
                                    setSelectedCohortId(null);
                                } else {
                                    setSelectedCohortId(_.id);
                                }
                            }
                        }}
                    />
                    <Box ml={2}>
                        <ButtonGroup
                            isAttached
                            variant={"outline"}
                            size={"sm"}
                            boxShadow={"0 2px 8px rgba(0,0,0, .08)"}
                            borderColor={"gray.100"}
                        >
                            <Button
                                fontWeight={400}
                                fontSize={"12px"}
                                onClick={() => setEditorOpen(false)}
                            >
                                Cancel
                            </Button>
                            <Button
                                bg="blue.50"
                                color="blue.500"
                                fontWeight={400}
                                fontSize={"12px"}
                                borderLeft={0}
                                onClick={() => {
                                    onSetCohortId(selectedCohortId);
                                    setEditorOpen(false);
                                }}
                            >
                                Save
                            </Button>
                        </ButtonGroup>
                    </Box>
                </Flex>
            ) : null}
        </Flex>
    );
};

const CohortsSelect: React.FC<{
    items: Cohort[];
    onChange?: (item: Cohort | null) => void;
    initialSelection?: Cohort;
}> = ({ items: _items, onChange, initialSelection }) => {
    const items = [allParticipants, ..._items];
    return (
        <Box ml="1">
            <Downshift
                itemToString={(item: Cohort | null) => item?.name || ""}
                onChange={onChange}
                initialSelectedItem={initialSelection}
            >
                {({
                    getRootProps,
                    getItemProps,
                    selectedItem,
                    itemToString,
                    openMenu,
                    isOpen,
                    highlightedIndex,
                }) => {
                    return (
                        <Box {...getRootProps()}>
                            <Flex
                                cursor={"pointer"}
                                onFocus={() => openMenu()}
                                onClick={() => openMenu()}
                                bg={"white"}
                                height={"32px"}
                                py="6px"
                                px="8px"
                                border="1px solid"
                                borderColor={isOpen ? "gray.800" : "gray.200"}
                                borderRadius={"6px"}
                                justifyContent={"space-between"}
                                minW={"200px"}
                            >
                                <Text
                                    fontSize={"16px"}
                                    lineHeight={"19px"}
                                    fontWeight={600}
                                    color="dark.800"
                                >
                                    {itemToString(selectedItem)}
                                </Text>
                                <ChevronDownIcon width="16px" />
                            </Flex>
                            {isOpen ? (
                                <Box
                                    position={"absolute"}
                                    zIndex={100}
                                    mt="6px"
                                    p={0}
                                    bg="white"
                                    borderRadius={"8px"}
                                    borderColor={"gray.200"}
                                    borderStyle={"solid"}
                                    borderWidth={"1px"}
                                    display={"block"}
                                    maxHeight={"200px"}
                                    minHeight={"60px"}
                                    overflow={"scroll"}
                                >
                                    <UnorderedList
                                        overflow={"scroll"}
                                        styleType={"none"}
                                        paddingY={"4px"}
                                        paddingX={0}
                                        m={0}
                                    >
                                        {items.map((item, index) => (
                                            <ListItem
                                                {...getItemProps({
                                                    item,
                                                })}
                                                key={item.id}
                                                cursor={"pointer"}
                                                paddingX={"12px"}
                                                paddingY={"10px"}
                                                fontSize={"14px"}
                                                fontWeight={400}
                                                lineHeight={"17px"}
                                                color={
                                                    selectedItem?.id === item.id
                                                        ? "blue.500"
                                                        : "gray.800"
                                                }
                                                bg={
                                                    highlightedIndex === index
                                                        ? "gray.50"
                                                        : "white"
                                                }
                                            >
                                                <Flex>
                                                    {selectedItem?.id ===
                                                    item.id ? (
                                                        <Box
                                                            mr={2}
                                                            width="17px"
                                                        >
                                                            <CheckIcon height="17px" />
                                                        </Box>
                                                    ) : (
                                                        <Box
                                                            mr={2}
                                                            width={"17px"}
                                                        ></Box>
                                                    )}
                                                    {itemToString(item)}
                                                </Flex>
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Box>
                            ) : null}
                        </Box>
                    );
                }}
            </Downshift>
        </Box>
    );
};
