import { Box, Flex, Text } from "@chakra-ui/react";
import { useInsights } from "./InsightsContext";

export const InsightsHeader: React.FC = () => {
    const { total, state } = useInsights();
    const label = (() => {
        if (total === 0) {
            return "No resources";
        } else if (total === 1) {
            return "1 resource";
        } else {
            return `${total} resources`;
        }
    })();
    return (
        <Flex
            paddingX={"24px"}
            paddingTop={"24px"}
            paddingBottom="16px"
            borderBottom="1px solid"
            borderColor={"gray.100"}
            borderTopRadius={"12px"}
            justifyContent={"space-between"}
            transition={"background-color 0.2s ease-in-out"}
            boxShadow={
                state.isScrolled ? "0 2px 4px rgba(0, 0, 0, 0.05)" : undefined
            }
            zIndex={1}
            display={["none", "flex"]}
        >
            <Box mb={["4px", 0]}>
                <Text fontSize={"18px"} color={"gray.900"} fontWeight={600}>
                    {label}
                </Text>
            </Box>
        </Flex>
    );
};
