import { Button, TabPanel } from "@chakra-ui/react";

export const ManagerTab = ({
    sendSampleEmail,
}: {
    sendSampleEmail: () => Promise<void>;
}) => {
    return (
        <TabPanel>
            <Button onClick={sendSampleEmail}>Send me a preview</Button>
        </TabPanel>
    );
};
