import {
    ExclamationTriangleIcon,
    QuestionMarkCircleIcon,
} from "@heroicons/react/24/outline";

interface Props {
    warning?: boolean;
    text: string;
    stretchToFit?: boolean;
}

const Tooltip = ({ warning, text, stretchToFit }: Props) => (
    <div className="group ml-1">
        {warning ? (
            <ExclamationTriangleIcon className="text-gray-500 cursor-pointer w-5 h-5 hover:text-orange-500" />
        ) : (
            <QuestionMarkCircleIcon className="text-gray-500 cursor-pointer w-5 h-5" />
        )}
        <div
            className={`absolute z-20 mt-2 p-2 text-sm text-gray-700 bg-white border border-gray-300 rounded shadow-lg group-hover:block hidden ${stretchToFit ? "" : "max-w-xs"} font-normal break-normal whitespace-pre-line`}
        >
            {text}
        </div>
    </div>
);

export default Tooltip;
