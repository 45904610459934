import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

interface Props {
    title: string;
    subtitle: string;
    cta?: React.ReactNode;
}

const ErrorCard = ({ title, subtitle, cta }: Props) => {
    return (
        <div className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
            <div>
                <div className="mx-auto flex h-12 w-12 items-center justify-center rounded-full bg-red-100">
                    <ExclamationTriangleIcon className="pb-1 w-8 h-8 text-red-900" />
                </div>
                <div className="mt-3 text-center sm:mt-5">
                    <h3
                        className="text-lg font-medium leading-6 text-gray-900"
                        id="modal-title"
                    >
                        {title}
                    </h3>
                    <div className="mt-2">
                        <p className="text-sm text-gray-500">{subtitle}</p>
                    </div>
                </div>
            </div>
            {cta}
        </div>
    );
};

export default ErrorCard;
