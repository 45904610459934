import { useEffect, useState } from "react";
import Button from "../components/common/Button";
import Form from "../components/common/Form";
import Loading from "../components/common/Loading";
import Table, { TableData } from "../components/common/Table";
import { Navigate } from "react-router-dom";
import useShowHide from "../hooks/useShowHide";
import { useTRPC } from "../hooks/useTRPC";
import { useUserData } from "../hooks/useUserData";

const Orgs = () => {
    const trpc = useTRPC();
    const userData = useUserData();

    const [addAccountName, setAddAcountName] = useState("");
    const [page, setPage] = useState(0);
    const [pending, setPending] = useState(false);
    const [error, setError] = useState("");

    const { data: orgs } = trpc.orgs.getOrgsByPage.useQuery({});

    const { show, setHidden, ShowHideButton } = useShowHide();

    const { data: csvData } = trpc.data.generateParticipantCsv.useQuery(
        undefined,
        {
            enabled: userData.isSuccess && userData.data.isSparkwiseAdmin,
        },
    );

    const onFetchCsvData = async () => {
        if (!csvData) return;
        setPending(true);
        // Save data to PC
        const link = document.createElement("a");
        link.href =
            "data:text/csv;charset=utf-8," + encodeURIComponent(csvData);
        link.download = "data.csv";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setPending(false);
    };

    const { mutateAsync: createOrg } = trpc.org.createOrg.useMutation();

    const [addedOrg, setAddedOrg] = useState("");

    const [orgData, setOrgData] = useState<TableData | null>(null);

    useEffect(() => {
        setOrgData({
            ...orgData,
            [page]: [...(orgs || [])]?.map((org) => ({
                ...org,
                name: {
                    value: org.name,
                    link: `/account/${org.id}`,
                },
                customer: org.featureFlagOverrides.some(
                    (flag: { featureFlag: { name: string }; enabled: any }) =>
                        flag.featureFlag.name === "Customer" && flag.enabled,
                )
                    ? "✅"
                    : "❌",
                recordSessions: org.featureFlagOverrides.some(
                    (flag: { featureFlag: { name: string }; enabled: any }) =>
                        flag.featureFlag.name === "Record sessions" &&
                        flag.enabled,
                )
                    ? "✅"
                    : "❌",
                sendManagerEmails: org.featureFlagOverrides.some(
                    (flag: { featureFlag: { name: string }; enabled: any }) =>
                        flag.featureFlag.name === "Send manager emails" &&
                        flag.enabled,
                )
                    ? "✅"
                    : "❌",
            })),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orgs]);

    if (userData.isSuccess && !userData.data.isSparkwiseAdmin) {
        if (userData.data.permissions.sessions.value) {
            return (
                <Navigate to={`/sessions/${userData.data.organizationId}`} />
            );
        }
        if (userData.data.permissions.users.value) {
            return <Navigate to={`/users/${userData.data.organizationId}`} />;
        }
        if (userData.data.permissions.cohorts.value) {
            return <Navigate to={`/cohorts/${userData.data.organizationId}`} />;
        }
        if (userData.data.permissions.communications.value) {
            return (
                <Navigate
                    to={`/customization/${userData.data.organizationId}`}
                />
            );
        }
        if (userData.data.permissions.analytics.value) {
            return <Navigate to={`/impact/${userData.data.organizationId}`} />;
        }
        return <Loading error={`User has no access to this section`} />;
    }

    if (addedOrg) {
        return <Navigate to={`/account/${addedOrg}`} />;
    } else if (!orgData || pending) {
        return <Loading error={error} />;
    }

    const AddAccountForm = (
        <Form
            cancel={() => {
                setHidden();
                setError("");
            }}
            submit={async () => {
                setPending(true);
                setError("");
                try {
                    const org = await createOrg({
                        name: addAccountName?.trim(),
                    });
                    setAddedOrg(org.id);
                } catch (error) {
                    setPending(false);
                    setError((error as any).message);
                }
            }}
            change={(event) => setAddAcountName(event.currentTarget.value)}
            disabled={pending}
            label="Name"
            value={addAccountName}
            error={error}
        />
    );

    console.log(orgData.length);

    return (
        <>
            <div className="sm:flex sm:items-center">
                <div className="sm:flex-auto">
                    <h1 className="text-xl font-semibold text-gray-900 mb-6">
                        Select Account
                    </h1>
                    <div className="flex gap-2 mt-4">
                        <ShowHideButton
                            label="Add Account"
                            component={AddAccountForm}
                        />
                        {!show && (
                            <Button
                                label="Download Participant CSV"
                                onClick={onFetchCsvData}
                                disabled={pending || !csvData}
                            />
                        )}
                    </div>
                </div>
            </div>
            <Table
                activeField={"active"}
                columns={[
                    ["name", "Name"],
                    [
                        "active",
                        "Status",
                        (value) => (value ? "Active" : "Inactive"),
                    ],
                    ["customer", "Customer"],
                    ["recordSessions", "Record sessions"],
                    ["sendManagerEmails", "Send manager emails"],
                ]}
                data={orgData as unknown as TableData}
                onPageChange={(page: number) => setPage(page)}
                page={page}
                searchable={["name"]}
                sortable={true}
            />
        </>
    );
};

export default Orgs;
