import { ParamsProps, withParams } from "../utils";
import { useGlobalContext } from "../context";
import AddSession from "./AddSession";
import Loading from "../components/common/Loading";
import SessionsByTime from "../components/factories/SessionsByTime";
import { useEffect, useState } from "react";
import { useTRPC } from "../hooks/useTRPC";
import { Tab, TabList, TabPanel, TabPanels, Tabs, Box } from "@chakra-ui/react";

const Sessions = (props: ParamsProps) => {
    const context = useGlobalContext();
    const [error, setError] = useState("");

    const trpc = useTRPC();

    const orgById = trpc.org.getOrgById.useQuery(
        props.params.organizationId || "",
    );

    useEffect(() => {
        if (orgById.data) {
            context.setOrganizationId(orgById.data.id);
            context.setOrganizationName(orgById.data.name);
            context.setOrganizationTimezone(
                orgById.data.timezone ? orgById.data.timezone.name : "UTC",
            );
        } else if (orgById.error) {
            setError(orgById.error.message);
        }
    }, [context, orgById.data, orgById.error]);

    if (error) {
        return <Loading error={error} />;
    }

    return (
        <div>
            <h4 className="text-lg font-medium text-slate-800 mb-6">
                Sessions
            </h4>
            <Box>
                <AddSession />
            </Box>

            <Tabs variant="unstyled">
                <TabList mt="40px">
                    <Tab
                        color="dark.500"
                        borderRadius="8px"
                        fontWeight="600"
                        _selected={{ bgColor: "gray.50", color: "blue.600" }}
                    >
                        Future Sessions
                    </Tab>
                    <Tab
                        color="dark.500"
                        borderRadius="8px"
                        fontWeight="600"
                        _selected={{ bgColor: "gray.50", color: "blue.600" }}
                    >
                        Past Sessions
                    </Tab>
                </TabList>
                <TabPanels>
                    <TabPanel>
                        <SessionsByTime
                            organizationOrUserId={props.params.organizationId}
                            pastOrFuture="future"
                        />
                    </TabPanel>
                    <TabPanel>
                        <SessionsByTime
                            organizationOrUserId={props.params.organizationId}
                            pastOrFuture="past"
                        />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </div>
    );
};

export default withParams(Sessions);
