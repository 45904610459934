interface SearchPopupProps {
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onSubmit: (e: React.FormEvent<HTMLFormElement>) => void;
    onBlur: () => void;
    filterMap: Record<string, { value: string[]; active: boolean }>;
    filterField: string;
}

const SearchPopup = (props: SearchPopupProps) => {
    return (
        <div className="absolute flex flex-col bg-white shadow-md rounded-md p-4 top-full right-0">
            <form onSubmit={props.onSubmit}>
                <input
                    type="text"
                    value={props.filterMap[props.filterField].value[0] || ""}
                    className="px-2 py-1 mt-1 text-sm text-gray-900 border rounded"
                    onChange={props.onChange}
                    onBlur={props.onBlur}
                    autoFocus
                />
            </form>
        </div>
    );
};

export default SearchPopup;
